export const setLoading = value => {
  return {
    type: '@global/SET_LOADING',
    payload: value,
  };
};

export const setLexval = (value) => {
  return {
    type: '@global/SET_LEXVAL',
    payload: value,
  };
};

export const setBreadcrumb = (type, ...rest) => ({
  type: '@global/SET_BREADCRUMB',
  payload: {
    type,
    params: rest,
  },
});

export const loadDashboard = () => ({
  type: '@global/LOAD_DASHBOARD',
});

export const dashboardLoaded = payload => ({
  type: '@global/DASHBOARD_LOADED',
  payload,
});

export const loadReport = () => ({
  type: '@global/LOAD_REPORT',
});
