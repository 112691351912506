import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    min-height: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, .ui.button {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    color: #000e00 !important;

    &.red {
      background: #c2ff61;
      &:hover{
        background: #addd62 !important;
      }
    }
  }

  .fc-button-primary {
    color: #000e00 !important;
  }

  .fc-button.fc-button-primary {
    background: #c2ff61 !important;
    border-color: #8ec536 !important;
  }

  .ui.button:focus {
    outline: 0;
    background: #addd62 !important;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  label {
    font-weight: bold;
    color: #333333;
  }

  .ui.form .field>label {
    color: rgba(0, 0, 0, 0.8);
  }

  .ui.form .react-datepicker-wrapper {
    width: 100%;
  }

  .ui.input {
    margin: 15px 0 0;
    display: flex;
    flex-direction: column;
  }

  .ui.checkbox {
    margin: 15px 0 0;

    input, label {
      cursor: pointer !important;
    }
  }

  .field {
    & > span, & > div > span {
      color: #addd62;
    }
  }

  .ui.button.icon {
    font-weight: bold;
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
      font-size: 16px;
    }
  }
  a.ui.button:hover {
    text-decoration: none;
  }

  .space-between-h {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .ui.field.ui.checkbox, a {
      margin: 0;
    }
  }

  .table-link {
    color: #333;
    border-width: 0;
    background-color: transparent;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      text-decoration: none;
    }

    &:first-child {
      padding-right: 10px;
    }
  }

  .body-link {
    color: #344CEB;
    text-decoration: underline;
    font-size: 14px;
    border: 0;
    background: transparent;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  .field .SingleDatePicker,
  .field .SingleDatePickerInput {
    display: block;
  }
  .field .SingleDatePicker .DateInput {
    width: auto;
    display: block;
  }

  button.cancel-link {
    color: #c2ff61;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
    border: 0;
    background: transparent;
    margin: 10px 0;

    &:hover {
      text-decoration: none;
    }
  }

  .ui.label {
    border: 1px solid;

    &.canceled {
      background: #FFFBF3;
      border-color: #CCBFA0;
      color: #7A4E04;
    }

    &.done {
      background: #FCFFF5;
      border-color: #1E571F;
      color: #1E571F;
    }

    &.scheduled {
      background: #ffffff;
      border-color: #12546C;
      color: #10556C;
    }
  }

  .table {
    .ui.checkbox.fitted {
      margin: 0;
    }
  }

  .ui form .fields{
    align-items: end !important;
  }

  .react-datepicker__input-container{
    margin-left: 5px !important;
  }


  #estimationAmount{
    opacity: 1 !important;
    color: #000e00 !important;
  }

  .ui.input{
    opacity: 1 !important;
  }

  #estimationAmount::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000e00 !important;
    opacity: 1;
  }

  #estimationAmount:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #000e00 !important;
    opacity: 1;
  }

  #estimationAmount::-ms-input-placeholder { /* Microsoft Edge */
    color: #000e00 !important;
    opacity: 1;
  }
`;
