import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
`;

export const CalendarContainer = styled.div`
  .react-calendar {
    border-color: rgba(0, 0, 0, 0.1);

    &__tile {
      &--active {
        background: #00a2ff;
      }
    }

    abbr {
      text-decoration: none;
    }
  }
`;

export const TimeSlotList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .ui.placeholder {
    display: block;
  }
`;

export const TimeSlotButton = styled.button`
  display: flex;
  border: 1px solid ${props => (props.selected ? '#999999' : '#00a2ff')};
  height: 52px;
  width: 150px;
  color: ${props => (props.selected ? '#ffffff' : '#00a2ff')};
  background: ${props => (props.selected ? '#999999' : '#ffffff')};
  border-radius: 4px;
  font-size: 20px;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: ${props =>
      props.selected ? '#999999' : darken(0.1, '#00a2ff')};
    color: ${props => (props.selected ? '#ffffff' : darken(0.1, '#00a2ff'))};
  }
`;
