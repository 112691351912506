import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form as SForm, Accordion, Icon, Button } from 'semantic-ui-react';
import { Form } from '@rocketseat/unform';
import { MdSearch, MdReplay } from 'react-icons/md';

import Select from '~/components/select';
import DatePicker from '~/components/date-picker';
import { ButtonsContainer } from '../../Users/styles';
import { PAYMENTS_TYPES } from '~/utils/constants';

const typeOptions = Object.keys(PAYMENTS_TYPES).map(s => ({ key: s, text: PAYMENTS_TYPES[s], value: s }))

export default function PaymentsFilter({ onSubmit, initialData }) {
  const [opened, setOpened] = useState(false);
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [status, setStatus] = useState('')

  const filterRefresh = () => {
    setStartDate('')
    setEndDate('')
    setStatus('')
  }

  return (
    <Accordion>
      <Accordion.Title active={opened} onClick={() => setOpened(!opened)}>
        <Icon name="dropdown" />
        Filtro
      </Accordion.Title>
      <Accordion.Content active={opened}>
        <Form className="ui form" onSubmit={onSubmit} initialData={initialData}>
          <SForm.Group widths="2">
            <DatePicker
              name="start_date"
              value={startDate} 
              label="Data de início"
              onChange={v => setStartDate(v)}
            />
            <DatePicker
              name="end_date"
              value={endDate} 
              label="Data Final"
              onChange={v => setEndDate(v)}
            />
            <Select
              name="status"
              label="Status"
              options={typeOptions}
              value={status}
              onChange={(e, { value }) => setStatus(value)}
            />
          </SForm.Group>
          <ButtonsContainer>
            <Button type="submit" color="red" icon>
              <MdSearch />
              Buscar
            </Button>
            <Button type="button" onClick={filterRefresh} default icon>
              <MdReplay />
              Voltar
            </Button>
          </ButtonsContainer>
        </Form>
      </Accordion.Content>
    </Accordion>
  );
}

PaymentsFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object,
};

PaymentsFilter.defaultProps = {
  initialData: {},
};
