import api from './api';

const signIn = credentials => {
  return api.post('sessions', credentials);
};

const forgot = payload => {
  return api.post('/auth/resetPassword/forgot', payload);
}

const verifyForgot = payload => {
  return api.post('auth/resetPassword/credentials', payload);
};

const resetPassword = ({ email }) => {
  return api.post('auth/resetPassword', { email });
};

const updatePassword = ({ userId, token, password, confirmPassword }) => {
  return api.put(`auth/resetPassword/${userId}/${token}`, {
    password,
    confirmPassword,
  });
};

const updatePasswdAndStatus = ({ userId, password, confirmPassword }) => {
  return api.put(`auth/resetPassword/${userId}`, {
    password,
    confirmPassword,
  });
};

export default {
  signIn,
  resetPassword,
  updatePassword,
  updatePasswdAndStatus,
  verifyForgot,
  forgot
};
