import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tab, Modal } from 'semantic-ui-react';
import { MdAdd } from 'react-icons/md';
import NewScheduling from '../Appointments/newScheduling';
import { setBreadcrumb, setLoading } from '~/store/modules/global/actions';
import CalendarView from './components/calendar';
import Timekit from './components/timekit';
import { Container } from './styles';
import appointmentService from '~/services/appointments';
import { PROFILE } from '~/utils/constants';
import ConfirmationModal from '../Appointments/components/confirmationModal';
import Scheduler from '~/components/scheduler';

export default function Calendar() {
  const dispatch = useDispatch();
  const [modalNewScheduleOpened, setModalNewScheduleOpened] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [requesterId, setRequesterId] = useState(null);
  const [specialtyId, setSpecialtyId] = useState(null);
  const { loading } = useSelector((state) => state.scheduling);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const { profile: loggedUser } = useSelector((state) => state.user);

  const onCreateScheduleSubmit = async (data) => {
    dispatch(setLoading(true));
    setRequesterId(data.patients[0]);
    setSpecialtyId(data.specialty[0]);
    setModalNewScheduleOpened(false);
    setModalOpen(true);
  };

  const onSubmit = async ({ description }) => {
    dispatch(setLoading(true));
    try {
      const appointment = await appointmentService.create({
        slot: selectedSlot,
        resource: loggedUser.timekit_resource_id,
        description,
        requesterId,
        specialty_id: specialtyId,
        providerId: loggedUser.id,
      });
      if (appointment) {
        toast.success('Agendamento efetuado com sucesso');
        setModalOpen(false);
      }
    } catch (err) {
      toast.error('Não foi possível criar o agendamento');
    } finally {
      dispatch(setLoading(false));
    }
  };

  const panes = [
    {
      menuItem: 'Reuniões Agendadas',
      render: () => (
        <Tab.Pane>
          <CalendarView />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Disponibilidade',
      render: () => (
        <Tab.Pane>
          <Timekit />
        </Tab.Pane>
      ),
    },
  ];

  useEffect(() => {
    dispatch(setBreadcrumb('CALENDAR'));
  }, []);

  return (
    <>
      <Container className="content">
        <h2>Calendário de Eventos</h2>
        {[PROFILE.PROVIDER, PROFILE.DOCTOR].includes(loggedUser.roleId) && (
          <>
            <Button
              color="red"
              icon
              onClick={() => setModalNewScheduleOpened(true)}
            >
              <MdAdd />
              Novo Agendamento
            </Button>
            <br />
          </>
        )}
        <Tab panes={panes} />
      </Container>
      <NewScheduling
        opened={modalNewScheduleOpened}
        onClose={() => setModalNewScheduleOpened(false)}
        onSubmit={(data) => onCreateScheduleSubmit(data)}
        specialties={loggedUser.specialties}
      />
      <Modal open={modalOpen} closeIcon onClose={() => setModalOpen(false)}>
        <Modal.Header>Escolha um horário disponível</Modal.Header>
        <Modal.Content>
          <Scheduler
            providerId={loggedUser.id}
            specialtyId={specialtyId}
            selectedSlot={selectedSlot}
            onSlotSelect={(slot) => setSelectedSlot(slot)}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button default onClick={() => setModalOpen(false)}>
            Cancelar
          </Button>
          {selectedSlot && (
            <ConfirmationModal
              patient={requesterId}
              loading={!selectedSlot || loading}
              selectedSlot={selectedSlot}
              onSubmit={onSubmit}
              specialtyType={
                loggedUser.roleId === PROFILE.PROVIDER ? 'EXAM' : 'MDCL'
              }
            />
          )}
        </Modal.Actions>
      </Modal>
    </>
  );
}
