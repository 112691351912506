import React from 'react';

import { 
    Container,
    TitleInfo,
    DescriptionInfo,
    Divider
} from './styles';

export default function CardInfo({received, lastMonth}){
    return (
        <Container>
            <TitleInfo>
                Total Recebido
            </TitleInfo>
            <DescriptionInfo>
                R$ {received ? received.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0.00.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            </DescriptionInfo>
            <Divider />
            <TitleInfo>
                Último Mês
            </TitleInfo>
            <DescriptionInfo>
                R$ {lastMonth ? lastMonth.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0.00.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            </DescriptionInfo>
        </Container>
    )
}