import api from './api'

const get = () => {
    return api.get('operators')
}

const onlyActive = () => {
    return api.get('operators?onlyActive=1')
}

export default {
    get,
    onlyActive
}