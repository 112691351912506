import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form as SForm, Accordion, Button, Icon } from 'semantic-ui-react';
import { Form } from '@rocketseat/unform';
import { GoSearch } from 'react-icons/go';
import { MdReplay } from 'react-icons/md';

import Input from '~/components/input';
import Select from '~/components/select';
import { SPECIALTY_TYPES } from '~/utils/constants';
import { ButtonsContainer } from '~/pages/Users/styles';

const specialtyTypeOptions = () =>
  Object.keys(SPECIALTY_TYPES).map(key => ({
    key,
    value: key,
    text: SPECIALTY_TYPES[key],
  }));

export default function SpecialtyFilter({ onSubmit, initialData }) {
  const [opened, setOpened] = useState(false);
  function reset() {
    const data = { type: "", enabled: "", name: "" }
    onSubmit(data);
  }
  return (
    <Accordion>
      <Accordion.Title active={opened} onClick={() => setOpened(!opened)}>
        <Icon name="dropdown" />
        Filtro
      </Accordion.Title>
      <Accordion.Content active={opened}>
        <Form className="ui form" onSubmit={onSubmit} initialData={initialData}>
          <SForm.Group widths="3">
            <Select
              name="type"
              label="Consulta Médica/Exame"
              options={specialtyTypeOptions()}
            />
            <Select
              name="enabled"
              label="Status"
              options={[
                { key: 0, text: 'Ativo', value: true },
                { key: 1, text: 'Inativo', value: false },
              ]}
            />
            <Input name="name" label="Nome" />
          </SForm.Group>
          <ButtonsContainer>
            <Button type="submit" color="red" icon>
              <GoSearch />
              Buscar
            </Button>
            <Button type="button" default icon onClick={reset}>
              <MdReplay />
              Voltar
            </Button>
          </ButtonsContainer>
        </Form>
      </Accordion.Content>
    </Accordion>
  );
}

SpecialtyFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object,
};

SpecialtyFilter.defaultProps = {
  initialData: {},
};
