import React from 'react';

import Logo from '../../assets/homeup_horizontal_logo.svg';
import { Container } from './styles';

function RpaSuccess() {
  return (
    <Container>
      <h1><img src={Logo} alt="HomeUp Diagnósticos" /></h1>
      <h2>Solicitação de Reembolso Automatizado</h2>

      <p>Suas informações foram recebidas com sucesso. Em breve solicitaremos o seu reembolso na sua Operadora de Saúde.</p>
      <p>Obrigado.</p>
    </Container>
  );
}
export default RpaSuccess;
