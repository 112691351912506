import React from 'react';

import { 
    Container,
    TitleBalance,
    DescriptionBalance,
} from './styles';

export default function CardBalance({balance}){
    return (
        <Container>
            <TitleBalance>
                Saldo Disponível
            </TitleBalance>
            <DescriptionBalance>
                R$ {balance ? balance.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0.00.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            </DescriptionBalance>
        </Container>
    )
}