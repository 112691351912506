import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { useField } from '@rocketseat/unform';
import { parseISO } from 'date-fns';

export default function CustomDatePicker({ name, label, ...rest }) {
  const { fieldName, registerField, error, defaultValue } = useField(name);
  const [value, setValue] = useState(
    defaultValue ? parseISO(defaultValue) : null
  );

  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current,
        path: 'props.selected',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName]);
  const setPropValue = (d) => {rest.onChange && rest.onChange(d)}
  return (
    <Form.Field {...(rest.widths && { className: `${rest.widths} wide` })}>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <DatePicker
        id={fieldName}
        dateFormat="dd/MM/yyyy"
        ref={ref}
        {...rest}
        selected={value}
        onChange={date => {
          setValue(date)
          setPropValue(date)
        }}
      />
      {error && <span>{error}</span>}
    </Form.Field>
  );
}

CustomDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

CustomDatePicker.defaultProps = {
  label: null,
  placeholder: null,
};
