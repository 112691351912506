import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { get } from 'lodash';

export const BREADCRUMB_TYPES = {
  HOME: 'HOME',
  USERS: 'USERS',
  USER_NEW: 'USER_NEW',
  USER_DETAIL: 'USER_DETAIL',
  SPECIALTIES: 'SPECIALTIES',
  SPECIALTY_DETAIL: 'SPECIALTY_DETAIL',
  SPECIALTY_NEW: 'SPECIALTY_NEW',
  APPOINTMENTS: 'APPOINTMENTS',
  APPOINTMENT_DETAIL: 'APPOINTMENT_DETAIL',
  CALENDAR: 'CALENDAR',
  PROJECTS: 'PROJECTS',
  PROJECT_DETAIL: 'PROJECT_DETAIL',
  PROJECT_NEW: 'PROJECT_NEW',
  REPORT: 'REPORT',
  REPORT_DETAIL: 'REPORT_DETAIL',
  FINANCIAL: 'FINANCIAL',
  PAYMENTS: 'PAYMENTS',
};

const ROUTES = {
  HOME: '/',
  USERS: '/users',
  SPECIALTIES: '/specialties',
  APPOINTMENTS: '/appointments',
  CALENDAR: '/calendar',
  FINANCIAL: '/financial',
  REPORT: '/report',
  PROJECTS: '/projects',
  PAYMENTS: '/payments',
};

const handlers = {
  [BREADCRUMB_TYPES.HOME]: () => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Dashboard',
      active: true,
    },
  ],
  [BREADCRUMB_TYPES.USERS]: () => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.USERS,
      content: 'Cadastros',
      active: true,
    },
  ],
  [BREADCRUMB_TYPES.USER_NEW]: () => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.USERS,
      content: 'Cadastros',
      href: ROUTES.USERS,
    },
    {
      key: 'new',
      content: 'Novo',
      active: true,
    },
  ],
  [BREADCRUMB_TYPES.USER_DETAIL]: (currentUser) => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.USERS,
      content: 'Cadastros',
      href: ROUTES.USERS,
    },
    {
      key: get(currentUser, 'id'),
      content: get(currentUser, 'name'),
      active: true,
    },
  ],
  [BREADCRUMB_TYPES.SPECIALTIES]: () => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.SPECIALTIES,
      content: 'Especialidades',
      active: true,
    },
  ],
  [BREADCRUMB_TYPES.SPECIALTY_NEW]: () => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.SPECIALTIES,
      content: 'Especialidades',
      href: ROUTES.SPECIALTIES,
    },
    {
      key: 'new',
      content: 'Novo',
      active: true,
    },
  ],
  [BREADCRUMB_TYPES.SPECIALTY_DETAIL]: (currentSpecialty) => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.SPECIALTIES,
      content: 'Especialidades',
      href: ROUTES.SPECIALTIES,
    },
    {
      key: get(currentSpecialty, 'id'),
      content: get(currentSpecialty, 'name'),
      active: true,
    },
  ],
  [BREADCRUMB_TYPES.APPOINTMENTS]: () => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.APPOINTMENTS,
      content: 'Procedimentos',
      active: true,
    },
  ],
  [BREADCRUMB_TYPES.APPOINTMENT_DETAIL]: (appointment) => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.APPOINTMENTS,
      content: 'Consultas',
      href: ROUTES.APPOINTMENTS,
    },
    {
      key: get(appointment, 'id', 'a'),
      content: `ID ${get(appointment, 'id', '#')}`,
      active: true,
    },
  ],
  [BREADCRUMB_TYPES.CALENDAR]: () => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.CALENDAR,
      content: 'Calendário',
      active: true,
    },
  ],
  [BREADCRUMB_TYPES.FINANCIAL]: () => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.FINANCIAL,
      content: 'Finanças',
      href: ROUTES.FINANCIAL,
    },
  ],
  [BREADCRUMB_TYPES.REPORT]: () => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.REPORT,
      content: 'Relatório',
      href: ROUTES.REPORT,
    },
  ],
  [BREADCRUMB_TYPES.PAYMENTS]: (currentPayment) => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.PAYMENTS,
      content: 'Pagamentos',
      href: ROUTES.PAYMENTS,
    },
    {
      key:
        (currentPayment && currentPayment.id) ||
        `${BREADCRUMB_TYPES.PAYMENTS}/:id`,
      content: currentPayment && currentPayment.id,
      href: ROUTES.PAYMENTS,
    },
  ],
  [BREADCRUMB_TYPES.REPORT_DETAIL]: (status) => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.REPORT,
      content: 'Relatório',
      href: ROUTES.REPORT,
    },
    {
      key: 'new',
      content: `Status: ${status}`,
      active: true,
    },
  ],
  [BREADCRUMB_TYPES.PROJECTS]: () => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.PROJECTS,
      content: 'Projetos',
      active: true,
    },
  ],
  [BREADCRUMB_TYPES.PROJECT_NEW]: () => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.PROJECTS,
      content: 'Projetos',
      href: ROUTES.PROJECTS,
    },
    {
      key: 'new',
      content: 'Novo',
      active: true,
    },
  ],
  [BREADCRUMB_TYPES.PROJECT_DETAIL]: (currentProject) => [
    {
      key: BREADCRUMB_TYPES.HOME,
      content: 'Home',
      href: ROUTES.HOME,
    },
    {
      key: BREADCRUMB_TYPES.PROJECTS,
      content: 'Projetos',
      href: ROUTES.PROJECTS,
    },
    {
      key: get(currentProject, 'id'),
      content: get(currentProject, 'name'),
      active: true,
    },
  ],
};
export default (type, ...rest) => (
  <Breadcrumb
    icon="right angle"
    key={type}
    sections={handlers[type](...rest)}
  />
);
