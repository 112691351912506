import produce from 'immer';

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  if (type === '@auth/SIGN_OUT') return INITIAL_STATE;

  return produce(state, draft => {
    switch (type) {
      case '@auth/SIGN_IN_REQUEST':
        draft.loading = true;
        break;
      case '@auth/SIGN_IN_SUCCESS':
        draft.token = payload.token;
        draft.signed = true;
        draft.loading = false;
        break;
      case '@auth/SIGN_FAILURE':
        draft.loading = false;
        break;
      default:
    }
  });
};
