import styled from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  display: flex;
  background: #ffffff;
  font-size: 16px;
  overflow: hidden;
  height: 100vh;

  .container-header {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    max-width: 500px;
    width: 80%;
    align-items: center;
    margin-bottom: 20px;

    h1 {
      display: block;
      float: none;

      img {
        width: 140px;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    max-width: 315px;
    width: 80%;

    .field {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }

  h2 {
    font-weight: normal;
    font-size: 22px;
    color: #333333;
    width: 80%;
    display: flex;
    text-align: center;
  }

  .ui.button {
    box-sizing: border-box;
    margin: 10px 0 0 0;
    font-size: 16px;
  }

  a {
    display: flex;
    justify-content: center;
    margin: 10px 0 0;
    color: #2185d0;

    &:hover {
      color: ${darken(0.05, '#2185D0')};
      text-decoration: underline;
    }
  }
`;
