import styled, { css } from 'styled-components';

export const Button = styled.button`
  background: #c2ff61
  padding: 20px;
  display: block;
  margin-top: 25px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 20px;
  color: #333333 !important;
  font-weight: 800;
  &:hover: {
    background: #addd62;
  }
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  ${props => props.disabled && css`
    background: #c5c5c3;
    color: black;
    cursor: auto;
  `}
`

export const ModalContainer = styled.div`
  padding: 20px;
`

export const TitleAccount = styled.h2`
  text-align: left;
  margin: 0;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
`

export const ModalRow = styled.div`
  display: flex;
  justify-content: center;
`

export const ModalClientInfo = styled.div`
  margin-top: 20px;
  padding: 20px 30px;
  display: grid;
  background: #f4f4f4 !important;
  border-radius: 30px;
`

export const TitleModal = styled.h2`
  text-align: center;
  margin: 0;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 5px;
`

export const RowForm = styled.div`
  display: flex;
`

export const ColumnFormButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 20px;
`

export const ButtonFormSave = styled.button`
  background: #c2ff61
  padding: 10px 30px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 15px;
  color: #333333 !important;
  font-weight: 800;
  margin-right: 10px;
  margin-top: 25px;
  &:hover: {
    background: #addd62;
  }
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  svg {
    font-size: 20px !important;
    margin-right: 5px;
  }
`

export const TitleInModal = styled.span`
  text-align: center;
  margin: 0;
  margin-left: 5px;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 18px;
  font-weight: 200;
`

export const ButtonFormBack = styled.button`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-left: 10px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 15px;
  color: #333333 !important;
  font-weight: 800;
  &:hover: {
    background: #addd62;
  }
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  svg {
    font-size: 20px !important;
    margin-right: 5px;
  }
`