import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ResetPasswordRequest from './components/request';
import ResetPasswordUpdate from './components/update';
import { Background, Container } from './styles';

export default function ResetPassword() {
  return (
    <>
      <Background />
      <Container>
        <Switch>
          <Route
            path='/forgot/redirect/:id'
            component={ResetPasswordUpdate}
          />
          <Route exact path='/forgot/:id' component={ResetPasswordRequest} />
        </Switch>
      </Container>
    </>
  );
}
