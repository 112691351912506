import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import { get } from 'lodash';
import { format, parseISO } from 'date-fns';

import { loadAppointments } from '~/store/modules/scheduling/actions';

import '~/../node_modules/@fullcalendar/core/main.css';
import '~/../node_modules/@fullcalendar/list/main.css';
import '~/../node_modules/@fullcalendar/daygrid/main.css';

export default function CalendarView() {
  const dispatch = useDispatch();
  const { appointments = [] } = useSelector((state) => state.scheduling);

  useEffect(() => {
    dispatch(loadAppointments());
  }, [dispatch]);

  return (
    <FullCalendar
      defaultView="dayGridWeek"
      height="parent"
      views={{
        listWeek: {
          type: 'listWeek',
          buttonText: 'Lista',
        },
        dayGridMonth: { buttonText: 'Mês' },
        dayGridWeek: { buttonText: 'Semana' },
      }}
      plugins={[listPlugin, dayGridPlugin]}
      header={{
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,listWeek',
      }}
      locale="pt-br"
      events={ appointments.lengh && appointments.map((item) => {
        // const [date, time] = item.start_at.split(' ');
        return {
          title: `${format(
            parseISO(item.start_at || item.created_at),
            'HH:mm'
          )} - ${item.requester ? item.requester.name : ''}`,
          end: item.end_at || item.created_at,
          start: item.start_at || item.created_at,
          // startTime: time,
          groupId: get(item, 'mediation.mediator_id'),
          url: `/appointments/${item.id}/view`,
        };
      })}
    />
  );
}
