export const checkingAccountGet = ({ user_id }) => {
  return {
    type: '@checking_accounts/GET_REQUEST',
    payload: {
      user_id,
    },
  };
};

export const checkingAccountGetLoaded = (checkingAccount) => {
  return {
    type: '@checking_accounts/GET_SUCCESS',
    payload: {
      ...checkingAccount,
    },
  };
};

export const checkingAccountPut = ({ user_id, accountData }) => {
  return {
    type: '@checking_accounts/PUT_REQUEST',
    payload: {
      user_id,
      accountData,
    },
  };
};

export const checkingAccountFailed = () => {
  return {
    type: '@checking_accounts/FAILED',
  };
};
