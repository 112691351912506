import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';

import {
  Button,
  ModalContainer,
  TitleAccount,
  TitleInModal,
  ModalRow,
  ModalClientInfo,
  TitleModal,
  RowForm,
  ColumnFormButton,
  ButtonFormSave,
  ButtonFormBack,
} from './styles';

import { RiCloseFill } from 'react-icons/ri';
import { formatCurrency } from '../../utils/formatCurrency';
import { useDispatch } from 'react-redux';
import { createPayment } from '~/store/modules/payments/actions';

export default function ButtonRequestRansom({
  id,
  disabled,
  name,
  cpf,
  bank,
  agency,
  checkingAccount,
  digit,
  cost,
  tedAmount,
}) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  function openModal() {
    setOpen(!open);
  }

  function onSubmit(evt) {
    setOpen(!open);
    dispatch(createPayment({ checkingAccountId: id, amount: tedAmount }));
  }

  return (
    <>
      {open && (
        <Modal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          <Modal.Content>
            <ModalContainer>
              <TitleAccount>Nova Transferência</TitleAccount>
              <ModalRow>
                <ModalClientInfo>
                  <TitleModal>
                    Nome: <TitleInModal>{name}</TitleInModal>
                  </TitleModal>
                  <TitleModal>
                    CPF: <TitleInModal>{cpf}</TitleInModal>
                  </TitleModal>
                  <TitleModal>
                    Banco: <TitleInModal>{bank}</TitleInModal>
                  </TitleModal>
                  <TitleModal>
                    Agência: <TitleInModal>{agency}</TitleInModal>
                  </TitleModal>
                  <TitleModal>
                    Conta Corrente:{' '}
                    <TitleInModal>
                      {checkingAccount}-{digit}
                    </TitleInModal>
                  </TitleModal>
                  <TitleModal>
                    Custo:{' '}
                    <TitleInModal>
                      {formatCurrency({ number: cost })}
                    </TitleInModal>
                  </TitleModal>
                  <TitleModal>
                    Valor PIX:{' '}
                    <TitleInModal>
                      {formatCurrency({ number: tedAmount })}
                    </TitleInModal>
                  </TitleModal>
                </ModalClientInfo>
              </ModalRow>
              <RowForm>
                <ColumnFormButton>
                  <ButtonFormSave onClick={onSubmit}>Solicitar</ButtonFormSave>
                  <ButtonFormBack onClick={openModal}>
                    <RiCloseFill />
                    Cancelar
                  </ButtonFormBack>
                </ColumnFormButton>
              </RowForm>
            </ModalContainer>
          </Modal.Content>
        </Modal>
      )}
      <Button disabled={disabled} onClick={openModal}>
        Solicitar Resgate
      </Button>
    </>
  );
}
