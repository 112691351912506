import produce from 'immer';

const INITIAL_STATE = {
  specialtyList: [],
  isEditing: false,
  loading: false,
  currentSpecialty: null,
  participations: {
    pagination: {
      page: 1,
    },
    records: [],
  },
  pagination: {
    page: 1,
    name: null,
    created_at: null,
  },
};

export default (state = INITIAL_STATE, { type, payload }) => {
  if (type === '@auth/SIGN_OUT') return INITIAL_STATE;

  return produce(state, draft => {
    switch (type) {
      case '@specialties/LIST_SUCCESS': {
        draft.specialtyList = payload.specialtyList;
        draft.pagination = payload.pagination;
        break;
      }
      case '@specialties/GET_SUCCESS': {
        draft.currentSpecialty = payload;
        break;
      }
      case '@specialties/PARTICIPATIONS_LOADED': {
        draft.participations = payload;
        break;
      }
      case '@specialties/SET_EDITING': {
        draft.isEditing = payload;
        break;
      }
      case '@specialties/FAILED': {
        draft.loading = false;
        break;
      }
      default:
    }
  });
};
