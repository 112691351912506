import React from 'react';
import { Check } from '@rocketseat/unform';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default function CustomCheckbox(props) {
  const { name, label } = props;
  return (
    <Form.Field className="ui checkbox">
      <Check {...props} name={name} label={label} />
    </Form.Field>
  );
}

CustomCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
