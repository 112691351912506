import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import { Form as SForm, Button, Modal } from 'semantic-ui-react';
import { MdBlock, MdSave, MdEdit } from 'react-icons/md';
import './styles.css'
import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';
import Input from '~/components/input';
import Select from '~/components/select';
import {
  APPOINTMENT_STATUS,
  STATUS_DOC,
  PROFILE,
} from '~/utils/constants';
import {
  updateStatus,
  updateStatusWithBoleto,
} from '~/store/modules/appointments/actions';
import { get, isNumber } from 'lodash';

const schema = Yup.object().shape({
  amount: Yup.string().required('Campo obrigatório'),
});

const statusOptions = Object.keys(STATUS_DOC).map((key) => ({
  key,
  value: key,
  text: STATUS_DOC[key],
}));

const financialOpt = Object.keys(APPOINTMENT_STATUS).map((key) => ({
  key,
  value: key,
  text: APPOINTMENT_STATUS[key],
}));

export default function StatusForm({ currentAppointment, special, singleDate }) {
  const [isEditing, setEditing] = useState(false);
  const [status, setStatus] = useState(currentAppointment.status);
  const [boletosModalOpen, setBoletosModalOpen] = useState(false);
  const { profile } = useSelector((state) => state.user);
  const statusChangeHandler = (_, { value }) => setStatus(value);
  const charge_value = get(currentAppointment, 'charge_value');
  const estimation_amount = get(currentAppointment, 'estimation_amount');
  const dispatch = useDispatch();

  let typeAppointment
  if(special.length) {
    typeAppointment = special.map(item => {
      if(item.type === "MDCL") return item.type
      else if(item.type === "EXAM") return item.type
      else if(item.type === "SURG") return item.type
      else return null
    });
    typeAppointment = String(typeAppointment);
  }

  /*EXAM OR MDCL OR SURG*/
  const changeStatusAndAddBoleto = (formData) => {
    if (boletosModalOpen && formData.amount >= 50) {
      dispatch(
        updateStatusWithBoleto({
          id: currentAppointment.id,
          ...boletosModalOpen,
          ...formData,
        })
      );
    }
    setBoletosModalOpen(false);
  };

  const changeStatus = async (data) => { //make
    const cpf = currentAppointment && currentAppointment.requester &&
      currentAppointment.requester.profile && currentAppointment.requester.profile.cpf;
    if ((data.status === 'DGSD' || data.status === 'SGPF' ||
      data.status === 'RBMG' || data.status === 'PMMG') && !cpf) {
      toast.error(
        'É preciso incluir o CPF no cadastro do Paciente para alterar para Diagnosticado'
      );
      setStatus(currentAppointment.status);
    } else if (data.status === 'WTPA') {
      setBoletosModalOpen(true);
    } else {
      dispatch(updateStatus({ id: currentAppointment.id, ...data }));
    }
    setEditing(false);

  };

  const fastRefresh = (event) => {
    window.location.reload(true);
  }

  useEffect(() => {
    setStatus(currentAppointment.status);
  }, [currentAppointment]);

  const isDisablePROC = (appointment) => {
    // For enabling PROC - Procedimento realizado
    // typeAppointment = MDCL must have file archive_type "document_fiscal"
    // typeAppointment = EXAM must have files archive_type "document_fiscal" AND "relatorio_medico"
    // typeAppointment = SURG must have files archive_type "document_fiscal" AND "relatorio_medico" AND "descricoes_cirurgicas"
    if(typeAppointment === 'MDCL'){
      return !appointment.files.some(f => f.archive_type === 'documento_fiscal');
    }

    if(typeAppointment === 'EXAM'){
      return !(
        appointment.files.some(f => f.archive_type === 'documento_fiscal')
        && appointment.files.some(f => f.archive_type === 'relatorio_medico')
      )
    }

    if(typeAppointment === 'SURG'){
      return !(
        appointment.files.some(f => f.archive_type === 'documento_fiscal')
        && appointment.files.some(f => f.archive_type === 'relatorio_medico')
        && appointment.files.some(f => f.archive_type === 'descricoes_cirurgicas')
      )
    }
    return true;
  }

  const filterHandleOptions = () => {
    const statusDictionary = {
      'SCDL': {
        disabled: false,
      },
      'PIRQ': {
        disabled: !charge_value && !estimation_amount,
      },
      'AWPR': {
        disabled: currentAppointment.status !== 'PIRQ' || (!charge_value && !estimation_amount),
      },
      'PIFN': {
        disabled: (currentAppointment.status !== 'PIRQ' && currentAppointment.status !== 'AWPR') || (!charge_value && !estimation_amount),
      },
      'WAIT': {
        disabled: (currentAppointment.status !== 'PIRQ' && currentAppointment.status !== 'PIFN') || (!charge_value && !estimation_amount),
      },
      'APPR': {
        disabled: false,
      },
      'FREE':{
        disabled: false
      },
      // Statuses enabled once patient does approve
      'PROC':{
        disabled: isDisablePROC(currentAppointment),
      },
      'RQRF':{
        disabled: false,
      },
      'OKPA':{
        disabled: false,
      },
      'WTPA':{
        disabled: false,
      },
      'DONE':{
        disabled: false,
      },
      'PNDC':{
        disabled: false,
      },
      'GLOS':{
        disabled: false,
      },
      'OVRD':{
        disabled: false,
      },
      'CANC':{
        disabled: false,
      },
    }
    
    let options = statusOptions
      .map(opt => {
        opt.disabled = statusDictionary[opt.value].disabled;
        return opt;
      })
    return options;
  }

  return (
    <>
      <Form
        className="ui form"
        onSubmit={changeStatus}
        initialData={{ status }}
      >
        <SForm.Group
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
         { ([PROFILE.ADMIN, PROFILE.FINADMIN].includes(profile.roleId))
         ?
         <>
          <div Name="wrapper">
            <Select
              name="status"
              label="Status do procedimento"
              options={statusOptions}
              onChange={statusChangeHandler}
              disabled={!isEditing}
              value={status}
            />
            <div className="tooltip">Para encaminhar para prévia, defina um valor cobrado.</div>
          </div>
         </>
           :
            <>
              <Select
                name="status"
                label="Status do procedimento"
                options={filterHandleOptions()}
                onChange={statusChangeHandler}
                disabled={!isEditing}
                value={status}
              />
            </>
          }
          {!isEditing ? (
            <Button icon onClick={() => setEditing(true)}>
              <MdEdit />
            </Button>
          ) : (
              <>
                <Button icon onClick={(e) => {setEditing(false); fastRefresh(e);}}>
                  <MdBlock />
                </Button>
                <Button type="submit" icon>
                  <MdSave />
                </Button>
              </>
            )}
        </SForm.Group>
        <p className="atl"><b>Atualizado em:</b>
          { isNumber(singleDate)
            ?
            format(new Date(singleDate), 'dd/MM/yyyy') + ' às ' + format(new Date(singleDate), 'HH:mm:ss.')
            :
            format(parseISO(singleDate), 'dd/MM/yyyy') + ' às ' + format(parseISO(singleDate), 'HH:mm:ss.')
          }
        </p>
      </Form>
      <Modal
        size="mini"
        open={!!boletosModalOpen}
        closeIcon
        onClose={() => setBoletosModalOpen(false)}
      >
        <Modal.Header>Enter amount</Modal.Header>
        <Modal.Content>
          <Form
            className="ui form"
            onSubmit={changeStatusAndAddBoleto}
            schema={schema}
          >
            <SForm.Group widths="equal">
              <Input name="amount" label="Amount" type="number" />
            </SForm.Group>
            <div style={{ textAlign: 'right' }}>
              <Button default onClick={() => setBoletosModalOpen(false)}>
                Cancelar
              </Button>
              <Button type="submit" color="red">
                Save
              </Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
}

StatusForm.propTypes = {
  currentAppointment: PropTypes.object.isRequired,
};
