import { all, takeLatest, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { setLoading } from '../global/actions';
import fileService from '~/services/files';
import requestHandler from '../../middlewares/requestHandler';

function* upload({ payload }) {
  yield put(setLoading(true));

  const response = yield call(requestHandler, fileService.upload, payload);
  if (response) {
    toast.success('Arquivo anexado com sucesso');

    setTimeout(() => {
      window.location.reload();
    }, 200);
  } else {
    toast.error('Não foi possível anexar o arquivo');
  }

  yield put(setLoading(false));
}

export default all([takeLatest('@files/UPLOAD', upload)]);
