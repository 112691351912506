import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@rocketseat/unform';
import { get } from 'lodash';

import {
  updateUserRequest,
  getUserRequest,
  inactivateUserRequest,
  // destroyUserRequest,
} from '~/store/modules/users/actions';
import { setBreadcrumb } from '~/store/modules/global/actions';
import schema from './components/validation';

import Actions from './components/actions';
import UserForm from './components/form';
import UserView from './components/view';
import { Container } from './styles';

export default function UserEdit(props) {
  const { t } = useTranslation();
  const { currentUser } = useSelector((state) => state.users);
  const [isUpdate, setPut] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const userId = props.match.params.id;
    dispatch(getUserRequest(userId));
  }, [dispatch, props.match.params.id]);

  useEffect(() => {
    dispatch(setBreadcrumb('USER_DETAIL', currentUser));
    setRoleId(get(currentUser, 'roleId'));
  }, [currentUser, dispatch]);

  function handleSubmit(data, e, t) {
    if(isUpdate){
      let result = {...data, isUpdate:isUpdate}
      dispatch(updateUserRequest(currentUser.id, result));
    }
    dispatch(updateUserRequest(currentUser.id, data));
  }
  return (
    currentUser && (
      <Container className="content">
        <h1>
          {currentUser
            ? `${currentUser.name} (${t(
                currentUser.role && currentUser.role.name
              )})`
            : 'Editar usuário'}
        </h1>
        <Form
          className="ui form"
          onSubmit={handleSubmit}
          schema={schema}
          initialData={currentUser}
        >
          {currentUser && (
            <Actions
              isEditing={isEditing}
              isActive={currentUser.status === 'ACTV'}
              currentUser={currentUser}
              setPut={(value) => setPut(value)}
              setEditing={(value) => setIsEditing(value)}
              onInactivate={() => dispatch(inactivateUserRequest(currentUser))}
              // onDelete={() => dispatch(destroyUserRequest(currentUser))}
            />
          )}
          {!isEditing ? (
            <>
              <UserView />
            </>
          ) : (
            <UserForm roleId={roleId} setRoleId={setRoleId} />
          )}
        </Form>
      </Container>
    )
  );
}

UserEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      // React Router passes arguments as strings
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
