import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@rocketseat/unform';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import Select from '~/components/select';
import DatePicker from '~/components/date-picker';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { registerLocale } from  "react-datepicker";
import { uploadFile } from '~/store/modules/files/actions';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';
import { FILE_TYPES, FISCAL_FILE_TYPES } from '~/utils/constants';
registerLocale('pt-BR', ptBR);

export default function InsertModal({ appointmentId }) {
  const [opened, setOpened] = useState(false);
  const [formData, setFormData] = useState({});
  const [ _archiveType, _setArchiveType ] = useState(null);
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    dispatch(
      uploadFile({
        id: appointmentId,
        files: e.target.files,
        ...formData
      })
    );
  };

  const archiveTypes = Object.keys(FILE_TYPES).map((e, index) => (
    {
      key: index,
      value: e,
      text: FILE_TYPES[e],
    }
  ))

  const fiscalDocTypes = Object.keys(FISCAL_FILE_TYPES).map((e, index) => (
    {
      key: index,
      value: e,
      text: FISCAL_FILE_TYPES[e],
    }
  ));

  const submitForm = async (data) => {
    data.emissionDate = data.emissionDate
      ? format(data.emissionDate, 'yyyy-MM-dd')
      : null;
    data.fiscalDocType = data.fiscalDocType
      ? data.fiscalDocType
      : null;

    try {
      const schema = Yup.object().shape(
        Object.assign(
          {
            archiveType: Yup.string().required(),
            emissionDate: Yup.string().required()
          },
          data.archiveType === 'documento_fiscal'
            ? { fiscalDocType: Yup.string().required() } : {}
        )
      );

      await schema.validate(data);
      setFormData(data);

      inputRef.current.click();
    } catch (error) {
      toast.error('Preencha todos os campos antes de selecionar o arquivo.');
    }
  }

  return (
    <>
      <Button
        color="red"
        icon
        onClick={() => setOpened(true)}
        style={{ cursor: 'pointer', marginLeft: '5px' }}
      >
        Adicionar 
      </Button>
      <Modal size="tiny" open={opened} onClose={() => setOpened(false)}>
        <Modal.Header>Selecione o Tipo de Arquivo</Modal.Header>
        <Modal.Content>
          <Form
            className="ui form"
            onSubmit={submitForm}
          >
              <Select
                name='archiveType'
                label="Tipo do Arquivo"
                options={archiveTypes}
                onChange={(_, {value}) => _setArchiveType(value)}
              />
              {
                _archiveType === 'documento_fiscal'
                  ? <Select name='fiscalDocType' label="Documento Fiscal" options={fiscalDocTypes} />
                  : null
              }
              <DatePicker
                name='emissionDate'
                label='Data de Emissão'
                onChange={() => {}}
                locale="pt-BR"
              />
              <Button
                color="red"
                type="submit"
              >
                Escolher Arquivo
              </Button>
          </Form>
        </Modal.Content>
        <input
          ref={inputRef}
          hidden={true}
          id="file"
          type="file"
          name="file[]"
          onChange={handleSubmit}
          multiple
        />
      </Modal>
    </>
  );
}

InsertModal.propTypes = {
  appointmentId: PropTypes.number.isRequired,
};