export const registerRequest = data => ({
  type: '@user/REGISTER_REQUEST',
  payload: data,
});

export const registerSuccess = () => ({
  type: '@user/REGISTER_SUCCESS',
});

export const updateProfile = (data) => {
  return {
    type: '@user/UPDATE_PROFILE',
    payload: data,
  };
};

// export const updateUserSuccess = user => {
//   return {
//     type: '@user/UPDATE_SUCCESS',
//     payload: user,
//   };
// };
