import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form as SForm, Accordion, Icon, Button } from 'semantic-ui-react';
import { Form } from '@rocketseat/unform';
import { MdSearch, MdReplay } from 'react-icons/md';

import Input from '~/components/input';
import Select from '~/components/select';
import DatePicker from '~/components/date-picker';
import { ButtonsContainer } from '../../Users/styles';
import { APPOINTMENT_STATUS, SPECIALTY_TYPES } from '~/utils/constants';

const appointmentStatusOptions = Object.keys(APPOINTMENT_STATUS).map((key) => ({
  key,
  value: key,
  text: APPOINTMENT_STATUS[key],
}));

const typeOptions = Object.keys(SPECIALTY_TYPES).map((s) => ({
  key: s,
  text: SPECIALTY_TYPES[s],
  value: s,
}));

export default function MediationFilter({ onSubmit, initialData }) {
  const [opened, setOpened] = useState(false);
  const [createdAt, setCreatedAt] = useState('');
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  const [requesterName, setRequesterName] = useState('');
  const [providerName, setProviderName] = useState('');
  const [id, setId] = useState('');

  const refreshFilter = () => {
    setType('');
    setProviderName('');
    setRequesterName('');
    setStatus('');
    setCreatedAt('');
    setId('');
    onSubmit();
  };

  return (
    <Accordion>
      <Accordion.Title active={opened} onClick={() => setOpened(!opened)}>
        <Icon name="dropdown" />
        Filtro
      </Accordion.Title>
      <Accordion.Content active={opened}>
        <Form className="ui form" onSubmit={onSubmit} initialData={initialData}>
          <SForm.Group widths="2">
            <Input
              name="requesterName"
              label="Nome do Paciente"
              value={requesterName}
              onChange={(e) => setRequesterName(e.target.value)}
            />
            <Input
              name="providerName"
              label="Nome do Médico"
              value={providerName}
              onChange={(e) => setProviderName(e.target.value)}
            />
            <Select
              name="type"
              label="Tipo de atendimento"
              options={typeOptions}
              value={type}
              clearable
              onChange={(e, { value }) => setType(value)}
            />
          </SForm.Group>
          <SForm.Group>
            <Select
              name="status"
              value={status}
              label="Status"
              clearable
              options={appointmentStatusOptions}
              onChange={(e, { value }) => setStatus(value)}
            />
            <DatePicker
              name="created_at"
              value={createdAt}
              label="Criado em"
              onChange={(v) => setCreatedAt(v)}
            />
            <Input
              name="id"
              label="Id da consulta"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
          </SForm.Group>
          <ButtonsContainer>
            <Button type="submit" color="red" icon>
              <MdSearch />
              Buscar
            </Button>
            <Button
              type="button"
              default
              icon
              onClick={(e) => refreshFilter(e)}
            >
              <MdReplay />
              Voltar
            </Button>
          </ButtonsContainer>
        </Form>
      </Accordion.Content>
    </Accordion>
  );
}

MediationFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object,
};

MediationFilter.defaultProps = {
  initialData: {},
};
