import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { get } from 'lodash';
import { Form } from '@rocketseat/unform';
import { Form as SForm, Button, Table, Pagination } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { loadPerformanceReport } from '~/store/modules/appointments/actions';
import { setBreadcrumb } from '~/store/modules/global/actions';
import { renderAppointmentStatus } from '~/utils/statuses';
import { MdSearch } from 'react-icons/md';
import { ReactComponent as IconTotalBilling } from './components/IconTotalBilling.svg';
import { ReactComponent as IconFinalized } from './components/IconFinalized.svg';
import { ReactComponent as IconMissing } from './components/IconMissing.svg';
import { calculateFinance, financeShow } from './reportView';
import './style.css';

export default function ListView(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const { status, selected } = props.match.params;
  let { appointmentList } = useSelector(
    (state) => state.appointments
  );

  if(selected) {
    appointmentList = appointmentList.filter((appoint) => appoint.provider_id == selected);
  }

  useEffect(() => {
    dispatch(setBreadcrumb('REPORT_DETAIL', props.match.params.status));
    dispatch(loadPerformanceReport({ status, startDate, endDate }));
  }, [props, dispatch, status, startDate, endDate]);

  const onHandleSearch = () => {
    dispatch(loadPerformanceReport({ status, startDate, endDate }));
  }
  function renderAppointments() {
    let aux = String(props.match.params.status);
    const verify = aux && aux === 'PROC' ? ['PROC', 'PERF', 'SGPF'] : aux;
    return appointmentList
      .filter((appointment) => verify.includes(appointment.status))
      .slice((page - 1) * 15, Math.min(page * 15, appointmentList.length))
      .map((appointment) => (
        <Table.Row key={appointment.id}>
          <Table.Cell>{appointment.id}</Table.Cell>
          <Table.Cell>{get(appointment, 'requester.name', '-')}</Table.Cell>
          <Table.Cell>{get(appointment, 'provider.name', '-')}</Table.Cell>
          <Table.Cell>
            {format(parseISO(appointment.created_at), 'dd/MM/yyyy hh:mm:ss')}
          </Table.Cell>
          <Table.Cell>
            {renderAppointmentStatus(appointment.status, t)}
          </Table.Cell>
          <Table.Cell>
            <Button.Group icon>
              <Link
                to={`/appointments/${appointment.id}/view`}
                className="table-link"
              >
                Abrir
              </Link>
              <Link
                to={`/appointments/${appointment.id}/view`}
                className="table-link"
              >
                Editar
              </Link>
            </Button.Group>
          </Table.Cell>
        </Table.Row>
      ));
  }
  const onPageChange = (e, { activePage: page }) => {
    setPage(page);
  };
  const onSubmit = () => {

  }
  return (
    <div className="content">
      <h1>Relatórios de Performance</h1>
      <div className="actions">
        <Form className="ui form" onSubmit={onSubmit}>
          <SForm.Group style={{ marginTop: 35, marginBottom: 35 }}>
            <SForm.Field className="d-flex" >
              <label className="mt-auto mb-auto">Inicio: </label>
              <DatePicker name="started_at"
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={date => setStartDate(date)}
              />
            </SForm.Field>
            <SForm.Field className="d-flex" >
              <label className="mt-auto mb-auto">Até: </label>
              <DatePicker name="finished_at"
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={date => setEndDate(date)}
              />
            </SForm.Field>

            <Button color="red" icon onClick={onHandleSearch}>
              <MdSearch />Buscar
            </Button>
          </SForm.Group>
        </Form>
        <Divider />
      </div>
      <div className="status-icon-board">
        <div className="d-flex">
          <div className="status-icon">
            <IconTotalBilling className="m-auto" />
          </div>
          <div className="pl-10">
            <p className="status-brand">Faturamento Total</p>
            <h3 className="m-0">R$ {financeShow(calculateFinance(appointmentList).estimationAmount)}</h3>
          </div>
        </div>
        <div className="d-flex">
          <div className="status-icon">
            <IconMissing className="m-auto" />
          </div>
          <div className="pl-10">
            <p className="status-brand">Finalizados</p>
            <h3 className="m-0">R$ {financeShow(calculateFinance(appointmentList).refundAmount)}</h3>
          </div>
        </div>
        <div className="d-flex">
          <div className="status-icon">
            <IconFinalized className="m-auto" />
          </div>
          <div className="pl-10">
            <p className="status-brand">Falta Receber</p>
            <h3 className="m-0">R$ {financeShow(calculateFinance(appointmentList).estimationAmount - calculateFinance(appointmentList).refundAmount)}</h3>
          </div>
        </div>
        <Divider />
      </div>
      {
        appointmentList.length > 0 && (
          <React.Fragment>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell># ID</Table.HeaderCell>
                  <Table.HeaderCell>Solicitante</Table.HeaderCell>
                  <Table.HeaderCell>Prestador</Table.HeaderCell>
                  <Table.HeaderCell>Criado em</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Ações</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{renderAppointments()}</Table.Body>
            </Table>
            <Pagination
              totalPages={Math.ceil(appointmentList.length / 15)}
              defaultActivePage={page}
              onPageChange={onPageChange}
              firstItem={null}
              lastItem={null}
              pointing
              secondary
            />
            <div>
              <h2 style={{ textAlign: "center" }}>{appointmentList.length} TOTAL</h2>
            </div>
          </React.Fragment>
        )
      }
    </div >
  );
}


