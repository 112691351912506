import produce from 'immer';

const INITIAL_STATE = {
  userList: [],
  loading: false,
  currentUser: null,
  participations: {
    pagination: {
      page: 1,
    },
    records: [],
  },
  pagination: {
    page: 1,
    name: null,
    created_at: null,
    email: null,
    roleId: null,
  },
};

export default (state = INITIAL_STATE, { type, payload }) => {
  if (type === '@auth/SIGN_OUT') return INITIAL_STATE;

  return produce(state, draft => {
    switch (type) {
      case '@users/LIST_SUCCESS': {
        draft.userList = payload.userList;
        draft.pagination = payload.pagination;
        break;
      }
      case '@users/GET_SUCCESS': {
        draft.currentUser = payload;
        break;
      }
      case '@users/PARTICIPATIONS_LOADED': {
        draft.participations = payload;
        break;
      }
      case '@users/FAILED': {
        draft.loading = false;
        break;
      }
      default:
    }
  });
};
