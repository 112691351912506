import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import { MdDelete } from 'react-icons/md';

export default function DeleteModal({ file, onConfirm }) {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <MdDelete
        size="20"
        color="red"
        icon
        onClick={() => setOpened(true)}
        style={{ cursor: 'pointer' }}
      />
      <Modal size="tiny" open={opened} onClose={() => setOpened(false)}>
        <Modal.Content>
          Você tem certeza que quer remover o arquivo {file.name}?
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            type="button"
            onClick={async () => {
              if (await onConfirm()) {
                setOpened(false);
              }
            }}
          >
            Confirmar
          </Button>
          <Button default type="button" onClick={() => setOpened(false)}>
            Cancelar
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

DeleteModal.propTypes = {
  file: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
