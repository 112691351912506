/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Header, Icon, Segment, List } from 'semantic-ui-react';
import { FaRegFileAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { ShadowBox } from '../../../Appointments/styles';
import { setLoading } from '~/store/modules/global/actions';
import { getUserRequest } from '~/store/modules/users/actions';
import usersService from '~/services/users';
import requestHandler from '~/services/requestHandler';
import DeleteModal from './components/deleteModal';
import InsertModal from './components/insertModal';
import { Container } from '../../styles';
import { USER_FILE_TYPES } from '~/utils/constants';

function buildFileName(file){
  return `${USER_FILE_TYPES[file.file_type]} - ${file.name}`
}

export default function Files({ user }) {
  const { id, files = [] } = user;
  const dispatch = useDispatch();

  const deleteFile = async (file) => {
    dispatch(setLoading(true));
    try {
      const response = await requestHandler(usersService.destroyFile, file);
      if (response && !response.error) {
        toast.success('Arquivo removido com sucesso');
        dispatch(getUserRequest(file.user_id));
        return true;
      }
    } catch (err) {
      toast.error('Não foi possível remover o arquivo');
    } finally {
      dispatch(setLoading(false));
    }
    return false;
  };

  return (
    <Container style={{ height: 'auto'}}>
      <ShadowBox style={{backgroundColor: '#fafafa'}}>

        <h3 style={{ display: 'flex' }}>
          Arquivos anexados{' '}
          {files && files.length > 0 && (
            <InsertModal userId={ id }/>
          )}
        </h3>

        {files && files.length > 0 ? (
           <ShadowBox className="boxfiles" id="boxfiles" style={{
            backgroundColor: 'white',
            display: 'flex',
            borderRadius: '0px',
            overflow: 'scroll',
            margin: '10px',
            padding: '60px',
            scrollDirection: 'vertical'
          }}>
            {files.map((file) => (
              <List.Item className="file-item">
                <List.Content floated="left">
                  <FaRegFileAlt size="20" />
                </List.Content>
                <a
                  href={file.url}
                  alt={buildFileName(file)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="body-link"
                >
                  {buildFileName(file)}
                </a>
                <List.Content floated="right">
                  <DeleteModal file={file} onConfirm={() => deleteFile(file)} />
                </List.Content>
              </List.Item>
            ))}
          </ShadowBox>
        ) : (
          <Segment placeholder>
            <Header icon>
              <Icon name="pdf file outline" />
              Nenhum documento anexado
            </Header>
            <InsertModal userId={id}/>
          </Segment>
        )}
      </ShadowBox>
    </Container>
  );
}

Files.propTypes = {
  user: PropTypes.object.isRequired,
};
