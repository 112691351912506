import React from 'react';
import { Header } from './styles';

import ProfileMenu from './profile';

export default function() {
  return (
    <Header>
      <ProfileMenu />
    </Header>
  );
}
