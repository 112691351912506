import React, { useEffect, useState } from 'react';
import {
  RowPayments,
  ColumnPayments,
  ColumnInfo,
  ColumnAccount,
} from './styles';

import CardBalance from './components/CardBalance';
import CardInfo from './components/CardInfo';
import ButtonRequestRansom from './components/ButtonRequestRansom';
import CardAccount from './components/CardAccount';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table, Pagination } from 'semantic-ui-react';
import Status from './components/Status';
import { format, parseISO } from 'date-fns';
import Filter from './components/filter';
import {
  loadPaymentList,
  loadPaymentsInfo,
} from '~/store/modules/payments/actions';
import { checkingAccountGet } from '~/store/modules/checkingAccount/actions';

import { setBreadcrumb } from '~/store/modules/global/actions';
import { formatCurrency } from './utils/formatCurrency';

export default function View(props) {
  const { pagination, paymentList, paymentInfo, currentPayment } = useSelector(
    (state) => state.payments
  );
  const { account } = useSelector((state) => state.checkingAccount);
  const currentUser = useSelector((state) => state.user);
  const [filtered, setFiltered] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBreadcrumb('PAYMENTS', props.match.params.status));
  }, [dispatch, props.match.params.status]);

  useEffect(() => {
    dispatch(checkingAccountGet({ user_id: currentUser.profile.id }));
  }, [dispatch, currentUser.profile.id]);

  useEffect(() => {
    dispatch(loadPaymentsInfo({ userId: currentUser.profile.id }));
    
    if(currentPayment && currentPayment.id){
      dispatch(
        loadPaymentList({
          user_id: currentUser.profile.id,
        })
      );
    }
  }, [dispatch, currentUser.profile.id, currentPayment]);

  useEffect(() => {
    dispatch(setBreadcrumb('PAYMENTS'));
    dispatch(
      loadPaymentList({
        user_id: currentUser.profile.id,
        page: pagination.page,
      })
    );
  }, [dispatch, pagination.page, currentUser.profile.id]);

  const onPageChange = (e, { activePage: page }) => {
    dispatch(
      loadPaymentList({
        ...pagination,
        ...filtered,
        page,
        user_id: currentUser.profile.id,
      })
    );
  };

  function renderPayments() {
    return paymentList.map((payment) => (
      <Table.Row key={payment.id}>
        <Table.Cell>
          {format(parseISO(payment.createdAt), 'dd/MM/yyyy hh:mm:ss')}
        </Table.Cell>
        <Table.Cell>
          {payment.bank_code} / {payment.branch_code} / {payment.account_number}
          -{payment.digit}
        </Table.Cell>
        <Table.Cell>
          {formatCurrency({ number: payment.amount_redemption })}
        </Table.Cell>
        <Table.Cell>
          <Status status={payment.status} />
        </Table.Cell>
        <Table.Cell>
          <Button.Group icon>
            <Link to={`/payments/${payment.id}`} className="table-link">
              Visualizar
            </Link>
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    ));
  }

  return (
    <>
      <div className="content">
        <h1>Pagamentos</h1>
        <RowPayments>
          <ColumnPayments>
            <CardBalance
              balance={paymentInfo && paymentInfo.balance_available}
            />
            <ButtonRequestRansom
              id={account.id}
              disabled={
                !account.id ||
                (paymentInfo && paymentInfo.balance_available === 0)
              }
              name={currentUser.profile.name}
              cpf={currentUser.profile.profile.cpf}
              bank={account.bank_code}
              agency={account.branch_code}
              checkingAccount={account.account_number}
              cost={0.5}
              tedAmount={paymentInfo && paymentInfo.balance_available}
              digit={account.digit}
            />
          </ColumnPayments>
          <ColumnInfo>
            <CardInfo
              received={paymentInfo && paymentInfo.total_received}
              lastMonth={paymentInfo && paymentInfo.last_month}
            />
          </ColumnInfo>
          <ColumnAccount>
            <CardAccount
              id={account.id}
              name={currentUser.profile.name}
              cpf={currentUser.profile.profile.cpf}
              bank={account.bank_code}
              agency={account.branch_code}
              checkingAccount={account.account_number}
              digit={account.digit}
              createdBy={account.created_by}
              userId={currentUser.profile.id}
            />
          </ColumnAccount>
        </RowPayments>
        <div className="actions">
          <Filter
            onSubmit={(data) => {
              dispatch(
                loadPaymentList({
                  user_id: currentUser.profile.id,
                  ...pagination,
                  ...data,
                  page: 1,
                })
              );
              setFiltered(data);
            }}
            initialData={pagination.search}
          />
        </div>
        {paymentList.length > 0 && (
          <>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Data</Table.HeaderCell>
                  <Table.HeaderCell>Banco / Ag. / CC</Table.HeaderCell>
                  <Table.HeaderCell>Valor</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Ações</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{renderPayments()}</Table.Body>
            </Table>
            <Pagination
              totalPages={pagination.pages}
              defaultActivePage={pagination.page}
              onPageChange={onPageChange}
              firstItem={null}
              lastItem={null}
              pointing
              secondary
            />
          </>
        )}
      </div>
    </>
  );
}
