import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import requestHandler from '../../middlewares/requestHandler';
import { setLoading } from '../global/actions';
import {
  scheduleAvailabilitySuccess,
  scheduleAvailabilityAndResourcesSuccess,
  scheduleFailure,
  createAppointmentSuccess,
  appoitmentsLoaded,
} from './actions';
import ScheduleAvailabilityService from '../../../services/scheduleAvailability';
import AppointmentsService from '../../../services/appointments';

function* getScheduleAvailability({ payload }) {
  yield put(setLoading(true));

  try {
    const response = yield call(
      requestHandler,
      ScheduleAvailabilityService.getScheduleAvailability,
      payload
    );
    if (response && response.availabilitiesAndResources.availabilities) {
      yield put(scheduleAvailabilitySuccess(response.availabilitiesAndResources.availabilities));
    } else {
      yield put(scheduleFailure());
    }
  } catch (err) {
    yield put(scheduleFailure());
  }
  yield put(setLoading(false));
}

function* scheduleAvailabilityAndResources({ payload }) {
  yield put(setLoading(true));

  try {
    const response = yield call(
      requestHandler,
      ScheduleAvailabilityService.getScheduleAvailability,
      payload
    );
    if (response && response.availabilitiesAndResources) {
      yield put(scheduleAvailabilityAndResourcesSuccess({
        availabilities: response.availabilitiesAndResources.availabilities,
        userInfo: response.availabilitiesAndResources.userSpecialtyInfo,
      }));
    } else {
      yield put(scheduleFailure());
    }
  } catch (err) {
    yield put(scheduleFailure());
  }
  yield put(setLoading(false));
}

function* create({ payload }) {
  yield put(setLoading(true));
  const response = yield call(requestHandler, AppointmentsService.create, {
    ...payload,
    where: 'Anywhere',
  });

  if (response) {
    yield put(createAppointmentSuccess());
    window.location.href = '/welcome';
  } else {
    yield put(scheduleFailure());
  }

  yield put(setLoading(false));
}

function* loadAppointments() {
  yield put(setLoading(true));

  try {
    const response = yield call(requestHandler, AppointmentsService.load);
    yield put(appoitmentsLoaded(response));
  } catch (error) {
    yield put(scheduleFailure());
  }

  yield put(setLoading(false));
}

function* cancelAppointment({ payload }) {
  yield put(setLoading(true));
  try {
    yield call(requestHandler, AppointmentsService.destroy, payload);
    toast.success('Agendamento cancelado com sucesso.');
    loadAppointments();
    setTimeout(() => window.location.href = '/welcome', 3000);
  } catch (error) {
    toast.error('Não foi possível cancelar o agendamento');
    yield put(scheduleFailure());
  }
  yield put(setLoading(false));
}

export default all([
  takeLatest(
    '@scheduling/SCHEDULE_AVAILABILITY_REQUEST',
    getScheduleAvailability
  ),
  takeLatest('@scheduling/CREATE_REQUEST', create),
  takeLatest('@scheduling/LOAD_APPOINTMENTS', loadAppointments),
  takeLatest('@scheduling/CANCEL_APPOINTMENT', cancelAppointment),
  takeLatest('@scheduling/SCHEDULE_AVAILABILITY_RESOURCES', scheduleAvailabilityAndResources),
]);
