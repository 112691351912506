import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import AppointmentList from './list';
import AppointmentView from './view';

export default function Appointments() {
  return (
    <Suspense fallback="loading">
        <Switch>
          <Route exact path="/appointments" component={AppointmentList} />
          <Route path="/appointments/:id/view" component={AppointmentView}/>
        </Switch>
    </Suspense>
  );
}
