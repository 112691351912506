import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Form } from '@rocketseat/unform';
import { Modal, Segment, Button } from 'semantic-ui-react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRequest } from '~/store/modules/users/actions';
import Input from '~/components/input';

export default function ConfirmationModal({
  selectedSlot,
  loading,
  onSubmit,
  specialtyType,
  patient,
}) {
  const parsedSlot = new Date(selectedSlot.start);
  const handleSubmit = (data) => {
    onSubmit(data);
  };
  const dispatch = useDispatch();
  const { profile: loggedUser } = useSelector((state) => state.user);
  const { currentUser } = useSelector((state) => state.users);
  const { availabilitiesAndResources } = useSelector(
    (state) => state.scheduling
  );
  const { currentAppointment } = useSelector((state) => state.appointments);
  useEffect(() => {
    dispatch(getUserRequest(patient));
  }, [patient, dispatch]);

  return (
    <Modal
      trigger={
        <Button type="button" color="red" disabled={loading}>
          Prosseguir
        </Button>
      }
    >
      <Modal.Header>Confirmar Agendamento</Modal.Header>
      <Modal.Content>
        <Form className="ui form" onSubmit={handleSubmit}>
          <h1 style={{ textAlign: 'center' }}>
            {format(parsedSlot, 'dd/MM/yyyy HH:mm')}
          </h1>
          <Segment.Group>
            {currentUser && (
              <>
                <Segment>
                  <span className="label">Nome: </span>
                  <span className="value">{currentUser.name}</span>
                </Segment>
                <Segment>
                  <span className="label">E-mail: </span>
                  <span className="value">{currentUser.email}</span>
                </Segment>
                <Segment>
                  <span className="label">Telefone: </span>
                  <span className="value">
                    {get(currentUser.profile, 'profile.phone')
                      ? currentUser.profile.phone
                      : 'N/D'}
                  </span>
                </Segment>
              </>
            )}
            {specialtyType === 'EXAM' && currentUser && (
              <>
                <Segment>
                  <span className="value">
                    Técnico: {availabilitiesAndResources.userInfo[0].user.name}
                  </span>
                </Segment>
                <Segment>
                  <span className="label">Endereço: </span>
                  <br />
                  <span className="value">
                    CEP:{' '}
                    {get(currentUser.profile, 'profile.zipcode')
                      ? currentUser.profile.zipcode
                      : 'N/D'}
                  </span>
                  <br />
                  <span className="value">
                    Número:{' '}
                    {get(currentUser.profile, 'profile.number')
                      ? currentUser.profile.number
                      : 'N/D'}
                  </span>
                  <br />
                  <span className="value">
                    Rua:{' '}
                    {get(currentUser.profile, 'profile.street')
                      ? currentUser.profile.street
                      : 'N/D'}
                  </span>
                  <br />
                  <span className="value">
                    Cidade:{' '}
                    {get(currentUser.profile, 'profile.city')
                      ? currentUser.profile.city
                      : 'N/D'}
                  </span>
                  <br />
                  <span className="value">
                    Estado:{' '}
                    {get(currentUser.profile, 'profile.state')
                      ? currentUser.profile.state
                      : 'N/D'}
                  </span>
                </Segment>
              </>
            )}
            {specialtyType === 'MDCL' && (
              <>
                <Segment>
                  <span className="value">
                    Médico: Dr.(a){' '}
                    {currentAppointment
                      ? currentAppointment.provider.name
                      : loggedUser.name}
                  </span>
                </Segment>
                <Segment>
                  <span className="label">Endereço: </span>
                  <br />
                  <span className="value">
                    CEP:{' '}
                    {
                      availabilitiesAndResources.userInfo[0].user
                        .professionalData.zipcode
                    }
                  </span>
                  <br />
                  <span className="value">
                    Número:{' '}
                    {
                      availabilitiesAndResources.userInfo[0].user
                        .professionalData.number
                    }
                  </span>
                  <br />
                  <span className="value">
                    Rua:{' '}
                    {
                      availabilitiesAndResources.userInfo[0].user
                        .professionalData.street
                    }
                  </span>
                  <br />
                  <span className="value">
                    Cidade:{' '}
                    {
                      availabilitiesAndResources.userInfo[0].user
                        .professionalData.city
                    }
                  </span>
                  <br />
                  <span className="value">
                    Estado:{' '}
                    {
                      availabilitiesAndResources.userInfo[0].user
                        .professionalData.state
                    }
                  </span>
                </Segment>
              </>
            )}
          </Segment.Group>
          <Input
            multiline
            label=""
            name="description"
            placeholder="Se desejar, informe alguma observação"
          />
          <Button color="red" type="submit" disabled={!selectedSlot || loading}>
            {loading ? 'Aguarde...' : 'Confirmar'}
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
}

ConfirmationModal.defaultProps = {
  specialtyType: '',
  patient: 0,
};

ConfirmationModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  selectedSlot: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  specialtyType: PropTypes.string,
  patient: PropTypes.number,
};
