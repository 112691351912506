import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Image } from 'semantic-ui-react';
import { get } from 'lodash';

import { signOut } from '../../../../store/modules/auth/actions';

const ProfileMenu = () => {
  const dispatch = useDispatch();
  const options = [
    {
      key: 'sign-out',
      text: 'Sair',
      icon: 'sign out',
      onClick: () => dispatch(signOut()),
    },
  ];
  const user = useSelector(state => state.user.profile);
  const trigger = (
    <span>
      {' '}
      <Image avatar src={get(user, 'profile.avatar_url')} /> {user.name}
    </span>
  );

  return <Dropdown trigger={trigger} options={options} />;
};

export default ProfileMenu;
