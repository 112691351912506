import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import {
  Button,
  Accordion,
  Icon,
  Form as SForm,
  Table,
  Pagination,
  Modal,
} from 'semantic-ui-react';
import { MdSearch, MdReplay } from 'react-icons/md';
import { parseISO, format } from 'date-fns';

import { setLoading } from '~/store/modules/global/actions';
import api from '~/services/api';
import requestHandler from '~/services/requestHandler';
import Input from '~/components/input';
import Select from '~/components/select';
import Datepicker from '~/components/date-picker';
import { Container } from './styles';
import { ButtonsContainer } from '../Users/styles';

const getAuditorList = pagination => {
  return requestHandler(api.get, '/auditors', { params: pagination });
};

const ACTIONS = {
  'Create mediation': 'Criou mediação',
  'Sign in': 'Entrou no sistema',
  'Add participant': 'Adicionou participante',
  'Cancel meeting': 'Cancelou reunião',
  'Upload avatar': 'Adicionou avatar',
  'Add comment': 'Adicinou comentário',
  'Add file': 'Adicionou arquivo',
  'Update mediation': 'Atualizou mediação',
  Create: 'Criou',
  Update: 'Atualizou',
  Delete: 'Apagou',
  'Reset password request': 'Solicitou esqueci minha senha',
  'Password reset': 'Reiniciou a senha',
  Register: 'Registrou-se',
};

const ACTION_OPTIONS = Object.keys(ACTIONS).map(k => ({
  key: k,
  value: k,
  text: ACTIONS[k],
}));

const ENTITIES = {
  User: 'Usuário',
  Mediation: 'Mediação',
  Project: 'Projeto',
};

const ENTITY_OPTIONS = Object.keys(ENTITIES).map(k => ({
  key: k,
  value: k,
  text: ENTITIES[k],
}));

const defaultPagination = {
  page: 1,
  created_at: null,
  enitity: null,
  entity_id: null,
  action: null,
};

export default function Auditor() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [pagination, setPagination] = useState(defaultPagination);
  const [auditorList, setAuditorList] = useState([]);
  const [filterOpened, setFilterOpened] = useState(false);
  const [createdAt, setCreatedAt] = useState('')
  const [entity, setEntity] = useState('')
  const [entityId, setEntityId] = useState('')
  const [action, setAction] = useState('')
  const [email, setEmail] = useState('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadAuditors = async (pag) => {
    dispatch(setLoading(true));
    try {
      const response = await getAuditorList(pag);
      if (response) {
        setAuditorList(get(response, 'records', []));
        setPagination({
          ...pag,
          ...get(response, 'pagination', defaultPagination),
        });
      }
    } catch (err) {
      toast.error('Não foi possível recuperar as auditorias');
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    const load = async () => {
      await loadAuditors(pagination);
    };
    load();
  }, [pagination, loadAuditors]);

  const onSubmitFilter = data => {
    loadAuditors({ ...pagination, ...data });
  };

  const onPageChange = (e, { activePage: page }) => {
    loadAuditors({ ...pagination, page });
  };

  const refreshFilter = () => {
    setEmail('')
    setEntity('')
    setEntityId('')
    setCreatedAt('')
    onSubmitFilter({
      created_at: '',
      entity: '',
      entity_id: '',
      action: '',
      email: ''
    })
  }

  return (
    <Container className="content">
      <h2>Log de Auditoria</h2>
      <Accordion>
        <Accordion.Title
          active={filterOpened}
          onClick={() => setFilterOpened(!filterOpened)}
        >
          <Icon name="dropdown" />
          Filtro
        </Accordion.Title>
        <Accordion.Content active={filterOpened}>
          <Form
            className="ui form"
            initialData={pagination}
            onSubmit={onSubmitFilter}
          >
            <SForm.Group widths="equal">
              <Datepicker
                name="created_at"
                value={createdAt}
                label="Criado em"
                onChange={v => setCreatedAt(v)}
              />
              <Select
                name="entity"
                value={entity}
                label="Entidade"
                clearable
                options={ENTITY_OPTIONS}
                onChange={(e, { value }) => setEntity(value)}
              />
            </SForm.Group>
            <SForm.Group widths="equal">
              <Input name="entity_id" label="ID da Entidade" value={entityId} onChange={(e) => setEntityId(e.target.value)}  />
              <Select
                name="action"
                value={action}
                label="Ação"
                clearable
                options={ACTION_OPTIONS}
                onChange={(e, { value }) => setAction(value)}
              />
            </SForm.Group>
            <SForm.Group widths="2">
              <Input name="email" label="E-mail" value={email} onChange={(e) => setEmail(e.target.value)}  />
            </SForm.Group>
            <ButtonsContainer>
              <Button type="submit" color="red" icon>
                <MdSearch />
                Buscar
              </Button>
              <Button
                type="button"
                default
                icon
                onClick={(e) => refreshFilter(e)}
              >
                <MdReplay />
                Voltar
              </Button>
            </ButtonsContainer>
          </Form>
        </Accordion.Content>
      </Accordion>
      {auditorList.length ? (
        <>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Data</Table.HeaderCell>
                <Table.HeaderCell>Usuário</Table.HeaderCell>
                <Table.HeaderCell>Entidade</Table.HeaderCell>
                <Table.HeaderCell>Ação</Table.HeaderCell>
                <Table.HeaderCell>ID da Entidade</Table.HeaderCell>
                <Table.HeaderCell>Ações</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {auditorList.map(row => (
                <Table.Row key={row.id}>
                  <Table.Cell>
                    {format(parseISO(row.created_at), 'dd/MM/yyyy hh:mm:ss')}
                  </Table.Cell>
                  <Table.Cell>{`${get(row, 'user.name')} (${t(
                    get(row, 'user.role.name')
                  )})`}</Table.Cell>
                  <Table.Cell>{t(get(row, 'entity'))}</Table.Cell>
                  <Table.Cell>{t(get(row, 'action'))}</Table.Cell>
                  <Table.Cell># {t(get(row, 'entity_id', '-'))}</Table.Cell>
                  <Table.Cell>
                    {!!get(row, 'content') && (
                      <Modal
                        closeIcon
                        trigger={
                          <button type="button" className="table-link">
                            visualizar
                          </button>
                        }
                      >
                        <Modal.Header>Registro de auditoria</Modal.Header>
                        <Modal.Content>
                          <p>
                            <strong>Data: </strong>
                            <span>
                              {format(
                                parseISO(row.created_at),
                                'dd/MM/yyyy hh:mm:ss'
                              )}
                            </span>
                          </p>
                          <p>
                            <strong>Entidade: </strong>
                            <span>{t(get(row, 'entity'))}</span>
                          </p>
                          <p>
                            <strong>ID da Entidade: </strong>
                            <span>{get(row, 'entity_id')}</span>
                          </p>
                          <p>
                            <strong>Ação: </strong>
                            <span>{t(get(row, 'actions'))}</span>
                          </p>
                          <p>
                            <strong>Conteúdo</strong>
                            <span>
                              <pre>
                                {JSON.stringify(
                                  get(row, 'content', 'N/D'),
                                  null,
                                  1
                                )}
                              </pre>
                            </span>
                          </p>
                        </Modal.Content>
                      </Modal>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Pagination
            totalPages={pagination.pages}
            defaultActivePage={pagination.page}
            onPageChange={onPageChange}
            firstItem={null}
            lastItem={null}
            pointing
            secondary
          />
        </>
      ) : (
        <div>Nenhum registro encontrado</div>
      )}
    </Container>
  );
}
