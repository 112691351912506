import styled from 'styled-components';

export const Aside = styled.aside`
  grid-area: aside;
  background-color: #ffffff;
  color: #666666;
  overflow-x: auto;
  border-right: 1px solid #e9e9e9;
  height: 100vh;

  .aside-body {
    position: relative;
  }

  svg{
    color: #333 !important;
  }
`;

export const Logo = styled.h2`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
`;

export const Menu = styled.ul`
  list-style: none;
  padding: 0;

  li {
    display: flex;
    flex-direction: column;
    font-size: 14px;

    a {
      display: flex;
      align-items: center;
      border-left: 0px solid transparent;
      padding: 10px;
      color: #666666;

      svg {
        margin-right: 15px;
        font-size: 22px;
      }

      &:hover {
        background-color: #eeeeee;
      }
    }
  }
`;

export const Header = styled.div`
  grid-area: header;
  display: flex;
  background: #ffffff;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  height: 55px;
`;
