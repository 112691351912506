import Axios from 'axios';
import { format, parseISO } from 'date-fns';
import { get } from 'lodash';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { MdDescription } from 'react-icons/md';
import { Button } from 'semantic-ui-react';
import * as XLSX from 'xlsx';
import { ButtonsContainer } from '~/pages/Users/styles';
import api from '~/services/api';
import { SPECIALTY_TYPES } from '~/utils/constants';

const ExportTable = ({
  type,
  providerName,
  requesterName,
  status,

  appointmentId,
  cpf,
}) => {
  function handleExport() {
    api
      .get(
        `/appointments/financial/all?requesterName=${requesterName}&providerName=${providerName}&type=${type}&status=${status}&cpf=${cpf}&id=${appointmentId}`
      )
      .then((response) => {
        let formatData = [];
        response.data.map((row) => {
          let formatedRow = {
            id: row.id,
            solicitante: get(row, 'requester.name', '-'),
            tipoServico: row.specialty
              ? SPECIALTY_TYPES[row.specialty.type]
              : '-',
            cpf: get(row, 'profile.cpf'),
            prestador: get(row, 'provider.name', '-'),
            dataCriacao: format(
              parseISO(row.created_at),
              'dd/MM/yyyy hh:mm:ss'
            ),
            status: row.status,
          };
          formatData.push(formatedRow);
        });
        const ws = XLSX.utils.json_to_sheet(formatData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'table.xlsx');
      });
  }
  return (
    <Button onClick={() => handleExport()} type="button" default icon>
      <MdDescription />
      Exportar Tabela
    </Button>
  );
};

export default ExportTable;
