import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { MdSave, MdBlock, MdClose, MdDelete, MdCheck } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { get } from 'lodash';

import { ActionsContainer } from '../styles';
import {
  setEditing,
  toggleSpecialtyRequest,
  destroySpecialty,
} from '../../../store/modules/specialties/actions';
export default function SpecialtyActions({ isEditing, currentSpecialty }) {
  const dispatch = useDispatch();
  return (
    <ActionsContainer>
      {isEditing ? (
        <>
          <Button type="submit" size="tiny" color="red" icon>
            <MdSave /> Gravar
          </Button>

        </>
      ) : (
        <Button
          type="button"
          size="tiny"
          color="red"
          icon
          onClick={() => dispatch(setEditing(true))}
        >
          <FaEdit /> Editar
        </Button>
      )}

      <Button type="button" size="tiny" color="red" icon onClick={() => dispatch(destroySpecialty(currentSpecialty))}>
        <MdDelete /> Apagar
      </Button>
      {get(currentSpecialty, 'enabled', true) ? (
        <Button
          type="button"
          size="tiny"
          default
          icon
          onClick={() => dispatch(toggleSpecialtyRequest(currentSpecialty))}
        >
          <MdClose /> Inativar
        </Button>
      ) : (
        <Button
          type="button"
          size="tiny"
          default
          icon
          onClick={() => dispatch(toggleSpecialtyRequest(currentSpecialty))}
        >
          <MdCheck /> Ativar
        </Button>
      )}
    </ActionsContainer>
  );
}

SpecialtyActions.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  currentSpecialty: PropTypes.object.isRequired,
};
