import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Form } from '@rocketseat/unform';
import { Modal, Segment, Button } from 'semantic-ui-react';
import { get } from 'lodash';

import Input from '~/components/input';

export default function ConfirmationModal({ selectedSlot, loading, onSubmit, specialtyType }) {
  const parsedSlot = new Date(selectedSlot.start);
  const handleSubmit = data => {
    onSubmit(data);
  };
  const { profile } = useSelector(state => state.user);
  const { availabilitiesAndResources } = useSelector(state => state.scheduling);

  return (
    <Modal
      trigger={
        <Button type="button" color="red" disabled={loading}>
          Prosseguir
        </Button>
      }
    >
      <Modal.Header>Confirmar Agendamento</Modal.Header>
      <Modal.Content>
        <Form className="ui form" onSubmit={handleSubmit}>
          <h1 style={{ textAlign: 'center' }}>
            {format(parsedSlot, 'dd/MM/yyyy HH:mm')}
          </h1>
          <Segment.Group>
            <Segment>
              <span className="label">Nome: </span>
              <span className="value">{profile.name}</span>
            </Segment>
            <Segment>
              <span className="label">E-mail: </span>
              <span className="value">{profile.email}</span>
            </Segment>
            <Segment>
              <span className="label">Telefone: </span>
              <span className="value">{get(profile, 'profile.phone')}</span>
            </Segment>
            {
              specialtyType === 'EXAM'
              ?
              <>
                <Segment>
                    <span className="value">Técnico: {availabilitiesAndResources.userInfo[0].user.name}</span>
                </Segment>
                <Segment>
                  <span className="label">Endereço: </span>
                  <br />
                  <span className="value">CEP: {profile.profile.zipcode}</span>
                  <br />
                  <span className="value">Número: {profile.profile.number}</span>
                  <br />
                  <span className="value">Rua: {profile.profile.street}</span>
                  <br />
                  <span className="value">Cidade: {profile.profile.city}</span>
                  <br />
                  <span className="value">Estado: {profile.profile.state}</span>
                </Segment>
              </>
              :
                <>
                  <Segment>
                    <span className="value">Médico: Dr.(a) {availabilitiesAndResources.userInfo[0].user.name}</span>
                  </Segment>
                  <Segment>
                    <span className="label">Endereço: </span>
                    <br />
                    <span className="value">CEP: {availabilitiesAndResources.userInfo[0].user.professionalData.zipcode}</span>
                    <br />
                    <span className="value">Número: {availabilitiesAndResources.userInfo[0].user.professionalData.number}</span>
                    <br />
                    <span className="value">Rua: {availabilitiesAndResources.userInfo[0].user.professionalData.street}</span>
                    <br />
                    <span className="value">Cidade: {availabilitiesAndResources.userInfo[0].user.professionalData.city}</span>
                    <br />
                    <span className="value">Estado: {availabilitiesAndResources.userInfo[0].user.professionalData.state}</span>
                  </Segment>
                </>
              }
          </Segment.Group>
          <Input
            multiline
            label=""
            name="description"
            placeholder="Se desejar, informe alguma observação"
          />
          <Button color="red" type="submit" disabled={!selectedSlot || loading}>
            {loading ? 'Aguarde...' : 'Confirmar'}
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  selectedSlot: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
