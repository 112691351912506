export function signInRequest(email, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password },
  };
}

export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function verifyAccess(payload) {
  return {
    type: '@auth/VERIFY_FORGOT',
    payload,
  }
}

export function resetPasswordRequest(payload) {
  return {
    type: '@auth/RESET_PASSWORD_REQUEST',
    payload,
  };
}

export function updatePasswdStatus(payload) {
  return {
    type: '@auth/PUT_PASSWD',
    payload,
  };
}

export function resetPasswordSuccess() {
  return {
    type: '@auth/RESET_PASSWORD_SUCCESS',
  };
}

export function resetPasswordUpdateRequest({
  token,
  userId,
  password,
  confirmPassword,
}) {
  return {
    type: '@auth/RESET_PASSWORD_UPDATE_REQUEST',
    payload: {
      token,
      userId,
      password,
      confirmPassword,
    },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}
