export const specialtyListRequest = ( payload ) => {
  return {
    type: '@specialties/LIST_REQUEST',
    payload
  };
};

export const specialtyListSuccess = (specialtyList, pagination) => {
  return {
    type: '@specialties/LIST_SUCCESS',
    payload: {
      specialtyList,
      pagination,
    },
  };
};

export const getSpecialtyRequest = id => {
  return {
    type: '@specialties/GET_REQUEST',
    payload: id,
  };
};

export const destroySpecialty = id => {
  return {
    type: '@specialties/DESTROY_REQUEST',
    payload: id,
  };
};

export const getSpecialtySuccess = specialty => {
  return {
    type: '@specialties/GET_SUCCESS',
    payload: specialty,
  };
};

export const createSpecialtyRequest = data => {
  return {
    type: '@specialties/CREATE_REQUEST',
    payload: data,
  };
};

export const updateSpecialty = (id, data) => {
  return {
    type: '@specialties/UPDATE_REQUEST',
    payload: {
      id,
      data,
    },
  };
};

export const updateSpecialtySuccess = specialty => {
  return {
    type: '@specialties/UPDATE_SUCCESS',
    payload: specialty,
  };
};

export const toggleSpecialtyRequest = specialty => {
  return {
    type: '@specialties/TOGGLE_REQUEST',
    payload: specialty,
  };
};

export const setEditing = value => {
  return {
    type: '@specialties/SET_EDITING',
    payload: value,
  };
};

export const createSpecialty = payload => ({
  type: '@specialties/CREATE_SPECIALTY',
  payload,
});

export const specialtyCreated = () => ({
  type: '@specialties/SPECIALTY_CREATED',
});

export const specialtiesFailed = () => {
  return {
    type: '@specialties/FAILED',
  };
};

// New Actions

export const bulkActivateSpecialtyRequest = specialties => {
  return {
    type: '@specialties/BULK_ACTIVATE_REQUEST',
    payload: specialties,
  };
};

export const bulkInactivateSpecialtyRequest = specialty => {
  return {
    type: '@specialties/INACTIVATE_REQUEST',
    payload: specialty,
  };
};

export const bulkDestroySpecialtyRequest = specialties => ({
  type: '@specialties/BULK_DESTROY_REQUEST',
  payload: specialties,
});

export const loadSpecialtyParticipations = ({ page = 1, ...rest }) => ({
  type: '@specialties/LOAD_PARTICIPATIONS',
  payload: {
    page,
    ...rest,
  },
});

export const specialtyParticipationsLoaded = (records, pagination) => ({
  type: '@specialties/PARTICIPATIONS_LOADED',
  payload: { records, pagination },
});
