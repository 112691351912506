import styled from 'styled-components';
import { darken } from 'polished';

const red = '#c02027';
export const Container = styled.div`
  .ui.segment.active.tab {
    min-height: 350px;
  }
  .fc-button.fc-button-primary {
    background-color: ${red};
    border-color: ${darken(0.1, red)};

    &.fc-button-active {
      border-color: ${darken(0.1, red)};
      background-color: ${darken(0.05, red)};
    }
  }
  .fc-day-grid-event .fc-time {
    display: none;
  }
`;
