import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import requestHandler from '../../middlewares/requestHandler';
import { setLoading } from '../global/actions';
import {
  specialtiesFailed,
  specialtyListRequest,
  specialtyListSuccess,
  getSpecialtyRequest,
  getSpecialtySuccess,
  setEditing,
} from './actions';
import specialtiesService from '../../../services/specialties';
import history from '../../../services/history';

function* listSpecialties({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      specialtiesService.list,
      payload
    );

    yield put(
      specialtyListSuccess(
        get(response, 'records', []),
        get(response, 'pagination', {})
      )
    );
  } catch (err) {
    toast.error(err);
    yield put(specialtiesFailed());
  }
  yield put(setLoading(false));
}

function* getSpecialty({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      specialtiesService.get,
      payload
    );
    yield put(getSpecialtySuccess(response));
    yield put(setLoading(false));
  } catch (err) {
    toast.error(err);
    yield put(specialtiesFailed());
    yield put(setLoading(false));
  }
  yield put(setLoading(false));
}

function* activateSpecialty({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      specialtiesService.toggleStatus,
      payload
    );
    yield put(getSpecialtyRequest(response.id));
    toast.success('Especialidade ativada com sucesso.');
  } catch (err) {
    toast.error('Ocorreu um erro ao tentar ativar a especialidade');
    yield put(specialtiesFailed());
  }
  yield put(setLoading(false));
}

function* inactivateSpecialty({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      specialtiesService.toggleStatus,
      payload
    );

    yield put(getSpecialtyRequest(response.id));
    toast.success('Especialidade desativada com sucesso.');
  } catch (err) {
    toast.error('Ocorreu um erro ao tentar desativar a especialidade');
  }
  yield put(setLoading(false));
}

function* reloadList() {
  const pagination = yield select(state => state.specialties.pagination);
  yield put(specialtyListRequest(pagination));
}

function* bulkActivate({ payload }) {
  yield put(setLoading(true));
  try {
    yield all(
      payload.map(specialty => {
        return call(requestHandler, specialtiesService.toggleStatus, specialty);
      })
    );

    yield call(reloadList);
    toast.success('Especialidades ativadas com sucesso');
  } catch (error) {
    toast.error(error.toString());
    yield put(specialtiesFailed());
  } finally {
    yield put(setLoading(false));
  }
}

function* bulkInactivate({ payload }) {
  yield put(setLoading(true));
  try {
    yield all(
      payload.map(specialty => {
        return call(requestHandler, specialtiesService.toggleStatus, specialty);
      })
    );

    yield call(reloadList);
    toast.success('Especialidades inativadas com sucesso');
  } catch (error) {
    toast.error(error.toString());
    yield put(specialtiesFailed());
  } finally {
    yield put(setLoading(false));
  }
}

function* destroy({ payload }) {
  yield put(setLoading(true));
  try {
    yield call(requestHandler, specialtiesService.destroy, payload);
    yield call(reloadList);
    history.push('/specialties');
    toast.success('Especialidade removida com sucesso');
  } catch (error) {
    toast.error(error.toString());
    yield put(specialtiesFailed());
  } finally {
    yield put(setLoading(false));
  }
}

function* bulkDestroy({ payload }) {
  yield put(setLoading(true));
  try {
    yield all(
      payload.map(specialty => {
        return call(requestHandler, specialtiesService.destroy, specialty);
      })
    );

    yield call(reloadList);
    toast.success('Especialidades removidas com sucesso');
  } catch (error) {
    toast.error(error.toString());
    yield put(specialtiesFailed());
  } finally {
    yield put(setLoading(false));
  }
}

function* toggleSpecialty({ payload }) {
  try {
    yield put(setLoading(true));
    const { id, enabled } = payload;
    const response = yield call(requestHandler, specialtiesService.update, {
      id,
      data: { enabled: !enabled },
    });
    yield put(getSpecialtySuccess(response));
    toast.success(
      `Especialidade ${enabled ? 'inativada' : 'ativada'} com sucesso`
    );
  } catch (error) {
    if (get(error, 'response.status', 500) >= 500) {
      toast.error(
        `Ocorreu um erro ao ${
          payload.enabled ? 'inativar' : 'ativar'
        } a especialidade`
      );
    }
    yield put(specialtiesFailed());
  }
  yield put(setLoading(false));
}

function* createSpecialty({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      specialtiesService.create,
      payload
    );
    toast.success('Especialidade criada com sucesso');
    yield put(getSpecialtySuccess(response));
    history.push(`/specialties/`);
  } catch (err) {
    if (err.response.status >= 500) {
      toast.error('Ocorreu um erro ao criar a especialidade');
    }
    yield put(specialtiesFailed());
  }
  yield put(setLoading(false));
}

function* updateSpecialty({ payload }) {
  try {
    yield put(setLoading(true));
    const { id, data } = payload;
    const response = yield call(requestHandler, specialtiesService.update, {
      id,
      data,
    });
    if (response) {
      toast.success('Especialidade atualizada com sucesso');
      yield put(setEditing(false));
      yield put(getSpecialtySuccess(response));
    } else {
      yield put(specialtiesFailed());
    }
  } catch (err) {
    if (err.response.status >= 500) {
      toast.error('Ocorreu um erro ao atuaizar a especialidade');
    }
    yield put(specialtiesFailed());
  }
  yield put(setLoading(false));
}

export default all([
  takeLatest('@specialties/LIST_REQUEST', listSpecialties),
  takeLatest('@specialties/GET_REQUEST', getSpecialty),
  takeLatest('@specialties/CREATE_REQUEST', createSpecialty),
  takeLatest('@specialties/UPDATE_REQUEST', updateSpecialty),
  takeLatest('@specialties/TOGGLE_REQUEST', toggleSpecialty),
  takeLatest('@specialties/ACTIVATE_REQUEST', activateSpecialty),
  takeLatest('@specialties/INACTIVATE_REQUEST', inactivateSpecialty),
  takeLatest('@specialties/BULK_ACTIVATE_REQUEST', bulkActivate),
  takeLatest('@specialties/BULK_INACTIVATE_REQUEST', bulkInactivate),
  takeLatest('@specialties/DESTROY_REQUEST', destroy),
  takeLatest('@specialties/BULK_DESTROY_REQUEST', bulkDestroy),
]);
