import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Divider, Button, Form as SForm } from 'semantic-ui-react';
import * as Yup from 'yup';
import { MdAdd } from 'react-icons/md';
import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import { PROFILE } from '~/utils/constants';
import {
  format,
  parseISO,
  startOfDay,
  endOfDay,
} from 'date-fns';

import availabilityService from '~/services/scheduleAvailability';
import requestHandler from '~/services/requestHandler';
import TimeInput from './time-input';
import Select from '~/components/select';
import { setLoading } from '~/store/modules/global/actions';
import { List } from './styles';
import moment from 'moment';

const daysOptions = [
  { key: 0, value: 'Monday', text: 'Segunda-feira' },
  { key: 1, value: 'Tuesday', text: 'Terça-feira' },
  { key: 2, value: 'Wednesday', text: 'Quarta-feira' },
  { key: 3, value: 'Thursday', text: 'Quinta-feira' },
  { key: 4, value: 'Friday', text: 'Sexta-feira' },
  { key: 5, value: 'Saturday', text: 'Sábado' },
  { key: 6, value: 'Sunday', text: 'Domingo' },
];

function canSetAvailability(loggedUser) {
  return [PROFILE.DOCTOR, PROFILE.PROVIDER].includes(loggedUser.roleId);
}

const typeOptions = [
  { key: 0, value: 'allow_period', text: 'Liberar' },
  { key: 1, value: 'block_period', text: 'Bloquear' },
];

const REQUIRED = 'obrigatório';

const dayTimeSchema = Yup.object().shape({
  day: Yup.string().required(REQUIRED),
  start: Yup.string().required(REQUIRED),
  end: Yup.string().required(REQUIRED),
});

const periodSchema = Yup.object().shape({
  type: Yup.string().required(REQUIRED),
  start: Yup.date()
    .typeError(REQUIRED)
    .required(REQUIRED),
  end: Yup.date()
    .typeError(REQUIRED)
    .required(REQUIRED),
});

export default function Timekit() {
  const dispatch = useDispatch();
  const loggedUser = useSelector(state => state.user.profile);
  const [periods, setPeriods] = useState([]);
  const [daysAndTime, setDaysAndTime] = useState([]);
  const { t } = useTranslation();

  const updateAvailability = async payload => {
    dispatch(setLoading(true));
    await requestHandler(availabilityService.setAvailabilityConfig, payload);
    dispatch(setLoading(false));
  };

  const loadAvailabilityConfigs = async () => {
    dispatch(setLoading(true));
    try {
      const response = await requestHandler(
        availabilityService.getAvailabilityConfig
      );

      if (response) {
        const p = [];
        const dt = [];

        response.forEach(i => {
          if (Object.keys(i).includes('allow_day_and_time')) {
            dt.push(i);
          } else {
            p.push(i);
          }
        });

        setPeriods(p);
        setDaysAndTime(dt);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const dayTimeSubmit = async data => {
    const start = moment(data.start, 'HH:mm');
    const end = moment(data.end, 'HH:mm');
    if (!start.isValid()) {
      toast.error('Horário inicial inválido.');
    } else if (!end.isValid()) {
      toast.error('Horário final inválido.');
    } else if (!end.isAfter(start)) {
      toast.error('Até precisa ser menor que De');
    } else {
      const payload = [
        ...daysAndTime,
        ...periods,
        { allow_day_and_time: { ...data } },
      ];
      await updateAvailability(payload);
      await loadAvailabilityConfigs();
    }
  };

  const periodSubmit = async data => {
    const record = {
      [data.type]: {
        start: startOfDay(data.start),
        end: endOfDay(data.end),
      },
    };
    const payload = [...daysAndTime, ...periods, record];

    await updateAvailability(payload);
    await loadAvailabilityConfigs();
  };

  const deletePeriod = async period => {
    const payload = [...periods.filter(i => i !== period), ...daysAndTime];
    await updateAvailability(payload);
    await loadAvailabilityConfigs();
  };

  const deleteDayAndTime = async dayAndTime => {
    const payload = [...daysAndTime.filter(i => i !== dayAndTime), ...periods];
    await updateAvailability(payload);
    await loadAvailabilityConfigs();
  };

  useEffect(() => {
    loadAvailabilityConfigs();
  }, [loadAvailabilityConfigs]);

  if (!canSetAvailability(loggedUser)) {
    return (
      <>
        <p>
          Usuário não é mediador ou o recurso do TimeKit não está configurado
          corretamente.
        </p>
      </>
    );
  }

  return (
    <>
      <div>
        <h3>Horários recorrentes de trabalho</h3>
        <p>Defina os horários semanais que a sua agenda estará disponível.</p>
        <Form
          className="ui form"
          onSubmit={dayTimeSubmit}
          schema={dayTimeSchema}
        >
          <SForm.Group>
            <Select name="day" options={daysOptions} />
            <TimeInput name="start" placeholder="De" initialValue="08:00" />
            <TimeInput name="end" placeholder="Até" initialValue="10:00" />
            <Button icon>
              <MdAdd />
            </Button>
          </SForm.Group>
        </Form>
        {daysAndTime.length > 0 ? (
          <List selection>
            {daysAndTime.map(dt => (
              <div
                className="list-item"
                key={dt.allow_day_and_time.day + dt.allow_day_and_time.start}
              >
                <div>
                  {t(dt.allow_day_and_time.day)}
                  <span> de </span>
                  {dt.allow_day_and_time.start}
                  <span> até </span>
                  {dt.allow_day_and_time.end}
                </div>
                <Button
                  type="button"
                  circular
                  icon="delete"
                  size="tiny"
                  onClick={() => deleteDayAndTime(dt)}
                />
              </div>
            ))}
          </List>
        ) : (
          <p>Nenhuma configuração. Todos os horários estão disponíveis.</p>
        )}
      </div>
      <Divider />
      <div>
        <h3>Horários específicos</h3>
        <p>
          Você pode habilitar ou bloquear intervalos específicos de tempo além
          dos horários recorrentes. Esse recurso é útil para feriados,
          compromissos fora do escritório e agendas dinâmicas que mudam com
          frequência.
        </p>
        <Form className="ui form" onSubmit={periodSubmit} schema={periodSchema}>
          <SForm.Group>
            <Select name="type" options={typeOptions} />
            <TimeInput name="start" placeholder="De" initialValue="08:00" />
            <TimeInput name="end" placeholder="Até" initialValue="10:00" />
            <Button icon>
              <MdAdd />
            </Button>
          </SForm.Group>
        </Form>
        {periods.length > 0 ? (
          <List>
            {periods.map(p =>
              Object.keys(p).map(key => (
                <div className="list-item" key={key + p[key].start}>
                  <div>
                    {key === 'allow_period' ? 'Liberado' : 'Bloqueado'}
                    <span> de </span>
                    {format(parseISO(p[key].start), 'dd/MM/yyyy HH:mm:ss')}
                    <span> até </span>
                    {format(parseISO(p[key].end), 'dd/MM/yyyy HH:mm:ss')}
                  </div>
                  <Button
                    circular
                    icon="delete"
                    size="tiny"
                    type="button"
                    onClick={() => deletePeriod(p)}
                  />
                </div>
              ))
            )}
          </List>
        ) : (
          <p>Nenhuma configuração.</p>
        )}
      </div>
    </>
  );
}
