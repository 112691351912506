import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@rocketseat/unform';
import { Button } from 'semantic-ui-react';
import * as Yup from 'yup';

import { resetPasswordRequest } from '../../../store/modules/auth/actions';
import logo from '../../../assets/homeup_horizontal_logo.svg';
import Input from '../../../components/input';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail obrigatório'),
});

export default function ResetPasswordRequest() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  const handleSubmit = ({ email }) => {
    dispatch(resetPasswordRequest({ email }));
  };

  return (
    <>
      <div className="container-header">
        <h1>
          <img src={logo} alt="Instituto Vertus de Mediação" />
        </h1>
        <h2>Para recuperar a sua senha, informe o seu e-mail de cadastro</h2>
      </div>
      <Form className="ui form" schema={schema} onSubmit={handleSubmit}>
        <Input
          label="E-mail"
          name="email"
          type="email"
          placeholder="nome@dominio.com"
        />
        <Button type="submit" color="red">
          {loading ? 'Carregando...' : 'Confirmar'}
        </Button>
        <Link to="/">voltar ao login</Link>
      </Form>
    </>
  );
}
