import React, { useState } from 'react';
import { parseISO, format, getYear } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { Divider, Grid, Input } from 'semantic-ui-react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PROFILE } from '../../../../utils/constants';
import ProfileImage from'../../../../assets/default_avatar.png';
import { Container, Title } from './styles';
import { ShadowBox } from '../../../Appointments/styles';
import { uploadAvatar, updateUserRequest } from '~/store/modules/users/actions';
import Files from '../Files';

export default function UserView() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.users);
  const [passwordVisible, setPaswordVisible] = useState(false);
  let control = false;
  let now = new Date();
  let dateUser = currentUser.profile.birthday ? currentUser.profile.birthday : null;
  var [ano, mes, dia] = dateUser.split('-').map(Number);

  function getLastWeeksDate() {
    const tdate = new Date();
    return new Date(tdate.getFullYear(), tdate.getMonth(), tdate.getDate() - 7);
  }
  let lastWeek = getLastWeeksDate();
  lastWeek = format(lastWeek, 'dd/MM/yyyy');
  now = format(now, 'dd/MM/yyyy');
  dateUser = format(parseISO(dateUser), 'dd/MM/yyyy')

  var d1 = lastWeek.split("/");
  var d2 = now.split("/");
  var c = dateUser.split("/");

  var from = new Date(d1[2], parseInt(d1[1])-1, d1[0]);  // -1 because months are from 0 to 11
  var to   = new Date(d2[2], parseInt(d2[1])-1, d2[0]);
  var check = new Date(c[2], parseInt(c[1])-1, c[0]);

  if(check >= from && check <= to){
    control = true;
  }

  if(ano > parseInt(getYear(now)) || ano < 1800) {
    let result = {profile: {birthday: now}}
    dispatch(updateUserRequest(currentUser.id, result));
  }

  const avatarDOC = () => {
    if(process.env.REACT_APP_RPA_ID == currentUser.id) return (<img src={currentUser.profile.avatar} alt="Profile" />)
    else if(currentUser.roleId === 4) return (<img src={currentUser.profile.avatar_url} alt="Profile" />)
    else if(currentUser.roleId === 6) return (<img src={currentUser.profile.avatar_url} alt="Profile" />)
    else return (<img src={ProfileImage} alt="Profile" />)
  }
  const formatAddress = (data) => (
    <><b>
      <span className="label"><i className="road icon" style={{color: '#bfbfbf'}}></i> Rua:</span> </b> {get(data, 'street')}{' '}
      <b>N°: </b> {get(data, 'number')}{' '}<br></br>
      <b><i className="bookmark icon" style={{color: '#bfbfbf'}}></i><span className="label">Complemento: </span></b>{get(data, 'complement') && ` ${get(data, 'complement')}`}
      <br />
      <b> <i className="building icon" style={{color: '#bfbfbf'}}></i><span className="label">Cidade: </span></b> {get(data, 'district')} {get(data, 'city')}-{get(data, 'state')}
      <br />
      <i className="map marker alternate icon" style={{color: "#bfbfbf"}}></i>
      <b><span className="label"> CEP: </span></b> {get(data, 'zipcode')}
    </>
  );

  const onChangeAvatar = (e) => {
    dispatch(uploadAvatar(currentUser.id, e.target.files[0]));
  };

  return (
    <>
    <Container>
      <ShadowBox
        style={{
          borderRadius: '0px',
          width: '100%',
          height: '100%',
          alignItems: 'stretch',
          justifyContent: 'center',
        }}
      >
      <div style={{display:"flex"}}>
        {/* N/A */}
        {currentUser && (
          <>
            <div className="profile"
              style={{
                flex: 4,
                marginTop: '20px',
                backgroundColor:'none',
                flexWrap: 'wrap',
              }}>
              <div className="profile-image" >
                <label htmlFor="avatar" className="image-dimmer">
                  Clique para alterar
                  <input
                    type="file"
                    id="avatar"
                    name="avatar"
                    accept="image/*"
                    onChange={onChangeAvatar}
                  />
                </label>
                {avatarDOC()}
              </div>

              <div className="profile-data">
                <div className="name">{get(currentUser, 'name')}</div>
                <div className="since">
                  {currentUser.created_at &&
                    `Desde ${format(
                      parseISO(currentUser.created_at),
                      'dd/MM/yyyy'
                    )}`}
                </div>
                <div className="role">
                  {t(get(currentUser, 'role.name'))} de{' '}
                  {get(currentUser, 'profile.city')}
                </div>
              </div>
            </div>

            <div className="additional-info"
              style={{
                flex: 4,
                backgroundColor:'inhert',
              }}>
              <Title> Dados Pessoais</Title>
              <div className="field-view">
                { currentUser.roleId === 4
                ?
                  <span className="label"> <i className="user md icon" style={{color: '#bfbfbf'}}></i> Tipo de conta:</span>
                :
                  <span className="label"> <i className="user icon" style={{color: '#bfbfbf'}}></i> Tipo de conta:</span>
                }

                {t(get(currentUser, 'role.name'))}
              </div>
              <div className="field-view">
              { currentUser.status == 'ACTV'
                ?
                  <span className="label"> <i className="id badge icon" style={{color: '#58d05e'}}></i> Status:</span>
                :
                  <span className="label"> <i className="user times icon" style={{color: '#FF5733'}}></i> Status:</span>
                }
                {t(get(currentUser, 'status'))}
              </div>
              <div className="field-view">
                <span className="label"><i className="bookmark icon" style={{color: '#bfbfbf'}}></i>Nome:</span>
                {currentUser.name}
              </div>
              <div className="field-view">
                <span className="label"><i className="envelope icon" style={{color: '#bfbfbf'}}></i>Email:</span>
                {currentUser.email}
              </div>
              <div className="field-view">
                <span className="label"><i className="calendar alternate outline icon" style={{color: '#bfbfbf'}}></i>Data de nascimento:</span>
                { control ?
                  <div className="wrapper">
                    <div className="tooltip">Necessário editar esse campo.</div>
                    {
                     ' ¿¿¿¿¿¿¿ ' + dateUser + ' ??????? '
                    }
                  </div>
                  :
                    dateUser
                }
              <div className="field-view">
                <span className="label"><i className="id card icon" style={{color: '#bfbfbf'}}></i>CPF:</span>
                {get(currentUser, 'profile.cpf')}
              </div>
              <div className="field-view">
                <span className="label"><i className="id card outline icon" style={{color: '#bfbfbf'}}></i> CNPJ:</span>
                {get(currentUser, 'profile.cnpj') || 'N/A'}
              </div>
              <div className="field-view">
                <span className="label"><i className="phone square icon" style={{color: '#bfbfbf'}}></i>Telefone celular:</span>
                {get(currentUser, 'profile.phone')}
              </div>
              </div>
            </div>

            <div className="additional-info"
              style={{
                flex: 4,
                backgroundColor:'none',
              }}>

              <Title> Locais Cadastrados </Title>

                <div className="field-view">
                  <h4 className="label" style={{color: 'gray', margin: '10px'}}> Endereço Pessoal </h4>
                  {formatAddress(currentUser.profile) }
                </div>
                {[PROFILE.DOCTOR, PROFILE.ASSDOCTOR].includes(currentUser.roleId) && (
                  <div className="field-view">
                    <h4 className="label" style={{color: 'gray', margin: '10px'}}> Endereço Profissional </h4>
                    {get(currentUser, 'professionalData') && formatAddress(get(currentUser, 'professionalData'))}
                  </div>
                )}
            </div>

            <div className="additional-info"
              style={{
                flex: 4,
                backgroundColor:'none',

              }}>
              { [PROFILE.PROVIDER, PROFILE.DOCTOR, PROFILE.ASSDOCTOR].includes(currentUser.roleId)
              &&
                (<>
                  <Title> Dados Profissionais </Title>
                  <div className="field-view">
                    <span className="label"><i className="id card outline icon" style={{color: '#bfbfbf'}}></i>
                      CREMERS:
                    </span>
                    {get(currentUser, 'professionalData.professional_id')}
                  </div>
                  <div className="field-view">
                    <span className="label"><i className="stethoscope icon" style={{color: "#bfbfbf"}}></i> Especialidade(s):</span>
                    <ShadowBox style={{ height: '200px',overflow: "scroll"}}>
                      { currentUser.specialties.length
                      ?
                        currentUser.specialties.map((specialty) => (
                          <div key={specialty.id}
                          >
                            {specialty.name}
                          </div>
                        ))
                      :
                        <p> Nenhuma especialidade informada </p>
                      }
                    </ShadowBox>
                  </div>
                </>)
              }
              { [PROFILE.PATIENT].includes(currentUser.roleId) &&
              (
                <>
                <Title>Dados do Plano </Title>
                 <div className="field-view">
                    <span className="label"><i className="heartbeat icon" style={{color: "#bfbfbf"}}></i>Nome do plano:</span>
                    <span className="field-view">
                      {get(currentUser, 'profile.insurer_name') || 'N/A'}
                    </span>
                    <br />
                    <span className="label"><i className="hospital symbol icon" style={{color: "#bfbfbf"}}></i>Tipo do plano:</span>
                    <span className="field-view">
                      {get(currentUser, 'profile.insurance_type')
                        ? get(currentUser, 'profile.insurance_type')
                        : 'Todos'}
                    </span>
                    <br />
                    <span className="label"> <i className="id badge outline icon" style={{color: "#bfbfbf"}}></i>Número do plano:</span>
                    <span className="field-view">
                      {get(currentUser, 'profile.insurance_account') || 'N/A'}
                    </span>
                  </div>
                  <div className="field-view">
                    <h4 className="label" style={{color: 'gray', margin: '10px'}}>Credenciais do Plano </h4>
                    <span className="label"><i className="user circle icon" style={{color: "#bfbfbf"}}></i>Usuário:</span>
                    <span className="field-view">
                      {get(currentUser, 'profile.insurance_username') || 'N/A'}
                    </span>
                    <br />
                    <div style={{display: 'flex', margin: '0px'}}>
                      <div className="label" style={{width: '45%'}}>
                        <i className="key icon" style={{color: "#bfbfbf", marginTop:'18px'}}></i>Senha:</div>
                      <div className="field-view"
                        style={{
                          width: '55%',
                          height: '100px',
                          marginRight: '50px'
                        }}>
                        <Input
                          onClick={() => {
                            setPaswordVisible(old => !old)
                          }}
                          placeholder='Senha'
                          id="insurance-password"
                          value={get(currentUser, 'profile.insurance_password')}
                          icon={passwordVisible ? 'eye slash': 'eye'}
                          type={passwordVisible ? 'text': 'password'}
                        />
                      </div>
                    </div>
                    <br />
                  </div>
                </>
              )}
            </div>
          </>
        )}
        </div>
    <Divider />
    <Files user={currentUser} />
    </ShadowBox>
    </Container>
    </>
  );
}
