import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { signOut } from '~/store/modules/auth/actions';
import { loadAppointments } from '~/store/modules/scheduling/actions';
import { setLoading } from '~/store/modules/global/actions';
import specialtyService from '~/services/specialties';
import appointmentService from '~/services/appointments';
import requestHandler from '~/services/requestHandler';
import { Background, Container } from './styles';
import logo from '~/assets/homeup_logo.svg';

import Scheduler from '~/components/scheduler';
import Appointments from './components/appointments';
import ConfirmationModal from './components/confirmationModal';
import SpecialtyModal from './components/SpecialtyModal';

export default function Welcome() {
  const [modalOpened, setModalOpened] = useState(false);
  const [specialtyModalOpened, setSpecialtyModalOpened] = useState(false);
  const specialtyId = useRef(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [specialties, setSpecialties] = useState([]);
  const [specialtyType, setSpecialtyType] = useState(null);
  const { name } = useSelector(state => state.user.profile);
  const { availabilitiesAndResources, loading } = useSelector(
    state => state.scheduling
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const loadSpecialties = async () => {
      const res = await requestHandler(specialtyService.listAllActive);
      setSpecialties(res);
    };

    loadSpecialties();
    dispatch(loadAppointments());
  }, [dispatch]);

  const logout = () => {
    dispatch(signOut());
  };

  const onSubmit = async ({ description, is_remote }) => {
    dispatch(setLoading(true));
    try {
      const appointment = await appointmentService.create({
        slot: selectedSlot,
        is_remote,
        resource: selectedSlot.resources[0].id,
        description,
        specialty_id: specialtyId.current,
        providerId: availabilitiesAndResources.userInfo[0].user.id,
      });

      if (appointment) {
        toast.success('Agendamento efetuado com sucesso');
        dispatch(loadAppointments());
        setModalOpened(false);
      }
    } catch (err) {
      toast.error('Não foi possível criar o agendamento');
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onSpecialtyModalSubmit = data => {
    specialtyId.current = data.specialty_id;
    setModalOpened(true);
    setSpecialtyModalOpened(false);
  };

  return (
    <>
      <Background />
      <Container>
        <div className="container-header">
          <h1>
            <img src={logo} alt="Instituto Vertus de Mediação" />
          </h1>
          <h2>Seja bem-vindo!</h2>
          <p>{name.split(' ')[0]}, o que você deseja fazer?</p>
          <Appointments />
          <>
            <Button
              type="button"
              onClick={() => {
                setSpecialtyType('EXAM');
                setSpecialtyModalOpened(true);
              }}
              fluid
              color="red"
            >
              Agendar um exame em casa
            </Button>
            <Button
              type="button"
              fluid
              color="red"
              onClick={() => {
                setSpecialtyType('MDCL');
                setSpecialtyModalOpened(true);
              }}
            >
              Agendar uma consulta
            </Button>
          </>
          <Button color="red" basic onClick={logout}>
            Sair
          </Button>
        </div>
      </Container>
      <Modal open={modalOpened} onClose={() => setModalOpened(false)}>
        <Modal.Header>Escolha um horário disponível</Modal.Header>
        <Modal.Content>
          <Scheduler
            specialtyId={specialtyId.current}
            selectedSlot={selectedSlot}
            onSlotSelect={slot => setSelectedSlot(slot)}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button default onClick={() => setModalOpened(false)}>
            Cancelar
          </Button>
          {selectedSlot && (
            <ConfirmationModal
              loading={!selectedSlot || loading}
              selectedSlot={selectedSlot}
              onSubmit={onSubmit}
              specialtyType={specialtyType}
            />
          )}
        </Modal.Actions>
      </Modal>
      <SpecialtyModal
        specialties={specialties}
        specialtyType={specialtyType}
        opened={specialtyModalOpened}
        onSubmit={data => onSpecialtyModalSubmit(data)}
        onClose={() => setSpecialtyModalOpened(false)}
      />
    </>
  );
}
