import React from 'react';
import Calendar from 'react-calendar';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { parseISO, isEqual, startOfDay } from 'date-fns';

export default function ScheduleCalendar({ onChange, defaultValue }) {
  const { scheduleAvailability } = useSelector(state => state.scheduling);

  const isDayDisabled = currentDate => {
    const parsedDate1 = startOfDay(currentDate.date);
    const slots = scheduleAvailability.filter(slot => {
      const parsedDate2 = startOfDay(parseISO(slot.start));
      return isEqual(parsedDate1, parsedDate2);
    });
    return !slots.length;
  };

  if (scheduleAvailability.length) {
    return (
      <Calendar
        minDetail="month"
        maxDetail="month"
        tileDisabled={isDayDisabled}
        onChange={onChange}
        calendarType="US"
        value={defaultValue}
      />
    );
  }

  return null;
}

ScheduleCalendar.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.objectOf(Date).isRequired,
};
