import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form as SForm, Accordion, Button, Icon } from 'semantic-ui-react';
import { Form } from '@rocketseat/unform';
import { MdSearch, MdReplay } from 'react-icons/md';

import DatePicker from '~/components/date-picker';
import Input from '~/components/input';
import Select from '~/components/select';
import { ButtonsContainer } from '../styles';

import { STATUS, PROFILE_TYPE } from '~/utils/constants';

const userStatusOptions = () =>
  Object.keys(STATUS).map(key => ({
    key,
    value: key,
    text: STATUS[key],
  }));

const userRoleOptions = () =>
  Object.keys(PROFILE_TYPE).map(key => ({
    key,
    value: key,
    text: PROFILE_TYPE[key],
  }));

export default function UserFilter({ onSubmit, initialData }) {
  const [opened, setOpened] = useState(false)
  const [name, setName] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const [status, setStatus] = useState('')
  const [roleId, setRoleId] = useState('')

  const refreshFilter = (e) => {
    e.preventDefault()
    setName('')
    setCreatedAt('')
    setStatus('')
    setRoleId('')
    onSubmit()
  }

  return (
    <Accordion>
      <Accordion.Title active={opened} onClick={() => setOpened(!opened)}>
        <Icon name="dropdown" />
        Filtro
      </Accordion.Title>
      <Accordion.Content active={opened}>
        <Form className="ui form" onSubmit={onSubmit} initialData={initialData}>
          <SForm.Group widths="2">
            <Input name="name" value={name} label="Nome" onChange={(e) => setName(e.target.value)} />
            <DatePicker
              name="created_at"
              value={createdAt}
              label="Criado em"
              onChange={v => setCreatedAt(v)}
            />
          </SForm.Group>
          <SForm.Group widths="2">
            <Select
              name="status"
              value={status}
              label="Status"
              options={userStatusOptions()}
              onChange={(e, { value }) => setStatus(value)}
            />
            <Select
              name="roleId"
              value={roleId}
              label="Tipo de Conta"
              options={userRoleOptions()}
              onChange={(e, { value }) => setRoleId(value)}
            />
          </SForm.Group>
          <ButtonsContainer>
            <Button type="submit" color="red" icon>
              <MdSearch />
              Buscar
            </Button>
            <Button
              type="button"
              default
              icon
              onClick={(e) => refreshFilter(e)}
            >
              <MdReplay />
              Voltar
            </Button>
          </ButtonsContainer>
        </Form>
      </Accordion.Content>
    </Accordion>
  );
}

UserFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object,
};

UserFilter.defaultProps = {
  initialData: {},
};
