import styled from 'styled-components';

import bgLogin from '../../assets/bg_welcome.png';

export const Background = styled.div`
  background-image: url(${bgLogin});
  background-size: cover;
  background-position: center center;
  align-items: stretch;
  display: flex;
  flex: 1;
  max-width: 50vw;
  min-height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  min-width: 50vw;
  padding: 30px 90px;
  flex-wrap: wrap;
  overflow: auto;
  align-items: center;
  justify-content: center;

  h2 {
    display: flex;
    justify-content: center;
  }
`;

export const Appointment = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  background: #f5f5f5;
  border: 1px solid #ececec;
  border-radius: 4px;
  padding: 20px 30px;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.15);
  margin: 10px 0;

  .appointment-line {
    display: flex;
  }
  strong {
    font-weight: bold;
    line-height: 170%;
    margin-right: 5px;
  }
  span {
    line-height: 170%;
  }
`;
