import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import UserList from './list';
import UserEdit from './edit';
import UserNew from './new';

export default function Users() {
  return (
    <Suspense fallback="loading">
      <Switch>
        <Route path="/users/:id/view" component={UserEdit} />
        <Route path="/users/new" component={UserNew} />
        <Route path="/users" exact component={UserList} />
      </Switch>
    </Suspense>
  );
}

//
