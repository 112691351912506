import api from './api';

export default {
  getScheduleAvailability: payload => {
    return api.get('/availabilities', { params: payload });
  },

  getAvailabilityConfig: () => {
    return api.get('/availabilities/me');
  },

  setAvailabilityConfig: config => {
    return api.put('/availabilities/me', config);
  },
};
