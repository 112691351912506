import styled from 'styled-components';

export const RowPayments = styled.div`
  display: flex;
  margin-bottom: 50px;
`;

export const ColumnPayments = styled.div`
  flex: 1;
  display: grid;
`;

export const ColumnInfo = styled.div`
  flex: 1;
  margin: 0 50px;
  display: grid;
`;

export const ColumnAccount = styled.div`
  flex: 2;
  display: grid !important;
`;

export const PaymentDetails = styled.dl`
margin-top: 20px;

& > dt,
& > dd {
  line-height: 170%;
  margin-bottom: 15px;
}

dt {
  font-weight: bold;
  float: left;
  flex-direction: row;
  clear: left;
}
dd {
  float: left;
  margin-left: 5px;
}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  height: 100vh;
  padding: 40px;

  h1 {
    font-size: 28px;
    margin-bottom: 20px;
  }

  h4 {
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 767px) {
    .ui.stackable.grid > .row > .column {
      padding: 0 !important;
    }
  }
`;