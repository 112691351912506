import React from 'react';
import { Textarea } from '@rocketseat/unform';
import { Form, TextArea } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default function CustomTextarea({ name, label, ...rest }) {
  function renderInput(props) {
    return <Textarea name={name} label={label} {...props} {...rest} />;
  }

  if (rest.type !== 'hidden') {
    return (
      <Form.Field {...(rest.widths && { widths: rest.widths })}>
        {renderInput({ ...TextArea.defaultProps, name })}
      </Form.Field>
    );
  }
  return renderInput();
}

CustomTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rest: PropTypes.arrayOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.func, PropTypes.number])
  ),
};

CustomTextarea.defaultProps = {
  label: null,
  rest: [],
};
