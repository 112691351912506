import React from 'react';

export const renderAppointmentStatus = (status, t) => {
  const statuses = {
    SCDL: (
      <span className="ui label scheduled">Agendado</span>
    ),
    PIRQ: (
      <span className="ui label scheduled">Necessita Prévia</span>
    ),
    AWPR: (
      <span className="ui label scheduled">Aguardando Prévia</span>
    ),
    PIFN: (
      <span className="ui label done">Prévia Concluída</span>
    ),
    WAIT: (
      <span className="ui label canceled">Aguardando Aprovação</span>
    ),
    APPR: (
      <span className="ui label scheduled">Aprovado pelo Paciente</span>
    ),
    FREE: (
      <span className='ui label scheduled'>Sem Cobrança</span>
    ),
    PROC: (
      <span className="ui label scheduled">Procedimento Realizado</span>
    ),
    RQRF: (
      <span className="ui label scheduled">Reembolso Solicitado</span>
    ),
    OKPA: (
      <span className="ui label done">Reembolso Pago</span>
    ),
    WTPA: (
      <span className="ui label canceled">Aguardando Pagamento</span>
    ),
    DONE: (
      <span className="ui label done">Finalizado</span>
    ),
    PNDC: (
      <span className="ui label canceled">Pendência</span>
    ),
    GLOS: (
      <span className="ui label scheduled">Glosa</span>
    ),
    OVRD: (
      <span className="ui label canceled">Inadimplente</span>
    ),
    CANC: (
      <span className="ui label canceled">Cancelado</span>
    ),
    //abaixo varáveis antigas
    AWTR: (
      <span className="ui label scheduled">Aguardando Repasse</span>
    ),
    SGPF: (
      <span className="ui label scheduled">Procedimento Realizado</span>
    ),
    PERF: (
      <span className="ui label scheduled">Procedimento realizado</span>
    ),
    DGSD: (
      <span className="ui label scheduled">Diagnosticados</span>
    ),
    PMMG: (
      <span className="ui label canceled">Falta Pagamento</span>
    ),
    RBMG: (
      <span className="ui label canceled">Falta Reembolso</span>
    ),
    //abaixo as variaveis, não mais inclusas no select status form
    SCRQ: (
      <span className="ui label scheduled">Agendamento Solicitado</span>
    ),
  };

  return statuses[status];
};
