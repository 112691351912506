import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Divider } from 'semantic-ui-react';
import { unionBy } from 'lodash';
import { Form } from '@rocketseat/unform';
import usersService from '~/services/users';
import requestHandler from '~/services/requestHandler';
import { setLoading } from '~/store/modules/global/actions';
import Select from '~/components/select';
import { PROFILE, SPECIALTY_TYPES } from '~/utils/constants';
export default function NewAppointment({ opened, onSubmit, onClose }) {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const [userList, setUserList] = useState([]);
  const [actual, setSpecialList] = useState([]);
  const [control, setControl] = useState(false);
  const [searchUserList, setSearchUserList] = useState(
    userList.map((x) => ({
      id: x.id,
      name: x.name,
      email: x.email,
    }))
  );
  const [specialtyGroup, setSpecialtyGroup] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  /* eslint-disable no-shadow */
  const handleSearchChange = (_e, { searchQuery }) =>
    setSearchQuery(searchQuery);
  const handleUserListChange = (_e, { value }) => {
    if (value.length <= 1) {
      setUserList(value);
    }
  };

  useEffect(() => {
    const updateSearchUserList = () => {
      if (searchQuery.length >= 3) {
        requestHandler(usersService.listAllUsers, {
          partialStr: searchQuery,
        })
          .then((result) =>
            setSearchUserList(
              unionBy(searchUserList, result.records, 'id').filter(
                (x) => x.roleId === PROFILE.PATIENT
              )
            )
          )
          .finally(() => dispatch(setLoading(false)));
      } else {
        setSearchUserList(
          searchUserList.filter((u) => userList.includes(u.id))
        );
        dispatch(setLoading(false));
      }
    };
    updateSearchUserList();
  }, [dispatch, searchQuery]);

  useEffect(() => {
    if (!control) {
      const getCurrentUser = async () => {
        await requestHandler(usersService.getUser, profile.id).then((res) => {
          setSpecialList(res.specialties);
          setControl(true);
        });
      };
      actual && getCurrentUser();
    }
  }, [actual, control, profile]);

  const userOptions = () => {
    return searchUserList.map((s) => ({
      key: s.id,
      value: s.id,
      text: `${s.name} (CPF: ${s.profile.cpf})`,
    }));
  };

  const specialtiesOpt = () => {
    return actual
      .filter((e) => e.type === specialtyGroup)
      .map((s) => ({
        key: s.id,
        value: s.id,
        text: s.name,
      }));
  };

  const specialtiesTypeOpt = () => {
    const tmp = actual.map(
      (s) => ({
        key: s.id,
        value: s.type,
        text: SPECIALTY_TYPES[s.type],
      }),
      'key'
    );

    let aux = tmp.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.value === value.value)
    );
    return aux;
  };

  return (
    <Modal open={opened} closeIcon onClose={onClose}>
      <Modal.Header>Nova consulta</Modal.Header>
      <Modal.Content>
        <p>
          Busque o paciente para adicionar à consulta. Busque por nome ou CPF.
        </p>
        <Form className="ui form" onSubmit={onSubmit}>
          <Select
            name="patients"
            label="Paciente"
            multiple
            value={userList}
            onChange={handleUserListChange}
            options={userOptions()}
            searchQuery={searchQuery}
            onSearchChange={handleSearchChange}
          />
          <Select
            name="specialtyGroup"
            label="Tipo de Atendimento"
            options={specialtiesTypeOpt()}
            onChange={(_, { value }) => setSpecialtyGroup(value)}
          />
          <Select
            disabled={!specialtyGroup}
            name="specialty"
            label="Procedimento"
            options={specialtiesOpt()}
          />
          <Divider />
          <Button type="button" onClick={() => onClose()}>
            Cancelar
          </Button>
          <Button type="submit" color="red">
            Confirmar
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
}

NewAppointment.propTypes = {
  opened: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  specialties: PropTypes.array.isRequired,
};
