import React from 'react';
import PropTypes from 'prop-types';
import { FaEdit } from 'react-icons/fa';
import { MdSave, MdBlock, MdClose } from 'react-icons/md';
import { Button } from 'semantic-ui-react';
import { ActionsContainer } from '../styles';
import ActivateUser from './ActivateUser';
import SelectSpecialties from './SelectSpecialties';

export default function UserActions({
  isEditing,
  isActive,
  setEditing,
  onInactivate,
  // onDelete,
  setPut,
  currentUser,
}) {
  return (
    <ActionsContainer>
      {isEditing ? (
        <>
          <Button icon color="red" type="submit">
            <MdSave /> Salvar
          </Button>
          <Button
            icon
            color="red"
            type="button"
            onClick={() => setEditing(false)}
          >
            <MdBlock /> Cancelar
          </Button>
        </>
      ) : (
          <Button icon color="red" type="button" onClick={() => {setEditing(true); setPut(true);}}>
            <FaEdit /> Editar
          </Button>
        )}
      {/* <Button icon color="red" type="button" onClick={() => onDelete()}>
        <MdDelete /> Apagar
      </Button> */}
      {isActive ? (
        <>
          <Button default icon type="button" onClick={() => onInactivate()}>
            <MdClose /> Inativar
          </Button>
          {currentUser.roleId === 4 || currentUser.roleId === 6 ?
            <SelectSpecialties currentUser={currentUser} />
            : null
          }
        </>
      ) : (
          <ActivateUser currentUser={currentUser} color="red" />
        )}
    </ActionsContainer>
  );
}

UserActions.propTypes = {
  setPut: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  setEditing: PropTypes.func.isRequired,
  onInactivate: PropTypes.func.isRequired,
  // onDelete: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};
