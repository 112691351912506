import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Wrapper, MainContent } from './styles';
import Header from './components/header';
import Aside from './components/aside';
import Breadcrumb from './components/breadcrumb';
import history from '~/services/history';

export default function DefaultLayout({ children }) {
  const { breadcrumb } = useSelector(state => state.global);
  const { profile } = useSelector(state => state.user);

  if (profile.roleId === 3) {
    history.push('/welcome');
  }

  return (
    <Wrapper>
      <Header />
      <Aside />
      <MainContent>
        {breadcrumb.type && Breadcrumb(breadcrumb.type, ...breadcrumb.params)}
        {children}
      </MainContent>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
