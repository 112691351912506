import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  height: 100vh;
  padding: 40px;

  .content .text p {
    margin: 0;
  }

  h1 {
    font-size: 28px;
    margin-bottom: 20px;
  }

  h4 {
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 767px) {
    .ui.stackable.grid > .row > .column {
      padding: 0 !important;
    }
  }
`;

export const ShadowBox = styled.div`
    -webkit-box-shadow: 0px 0px 11px -1px #c5c3c3;
    box-shadow: 0px 0px 11px -1px #c5c3c3;
    border-radius: 10px;
    padding: 20px;
`;

export const AppointmentData = styled.dl`

`;

export const BoxStatus = styled.div`
  margin:0;
`

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
`;

export const CommentActionContainer = styled.div`
  display: inline-flex;
  margin-left: 8px;
  position: absolute;
  top: 9px;
`
