import { takeLatest, put, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import requestHandler from '../../middlewares/requestHandler';
import { setLoading } from '../global/actions';
import {
  appointmentFailed,
  appointmentListLoaded,
  financialListLoaded,
  getAppointmentSuccess,
  getAppointmentRequest,
  performanceReportLoaded,
} from './actions';
import appointmentService from '../../../services/appointments';

function* listAppointments({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      appointmentService.list,
      payload
    );

    yield put(appointmentListLoaded(response.records, response.pagination));
  } catch (err) {
    toast.error(err);
    yield put(appointmentFailed());
  }
  yield put(setLoading(false));
}

function* financialList({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      appointmentService.financial,
      payload
    );

    yield put(financialListLoaded(response.records, response.pagination));
  } catch (err) {
    toast.error(err);
    yield put(appointmentFailed());
  }
  yield put(setLoading(false));
}

function* getPerformanceReportList({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      appointmentService.statistic,
      payload
    );

    yield put(performanceReportLoaded(response.records));
  } catch (err) {
    toast.error(err);
    yield put(appointmentFailed());
  }
  yield put(setLoading(false));
}

function* getAppointment({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      appointmentService.get,
      payload
    );
    yield put(getAppointmentSuccess(response));
  } catch (err) {
    toast.error(err);
    yield put(appointmentFailed());
  }
  yield put(setLoading(false));
}

//alter providers
 function* updateProviders({payload}) {

  try {
    yield put(setLoading(true));
    yield call(
      requestHandler,
      appointmentService.update,
      payload
    )
    toast.success('Usuário(s) alterado(s) com sucesso.');
    yield put(getAppointmentRequest(payload.id));
  } catch (error) {
    toast.error('Não foi possível atualizar a lista de usuários.');
  }
  yield put(setLoading(false));
}

//alter specialities
function* updateSpecialities({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(
      requestHandler,
      appointmentService.update,
      payload
    );
    toast.success('Especialidade do procedimente atualizada.');
    yield put(getAppointmentRequest(payload.id));
  } catch (error) {
    toast.error('Não foi possível atualizar o procedimento, tente novamente.');
  }
  yield put(setLoading(false));
}

//this functions
function* updateStatus({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(
      requestHandler,
      appointmentService.update,
      payload
    );
    toast.success('Status atualizado com sucesso.');
    yield put(getAppointmentRequest(payload.id));
  } catch (error) {
    toast.error('Não foi possível atualizar o status');
  }
  yield put(setLoading(false));
}

function* updateFinancialAmount({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      appointmentService.updateFinance,
      payload
    );
    if (response) {
      toast.success('Montante financeiro atualizado com sucesso.');
      yield put(getAppointmentRequest(payload.id));
    }
  } catch (error) {
    toast.error('Não foi possível atualizar o valor financeiro');
  }
  yield put(setLoading(false));
}

function* updateStatusWithBoleto({ payload }) {
  try {
    const { amount, ...rest } = payload;
    yield put(setLoading(true));
    const response = yield call(requestHandler, appointmentService.addBoleto, {
      amount,
      id: rest.id,
    });
    if (response) {
      if (response.error) {
        if (response.error.msg) {
          toast.error(response.error.msg);
        } else {
          toast.error('Error');
        }
      } else {
        toast.success('O boleto foi gerado corretamente');
        yield call(updateStatus, { payload: rest });
      }
    }
  } catch (error) {
    toast.error('Error');
  }
  yield put(setLoading(false));
}

function* addComment({ payload }) {
  yield put(setLoading(true));
  const { appointment_id } = payload;
  try {
    const response = yield call(
      requestHandler,
      appointmentService.addComment,
      payload
    );
    if (response) {
      toast.success('Comentário adicionado com sucesso.');
      setTimeout(() => {
        window.location.href = `/appointments/${appointment_id}/view`;
      }, 300);
    }
  } catch (error) {
    toast.error('Não foi possível adicionar o comentário');
  }
  yield put(setLoading(false));
}

function* destroyComment({ payload }) {
  yield put(setLoading(true));
  const { appointment_id } = payload;
  try {
    const response = yield call(
      requestHandler,
      appointmentService.destroyComment,
      payload
    );
    if (response) {
      toast.success('comentário removido com sucesso.');
      setTimeout(() => {
        window.location.href = `/appointments/${appointment_id}/view`;
      }, 300);
    }
  } catch (error) {
    toast.error('Não foi possível remover o comentário');
  }
  yield put(setLoading(false));
}

function* destroy({ payload }) {
  yield put(setLoading(true));
  try {
    const response = yield call(
      requestHandler,
      appointmentService.destroy,
      payload
    );
    if (response) {
      toast.success('Agendamento removido com sucesso.');
      setTimeout(() => {
        window.location.href = `/appointments`;
      }, 300);
    }
  } catch (error) {
    toast.error('Não foi possível remover o agendamento');
  }
  yield put(setLoading(false));
}

export default all([
  takeLatest('@appointments/LIST_REQUEST', listAppointments),
  takeLatest('@appointments/FINANCIAL_REQUEST', financialList),
  takeLatest('@appointments/REPORT_REQUEST', getPerformanceReportList),
  takeLatest('@appointments/UPDATE_PROVIDERS', updateProviders),
  takeLatest('@appointments/UPDATE_CURRENT_APPOINTMENT', updateSpecialities),
  takeLatest('@appointments/GET_REQUEST', getAppointment),
  takeLatest('@appointments/UPDATE_STATUS', updateStatus),
  takeLatest('@appointments/UPDATE_FINANCIALAMOUNT', updateFinancialAmount),
  takeLatest('@appointments/UPDATE_STATUS_WITH_BOLETO', updateStatusWithBoleto),
  takeLatest('@appointments/ADD_COMMENT', addComment),
  takeLatest('@appointments/DESTROY_COMMENT', destroyComment),
  takeLatest('@appointments/DESTROY_APPOINTMENT', destroy),
]);
