import api from './api';

const load = () => {
  return api.get('/appointments/current');
};
const list = (payload) => {
  return api.get('/appointments', {
    params: payload,
  });
};
const financial = (payload) => {
  return api.get('/appointments/financial', {
    params: payload,
  });
};
const statistic = (payload) => {
  return api.post('/appointments/statistics', payload);
};

const getUpDate = (id) => {
  return api.get(`appointments/uptodate/${id}`);
}

const get = (id) => {
  return api.get(`/appointments/${id}`);
};

const create = (payload) => {
  return api.post('/appointments', {
    ...payload,
  });
};

const update = (payload) => {
  return api.put(`/appointments/${payload.id}`, payload);
};

const updateFinance = (payload) => {
  return api.post(`/appointments/${payload.id}/finance`, payload)
}
const addComment = (payload) => {
  const { appointment_id, ...data } = payload;
  return api.post(`/appointments/${appointment_id}/comments`, data);
};

const destroyComment = (payload) => {
  const { appointment_id, commentId } = payload;
  return api.delete(`/appointments/${appointment_id}/comments/${commentId}`);
}

const addBoleto = (payload) => {
  const { id, ...data } = payload;
  return api.post(`/appointments/${id}/boletos`, data);
};

const destroy = (id) => {
  return api.delete(`/appointments/${id}`);
};

export default {
  load,
  list,
  financial,
  statistic,
  get,
  getUpDate,
  update,
  create,
  updateFinance,
  addComment,
  destroyComment,
  destroy,
  addBoleto,
};
