import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@rocketseat/unform';
import { Button } from 'semantic-ui-react';
import * as Yup from 'yup';

import { updatePasswdStatus } from '../../../store/modules/auth/actions';
import logo from '../../../assets/homeup_logo.svg';
import Input from '../../../components/input';

const schema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Senha deve ter pelo menos 8 caracteres')
    .required('Senha é obrigatória'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Senhas não conferem')
    .required('Confirmar senha é obrigatório'),
});

export default function ResetPasswordUpdate(props) {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  const handleSubmit = ({ password, confirmPassword }) => {
    const { id } = props.match.params;
    dispatch(
      updatePasswdStatus({ userId:id, password, confirmPassword })
    );
  };

  return (
    <>
      <div className="container-header">
        <h1>
          <img src={logo} alt="Instituto Vertus de Mediação" />
        </h1>
        <h2>Preencha os campos abaixo para cadastrar sua nova senha</h2>
      </div>
      <Form className="ui form" schema={schema} onSubmit={handleSubmit}>
        <Input
          label="Senha"
          name="password"
          type="password"
          placeholder="Mínimo 8 caracteres"
        />
        <Input
          label="Confirmar Senha"
          name="confirmPassword"
          type="password"
          placeholder="Repita a senha"
        />
        <Button type="submit" color="red">
          {loading ? 'Carregando...' : 'Confirmar'}
        </Button>
        <Link to="/">voltar ao login</Link>
      </Form>
    </>
  );
}

ResetPasswordUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
};
