import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import requestHandler from '../../middlewares/requestHandler';
import { setLoading } from '../global/actions';
import {
  usersFailed,
  userListRequest,
  userListSuccess,
  getUserRequest,
  getUserSuccess,
  loadUserParticipations,
  userCreated,
} from './actions';
import usersService from '~/services/users';
import history from '~/services/history';

function* listUsers({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      usersService.listUsers,
      payload
    );
    yield put(
      userListSuccess(
        get(response, 'records', []),
        get(response, 'pagination', {})
      )
    );
  } catch (err) {
    toast.error(err);
    yield put(usersFailed());
  }
  yield put(setLoading(false));
}

function* getUser({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(requestHandler, usersService.getUser, payload);
    yield put(getUserSuccess(response));
    yield put(loadUserParticipations({ userId: response.id }));
  } catch (err) {
    toast.error(err);
    yield put(usersFailed());
  }
  yield put(setLoading(false));
}

function* activateUser({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      usersService.toggleStatus,
      payload
    );
    yield put(getUserRequest(response.id));
    toast.success('Usuário ativado com sucesso.');
  } catch (err) {
    toast.error('Ocorreu um erro ao tentar ativar o usuário');
    yield put(usersFailed());
  }
  yield put(setLoading(false));
}

function* inactivateUser({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      usersService.toggleStatus,
      payload
    );

    yield put(getUserRequest(response.id));
    toast.success('Usuário desativado com sucesso.');
  } catch (err) {
    toast.error('Ocorreu um erro ao tentar desativar o usuário');
  }
  yield put(setLoading(false));
}

function* reloadList() {
  const pagination = yield select((state) => state.users.pagination);
  yield put(userListRequest(pagination));
}

function* bulkActivate({ payload }) {
  yield put(setLoading(true));
  try {
    yield all(
      payload.map((user) => {
        return call(requestHandler, usersService.toggleStatus, user);
      })
    );

    yield call(reloadList);
    toast.success('Usuários ativados com sucesso');
  } catch (error) {
    toast.error(error.toString());
    yield put(usersFailed());
  } finally {
    yield put(setLoading(false));
  }
}

function* bulkInactivate({ payload }) {
  yield put(setLoading(true));
  try {
    yield all(
      payload.map((user) => {
        return call(requestHandler, usersService.toggleStatus, user);
      })
    );

    yield call(reloadList);
    toast.success('Usuários inativados com sucesso');
  } catch (error) {
    toast.error(error.toString());
    yield put(usersFailed());
  } finally {
    yield put(setLoading(false));
  }
}

function* destroy({ payload }) {
  yield put(setLoading(true));
  try {
    yield call(requestHandler, usersService.destroy, payload);

    yield call(reloadList);
    history.push('/users');
    toast.success('Usuário removido com sucesso');
  } catch (error) {
    toast.error(error.toString());
    yield put(usersFailed());
  } finally {
    yield put(setLoading(false));
  }
  yield put(setLoading(false));
}

function* bulkDestroy({ payload }) {
  yield put(setLoading(true));
  try {
    yield all(
      payload.map((user) => {
        return call(requestHandler, usersService.destroy, user);
      })
    );

    yield call(reloadList);
    toast.success('Usuários removidos com sucesso');
  } catch (error) {
    toast.error(error.toString());
    yield put(usersFailed());
  } finally {
    yield put(setLoading(false));
  }
}

function* uploadAvatar({ payload }) {
  yield put(setLoading(true));
  const response = yield call(
    requestHandler,
    usersService.uploadAvatar,
    payload
  );

  if (!response) {
    toast.error('Não foi possível atualizar o avatar');
  } else {
    toast.success('Avatar atualizado com sucesso');
    yield put(getUserRequest(payload.userId));
  }
  yield put(setLoading(false));
}

function* create({ payload }) {
  yield put(setLoading(true));
  try {
    const response = yield call(
      requestHandler,
      usersService.createUser,
      payload
    );
    if (response) {
      toast.success('Usuário criado com sucesso');
      history.push(`/users`);
      yield put(userCreated());
    } else {
      toast.error('Não foi possível criar o usuário');
      yield put(usersFailed());
    }
  } catch (error) {
    toast.error('Não foi possível criar o usuário');
    yield put(usersFailed());
  } finally {
    yield put(setLoading(false));
  }
}

function* update({ payload }) {
  yield put(setLoading(true));
  try {
    const response = yield call(
      requestHandler,
      usersService.updateUser,
      payload,
      true
    );
    if (response) {
      toast.success('Usuário atualizado com sucesso');
      history.push(`/users/${response.id}/view`);
      yield put(userCreated());
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } else {
      toast.error('Não foi possível atualizar o usuário');
      yield put(usersFailed());
    }
  } catch (error) {
    toast.error('Não foi possível atualizar o usuário');
    yield put(usersFailed());
  } finally {
    yield put(setLoading(false));
  }
}

function * filed({payload}){
  yield put(setLoading(true));
  toast.error('Data inválida');
  yield put(setLoading(false));

}

function* upload({ payload }) {
  yield put(setLoading(true));

  const response = yield call(requestHandler, usersService.uploadFile, payload);
  if (response) {
    toast.success('Arquivo anexado com sucesso');

    setTimeout(() => {
      window.location.reload();
    }, 200);
  } else {
    toast.error('Não foi possível anexar o arquivo');
  }

  yield put(setLoading(false));
}

export default all([
  takeLatest('@users/UPLOAD_AVATAR', uploadAvatar),
  takeLatest('@users/LIST_REQUEST', listUsers),
  takeLatest('@users/GET_REQUEST', getUser),
  takeLatest('@users/UPDATE_REQUEST', update),
  takeLatest('@users/ACTIVATE_REQUEST', activateUser),
  takeLatest('@users/INACTIVATE_REQUEST', inactivateUser),
  takeLatest('@users/DESTROY_REQUEST', destroy),
  takeLatest('@users/BULK_ACTIVATE_REQUEST', bulkActivate),
  takeLatest('@users/BULK_INACTIVATE_REQUEST', bulkInactivate),
  takeLatest('@users/BULK_DESTROY_REQUEST', bulkDestroy),
  takeLatest('@users/CREATE_USER', create),
  takeLatest('@users/DATA_FILED', filed),
  takeLatest('@users/UPLOAD', upload)
]);
