import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PROFILE } from '~/utils/constants';
import {
  MdHome,
  MdPersonOutline,
  MdLibraryBooks,
  MdHistory,
  MdAssignment,
} from 'react-icons/md';
import { FaMedkit, FaRegMoneyBillAlt } from 'react-icons/fa';
import { MdOutlinePayments } from 'react-icons/md';

import homeUpLogo from '~/assets/homeup_horizontal_logo.svg';
import { Aside, Logo, Menu } from './styles';

export default function () {
  const { profile } = useSelector((state) => state.user);
  const isAdmin = profile.roleId === PROFILE.ADMIN;
  const isFinancialAdmin = profile.roleId === PROFILE.FINADMIN;
  const isProvider = [PROFILE.PROVIDER, PROFILE.DOCTOR].includes(
    profile.roleId
  );
  const isDoctor = PROFILE.DOCTOR === profile.roleId;
  const isAssociateDoctor = PROFILE.ASSDOCTOR === profile.roleId;
  const isAttendance = PROFILE.ATTENDANCE === profile.roleId;

  return (
    <Aside>
      <Logo>
        <img src={homeUpLogo} alt="HomeUp" />
      </Logo>
      <Menu>
        {!isAttendance && <li>
          <Link to="/dashboard">
            <MdHome />
            Home
          </Link>
        </li>}
        {(isAdmin || isProvider || isAssociateDoctor || isAttendance) && (
          <li>
            <Link to="/users">
              <MdPersonOutline />
              Cadastros
            </Link>
          </li>
        )}
        {(isAdmin || isAttendance) && (
          <li>
            <Link to="/specialties">
              <FaMedkit />
              Especialidades
            </Link>
          </li>
        )}
        {(isAdmin || isProvider || isAssociateDoctor || isAttendance) && (
          <>
            <li>
              <Link to="/appointments">
                <MdLibraryBooks />
                Procedimentos
              </Link>
            </li>
            {/* <li>
              <Link to="/calendar">
                <FaRegCalendarAlt />
                Calendário
              </Link>
            </li> */}
          </>
        )}
        {(isAdmin || isFinancialAdmin || isDoctor) && (
          <li>
            <Link to="/financial">
              <FaRegMoneyBillAlt />
              Financeiro
            </Link>
          </li>
        )}
        {!isAttendance && <li>
          <Link to="/report">
            <MdAssignment />
            Relatório
          </Link>
        </li>}
        {isAssociateDoctor && (
          <>
            <li>
              <Link to="/payments">
                <MdOutlinePayments />
                Pagamentos
              </Link>
            </li>
            <li>
              <Link to="/auditor">
                <MdHistory />
                Auditoria
              </Link>
            </li>
          </>
        )}
      </Menu>
    </Aside>
  );
}
