import { takeLatest, put, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import requestHandler from '../../middlewares/requestHandler';
import { setLoading } from '../global/actions';
import {
  paymentFailed,
  paymentListLoaded,
  paymentInfoLoaded,
  paymentGetLoaded,
} from './actions';
import paymentService from '../../../services/payments';

function* showPayment({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(requestHandler, paymentService.show, payload);
    yield put(paymentGetLoaded(response));
  } catch (err) {
    toast.error(err);
    yield put(paymentFailed());
  }
  yield put(setLoading(false));
}

function* createPayment({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(requestHandler, paymentService.store, payload);
    yield put(paymentGetLoaded(response));
  } catch (err) {
    toast.error(err);
    yield put(paymentFailed());
  }
  yield put(setLoading(false));
}

function* loadPaymentsInfo({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      paymentService.load,
      payload,
      true
    );
    yield put(paymentInfoLoaded(response));
  } catch (err) {
    toast.error(err);
    yield put(paymentFailed());
  }
  yield put(setLoading(false));
}

function* listPayments({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      paymentService.list,
      payload,
      true
    );

    yield put(paymentListLoaded(response.records, response.pagination));
  } catch (err) {
    toast.error(err);
    yield put(paymentFailed());
  }
  yield put(setLoading(false));
}

export default all([
  takeLatest('@payments/LIST_REQUEST', listPayments),
  takeLatest('@payments/INFO_REQUEST', loadPaymentsInfo),
  takeLatest('@payments/POST_REQUEST', createPayment),
  takeLatest('@payments/GET_REQUEST', showPayment),
]);
