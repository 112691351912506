import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './list';
import View from './view';

export default function Payments() {
  return (
    <Suspense fallback="loading">
      <Switch>
        <Route path="/payments" exact component={List} />
        <Route path="/payments/:id" exact component={View} />
      </Switch>
    </Suspense>
  );
}
