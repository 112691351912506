import React, { useState } from 'react';
import { Modal, Divider } from 'semantic-ui-react';

import {
  Container,
  RowAccount,
  TitleAccount,
  OptionAccount,
  ButtonAccount,
  ModalContainer,
  ModalRow,
  ModalClientInfo,
  TitleModal,
  TitleInModal,
  InformationModal,
  FormModal,
  RowForm,
  ColumnForm,
  ColumnFormButton,
  LabelForm,
  InputModal,
  RowInput,
  InputM1,
  InputM2,
  ButtonFormSave,
  ButtonFormBack,
} from './styles';

import { FaSave } from 'react-icons/fa';
import { RiCloseFill } from 'react-icons/ri';
import {
  codeBankMask,
  agencyMask,
  checkingAccountMask,
  digitMask,
} from '../../utils/inputMask';
import { checkingAccountPut } from '~/store/modules/checkingAccount/actions';
import { useDispatch } from 'react-redux';
import formatCpf from '../../utils/formatCpf';

export default function CardAccount({
  id,
  name,
  cpf,
  bank,
  agency,
  checkingAccount,
  digit,
  createdBy,
  userId,
}) {
  const [open, setOpen] = useState(false);
  const [banks, setBanks] = useState('');
  const [agencys, setAgencys] = useState('');
  const [checkingAccounts, setCheckingAccounts] = useState('');
  const [digits, setDigits] = useState('');

  const dispatch = useDispatch();

  function onSubmit() {
    dispatch(
      checkingAccountPut({
        user_id: userId,
        accountData: {
          bank_code: banks,
          ispb: '42432423',
          branch_code: agencys,
          account_number: checkingAccounts,
          digits,
          created_by: createdBy || userId,
        },
      })
    );
  }

  function openModal() {
    if (!open) {
      setBanks(bank);
      setAgencys(agency);
      setCheckingAccounts(checkingAccount);
      setDigits(digit);
    }
    setOpen(!open);
  }

  return (
    <>
      {open && (
        <Modal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          <Modal.Content>
            <ModalContainer>
              <TitleAccount>Dados da Conta Corrente</TitleAccount>
              <ModalRow>
                <ModalClientInfo>
                  <TitleModal>
                    Nome: <TitleInModal>{name}</TitleInModal>
                  </TitleModal>
                  <TitleModal>
                    CPF:{' '}
                    <TitleInModal>
                      {`${formatCpf(cpf)}`}
                    </TitleInModal>
                  </TitleModal>
                </ModalClientInfo>
              </ModalRow>
              <InformationModal>
                A conta cadastrada deve pertencer a pessoa com os dados da conta
                acima
              </InformationModal>
              <Divider />
              <FormModal>
                <RowForm>
                  <ColumnForm>
                    <LabelForm>Banco (Cód)</LabelForm>
                    <InputModal
                      type="text"
                      value={banks}
                      onChange={(event) =>
                        setBanks(codeBankMask(event.target.value))
                      }
                    />
                  </ColumnForm>
                  <ColumnForm>
                    <LabelForm>Agência (Sem dígitos)</LabelForm>
                    <InputModal
                      type="text"
                      value={agencys}
                      onChange={(event) =>
                        setAgencys(agencyMask(event.target.value))
                      }
                    />
                  </ColumnForm>
                  <ColumnForm>
                    <LabelForm>Conta corrente</LabelForm>
                    <RowInput>
                      <InputM1
                        type="text"
                        value={checkingAccounts}
                        onChange={(event) =>
                          setCheckingAccounts(
                            checkingAccountMask(event.target.value)
                          )
                        }
                      />
                      <InputM2
                        type="text"
                        value={digits}
                        onChange={(event) =>
                          setDigits(digitMask(event.target.value))
                        }
                      />
                    </RowInput>
                  </ColumnForm>
                </RowForm>
                <RowForm>
                  <ColumnFormButton>
                    <ButtonFormSave onClick={onSubmit}>
                      <FaSave />
                      Salvar
                    </ButtonFormSave>
                    <ButtonFormBack onClick={openModal}>
                      <RiCloseFill />
                      Cancelar
                    </ButtonFormBack>
                  </ColumnFormButton>
                </RowForm>
              </FormModal>
            </ModalContainer>
          </Modal.Content>
        </Modal>
      )}
      <Container>
        <RowAccount>
          <TitleAccount>Conta Cadastro</TitleAccount>
          <ButtonAccount onClick={openModal}>
            {id ? 'Alterar conta' : 'Cadastrar conta'}
          </ButtonAccount>
        </RowAccount>
        {!id ? (
          <OptionAccount>Nenhuma conta cadastrada!</OptionAccount>
        ) : (
          <>
            <OptionAccount>Banco: {bank}</OptionAccount>
            <OptionAccount>Agência: {agency}</OptionAccount>
            <OptionAccount>
              Conta corrente: {checkingAccount}-{digit}
            </OptionAccount>
          </>
        )}
      </Container>
    </>
  );
}
