import styled from 'styled-components';

import bgLogin from '../../assets/bg_login.png';

export const Background = styled.div`
  background-image: url(${bgLogin});
  background-size: cover;
  background-position: center center;
  flex: 1;
  height: 100vh;

  @media (max-width: 460px) {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  overflow: auto;
  padding: 90px;
  align-items: center;
  flex-direction: column;
  min-width: 50vw;

  @media (max-width: 460px) {
    padding: 40px 20px;
    min-width: 100vw;
  }

  .space-between-h {
    margin-bottom: 10px;
  }

  
  img{
    width: 320px !important;
  }
`;
