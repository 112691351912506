import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown } from 'semantic-ui-react';
import { useField } from '@rocketseat/unform';

export default function SearchableSelect({
  name,
  label,
  placeholder,
  options,
  ...rest
}) {
  const { fieldName, registerField, error, defaultValue } = useField(name);

  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current,
        path: 'state.value',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName]);

  const defaultValueProp = !rest.value ? { defaultValue } : {};

  return (
    <Form.Field {...(rest.widths && { className: `${rest.widths} wide` })}>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <Dropdown
        id={fieldName}
        ref={ref}
        {...rest}
        placeholder={placeholder}
        search
        selection
        options={options}
        {...defaultValueProp}
      />
      {error && <span>{error}</span>}
    </Form.Field>
  );
}

SearchableSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
};

SearchableSelect.defaultProps = {
  label: null,
  placeholder: null,
};
