import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { CommentActionContainer } from '../styles'
import PropTypes from 'prop-types';

export default function Action({item, action}) {
    return (
        <CommentActionContainer>
            <Dropdown icon='ellipsis horizontal'>
                <Dropdown.Menu>
                    <Dropdown.Item
                        icon='edit'
                        text='Editar Comentário'
                        onClick={() => {action(item, true)}}
                    />
                    <Dropdown.Item
                        icon='trash'
                        text='Apagar Comentário'
                        onClick={() => {action(item, false)}}
                    />
                </Dropdown.Menu>
            </Dropdown>
        </CommentActionContainer>
    )
}

Action.propTypes = {
    item: PropTypes.object,
    action: PropTypes.func
}