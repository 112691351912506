export const uploadFile = ({ id, files, archiveType, emissionDate, fiscalDocType }) => ({
  type: '@files/UPLOAD',
  payload: {
    id,
    files,
    archiveType,
    emissionDate,
    fiscalDocType
  },
});

export const fileFailed = () => ({
  type: '@files/FAILED',
});
