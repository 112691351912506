import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, parseISO, startOfDay, format } from 'date-fns';
import { Placeholder } from 'semantic-ui-react';

import Calendar from '~/components/calendar';
import {
  scheduleAvailabilityRequest,
  scheduleAvailabilityAndResources,
} from '~/store/modules/scheduling/actions';
import {
  Container,
  CalendarContainer,
  TimeSlotList,
  TimeSlotButton,
} from './styles';

export default function Scheduler({
  selectedSlot,
  onSlotSelect,
  providerId,
  specialtyId,
}) {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { scheduleAvailability, loading } = useSelector(
    (state) => state.scheduling
  );

  const filterTimeSlots = (date) => {
    const slots = scheduleAvailability.filter((slot) => {
      const parsedDate = startOfDay(parseISO(slot.start));
      return isEqual(startOfDay(date), parsedDate);
    });

    return slots.map((slot) => (
      <TimeSlotButton
        key={slot.start}
        {...(slot.start === get(selectedSlot, 'start')
          ? { selected: true }
          : {})}
        onClick={() => onSlotSelect(slot)}
      >
        {format(parseISO(slot.start), 'HH:mm')}
      </TimeSlotButton>
    ));
  };

  useEffect(() => {
    if (!providerId) {
      dispatch(scheduleAvailabilityRequest({ specialtyId }));
      dispatch(scheduleAvailabilityAndResources({ specialtyId }));
    }
    if (providerId) {
      dispatch(scheduleAvailabilityRequest({ specialtyId, providerId }));
      dispatch(scheduleAvailabilityAndResources({ specialtyId, providerId }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (scheduleAvailability[0] && scheduleAvailability[0].start) {
      setSelectedDate(parseISO(scheduleAvailability[0].start));
    }
  }, [scheduleAvailability]);

  const onDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Container>
      <CalendarContainer>
        {loading ? (
          <Placeholder>
            <Placeholder.Image rectangular />
          </Placeholder>
        ) : (
          <Calendar defaultValue={selectedDate} onChange={onDateChange} />
        )}
      </CalendarContainer>

      {loading ? (
        <Placeholder fluid>
          <Placeholder.Paragraph>
            {[...Array(15)].map((v) => (
              <Placeholder.Line key={v} />
            ))}
          </Placeholder.Paragraph>
        </Placeholder>
      ) : (
        <TimeSlotList>
          {!!scheduleAvailability.length && filterTimeSlots(selectedDate)}
        </TimeSlotList>
      )}
    </Container>
  );
}

Scheduler.defaultProps = {
  providerId: null,
  specialtyId: null,
  selectedSlot: null,
};

Scheduler.propTypes = {
  onSlotSelect: PropTypes.func.isRequired,
  selectedSlot: PropTypes.object,
  providerId: PropTypes.any,
  specialtyId: PropTypes.any,
};
