import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  height: 100vh;
  padding: 40px;

  h1 {
    font-size: 28px;
    margin-bottom: 20px;
  }

  h4 {
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 767px) {
    .ui.stackable.grid > .row > .column {
      padding: 0 !important;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
`;
