import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@rocketseat/unform';
import { Button } from 'semantic-ui-react';
import { FaSave } from 'react-icons/fa';
import UserForm from './components/form';
import { createUser, dataFiled } from '~/store/modules/users/actions';
import { setBreadcrumb } from '~/store/modules/global/actions';
import schema from './components/validation';
import { ActionsContainer } from './styles';
import { getYear } from 'date-fns';

export default function NewUser() {
  const dispatch = useDispatch();
  const [roleId, setRoleId] = useState(null);
  const { profile } = useSelector(state => state.user)

  useEffect(() => {
    dispatch(setBreadcrumb('USER_NEW'));
  }, [dispatch]);

  const handleSubmit = data => {
    data['created_by'] = profile.id;
    const aux = data.profile.birthday;
    const now = getYear(Date.now());
    if((aux).getTime() === isNaN || getYear(aux) > now){
        dispatch(dataFiled(aux));
      }
    else {
      dispatch(createUser(data));
    }
  };

  return (
    <div className="content">
      <h2>Novo usuário</h2>
      <Form className="ui form" onSubmit={handleSubmit} schema={schema}>
        <ActionsContainer>
          <Button icon type="submit" color="red">
            <FaSave />
            Salvar
          </Button>
        </ActionsContainer>
        <UserForm roleId={roleId} setRoleId={setRoleId} />
      </Form>
    </div>
  );
}
