import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal, Button, Divider } from 'semantic-ui-react';
import { Form } from '@rocketseat/unform';
import { MdCheck } from 'react-icons/md';
import { get } from 'lodash';
import SelectionModal from '~/components/select_modal';
import { activateUserRequest } from '~/store/modules/users/actions';
import { setLoading } from '~/store/modules/global/actions';
import requestHandler from '~/services/requestHandler';
import specialtiesService from '~/services/specialties';
import Select from '~/components/select';
import { useSelector } from 'react-redux';
import { updateProfile } from '~/store/modules/user/actions';
import { SPECIALTY_TYPES } from '~/utils/constants';

export default function ActivateUser({ color, currentUser }) {
  const [opened, setOpened] = useState(false);
  const [specialtyList, setSpecialtyList] = useState([]);
  const [finale, setFinal] = useState([]);
  const dispatch = useDispatch();
  const {profile} = useSelector(state => state.user);
  const lexval = useSelector((state) => state.global.lexval);

  useEffect(() => {
    let list
    if(lexval){
      // eslint-disable-next-line array-callback-return
      list = specialtyList?.map((item) => {
        if(lexval.includes(item.type)) return item;
      });
      list = list.filter(function(e){return e});
    }
    setFinal(list);
  }, [lexval, specialtyList]);

  const specialtyOptionsDefault = () => {
    const options = Object.keys(SPECIALTY_TYPES)
    .map(s => {
      return {
        key: s,
        value: s,
        text: SPECIALTY_TYPES[s],
      }
    });
    return options
  }

  const onClick = () => {
    if (
      [6, 4].includes(get(currentUser, 'roleId')) &&
      !get(currentUser, 'specialty_id')
    ) {
      dispatch(setLoading(true));
      requestHandler(specialtiesService.listAll)
        .then(result => setSpecialtyList(result))
        .finally(() => dispatch(setLoading(false)));
      setOpened(true);
    } else {
      dispatch(activateUserRequest(currentUser));
    }
  };
  const specialtyOptions = () => {
    let option = finale.filter(s => s.type).map(s => ({
      key: s.id,
      value: s.id,
      text: s.name,
    }));
    return option
  }

  const onSubmit = ({ specialties }) => {
    const result = {...currentUser, specialties: specialties}
       // eslint-disable-next-line no-unused-vars
    let mylist = specialtyList.map((item) => {
      for(let i = 0; i <= specialties.length; i++){
        if(item.id === specialties[i]) return item;
      }
      return;
    });
    mylist = mylist.filter(function(e){return e});
    let putProfile = {...currentUser, specialties:mylist}

    if(profile.id === currentUser.id) {
      dispatch(updateProfile(putProfile));
    }
    setOpened(false);
    dispatch(activateUserRequest(result));
  };

  return (
    <>
      <Button type="button" color={color} icon onClick={onClick}>
        <MdCheck />
        Ativar
      </Button>
      <Modal size="tiny" open={opened} onClose={() => setOpened(false)}>
        <Modal.Header>Ativar usuário</Modal.Header>
        <Modal.Content>
          <p>
            Antes de ativar o usuário, defina sua(s) especialidade(s):
          </p>
          <Form className="ui form" onSubmit={onSubmit} >
            <SelectionModal
              name="specialties"
              label="Categorias"
              multiple
              options={specialtyOptionsDefault()}
            />
            <Divider />
            <Select
              name="specialties"
              label="Procedimentos"
              multiple
              options={specialtyOptions()}
            />
            <Divider />
            <Button type="button" onClick={() => setOpened(false)}>
              Cancelar
            </Button>
            <Button type="submit" color="red">
              Confirmar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
}

ActivateUser.propTypes = {
  color: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
};

ActivateUser.defaultProps = {
  color: '',
};
