import styled from 'styled-components';


export const Container = styled.div`
  border-radius: 5px;
  border: solid 1px #c5c5c3;
  padding: 20px;
  box-shadow: 10px 10px 10px -6px #c5c5c3;
`;

export const TitleBalance = styled.h2`
  text-align: center;
  margin: 0;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 500;
`

export const DescriptionBalance = styled.h1`
  text-align: center;
  margin: 0;
  font-size: 35px !important;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 700;
`