import React, { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';

import { Container, PaymentDetails } from './styles';
import { setBreadcrumb } from '~/store/modules/global/actions';
import { useDispatch, useSelector } from 'react-redux';
import { paymentGetRequest } from '~/store/modules/payments/actions';
import { formatCurrency } from './utils/formatCurrency';
import formatCpf from './utils/formatCpf';
import { format, parseISO } from 'date-fns';
import Status from './components/Status';

export default function View(props) {
  const { currentPayment } = useSelector((state) => state.payments);
  const currentUser = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBreadcrumb('PAYMENTS', { id: `${props.match.params.id}` }));
  }, [dispatch, props.match.params]);
  useEffect(() => {
    dispatch(paymentGetRequest({ paymentId: props.match.params.id }));
  }, [dispatch, props.match.params.id]);

  return (
    currentPayment &&
    currentPayment.id && (
      <Container className="content">
        <h1>Pagamento # ID {currentPayment.id}</h1>
        <Grid columns="1">
          <Grid.Column>
            <PaymentDetails>
              <dt>Nome:</dt>
              <dd>{currentUser.profile.name}</dd>
            </PaymentDetails>
            <PaymentDetails>
              <dt>CPF:</dt>
              <dd>{`${formatCpf(currentUser.profile.profile.cpf)}`}</dd>
            </PaymentDetails>
            <PaymentDetails>
              <dt>Codigo do Banco:</dt>
              <dd>{currentPayment.bank_code}</dd>
            </PaymentDetails>
            <PaymentDetails>
              <dt>ISPB:</dt>
              <dd>{currentPayment.ispb}</dd>
            </PaymentDetails>
            <PaymentDetails>
              <dt>Agência (Sem dígitos):</dt>
              <dd>{currentPayment.branch_code}</dd>
            </PaymentDetails>
            <PaymentDetails>
              <dt>Conta Corrente:</dt>
              <dd>
                {currentPayment.account_number}-{currentPayment.digit}
              </dd>
            </PaymentDetails>
            <PaymentDetails>
              <dt>Data de Solicitação:</dt>
              <dd>
                {format(
                  parseISO(currentPayment.createdAt),
                  'dd/MM/yyyy hh:mm:ss'
                )}
              </dd>
            </PaymentDetails>
            <PaymentDetails>
              <dt>Data de Agendamento:</dt>
              <dd>
                {format(
                  parseISO(currentPayment.scheduled),
                  'dd/MM/yyyy hh:mm:ss'
                )}
              </dd>
            </PaymentDetails>
            <PaymentDetails>
              <dt>Valor:</dt>
              <dd>
                {formatCurrency({ number: currentPayment.amount_redemption })}
              </dd>
            </PaymentDetails>
            <PaymentDetails>
              <dt>Valor:</dt>
              <dd>{formatCurrency({ number: currentPayment.fee })}</dd>
            </PaymentDetails>
            <PaymentDetails>
              <dt>Status:</dt>
              <dd>
                <Status status={currentPayment.status} />
              </dd>
            </PaymentDetails>
            <PaymentDetails>
              <dt>ID da Transação:</dt>
              <dd>{currentPayment.transfer_id}</dd>
            </PaymentDetails>
          </Grid.Column>
        </Grid>
      </Container>
    )
  );
}
