import React from 'react';

import Logo from '../../assets/homeup_horizontal_logo.svg';
import { Container } from './styles';

function NotFound() {
  return (
    <Container>
      <h1><img src={Logo} alt="HomeUp Diagnósticos" /></h1>
      <h2>Página não encontrada</h2>
    </Container>
  );
}

export default NotFound;
