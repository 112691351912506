import api from './api';

const list = ({ page, ...search }) => {
  return api.get('specialties', { params: { page, ...search } });
};

const listAll = () => {
  return api.get('specialties?all=1');
};

const listAllActive = () => {
  return api.get('specialties?allActive=1');
};

const get = (id) => {
  return api.get(`specialties/${id}`);
};

const getTuss = () => {
  return api.get(`specialties/tusstable`);
};

const getTussParams = (params) => {
  return api.get(`specialties/tuss`, { params });
};

const getTusByCode = (id) => {
  return api.get(`specialties/tuss/${id}`);
};

const create = (data) => {
  return api.post('specialties', data);
};

const update = ({ id, data }) => {
  return api.put(`specialties/${id}`, data);
};

const destroy = ({ id }) => {
  return api.delete(`specialties/${id}`);
};

export default {
  list,
  listAll,
  listAllActive,
  get,
  getTuss,
  getTussParams,
  getTusByCode,
  create,
  update,
  destroy,
};
