import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form as SForm } from 'semantic-ui-react';
import Input from '../../../components/input';
import Select from '../../../components/select';
import { SPECIALTY_TYPES } from '~/utils/constants';
import specialsService from '~/services/specialties';
import requestHandler from '~/services/requestHandler';
import { setLoading } from '~/store/modules/global/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const specialtyRoleOptions = () =>
  Object.keys(SPECIALTY_TYPES).map((key) => ({
    key,
    value: key,
    text: SPECIALTY_TYPES[key],
  }));

export default function SpecialtyForm({ isEditing, isCreating }) {
  const [tussList, setTussList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [valueData, setValues] = useState([]);
  const [valueSelected, setSelected] = useState('');
  const { currentSpecialty } = useSelector((state) => state.specialties);

  const dispatch = useDispatch();

  const handleSearchChange = (_e, { searchQuery }) => {
    setSearchQuery(searchQuery);
  };

  function handleTussSearch(tussCode) {
    setSelected(tussCode);
    if (tussCode.length > 5) {
      specialsService.getTusByCode(tussCode).then((res) => {
        console.log(res.data[0]);
        setValues(res.data);
        setSearchQuery(res.data[0]?.proccess);
        setTussList(res.data[0]?.code);
      });
    }
  }

  const GetterValueData = (_e, { value }) => {
    if (value.length <= 1) {
      setTussList(value);
      setSelected(value);
    }
  };

  const userOptions = () => {
    return valueData.map((s) => ({
      key: s.code,
      value: s.code,
      text: `${s.proccess}`,
    }));
  };

  useEffect(() => {
    const updateSearchList = () => {
      if (searchQuery.length >= 3) {
        requestHandler(specialsService.getTussParams, {
          partialStr: searchQuery,
        })
          .then((result) => setValues(result))
          .finally(() => dispatch(setLoading(false)));
      }
    };
    updateSearchList();
  }, [dispatch, searchQuery]);

  return (
    <SForm.Group widths="equal">
      <Select
        name="type"
        label="Tipo"
        options={specialtyRoleOptions()}
        disabled={!isEditing && !isCreating}
      />
      <Select
        name="name"
        label="Especialidade"
        multiple
        value={tussList}
        onChange={GetterValueData}
        options={userOptions()}
        searchQuery={searchQuery}
        onSearchChange={handleSearchChange}
        disabled={!isEditing && !isCreating}
      />
      <Input
        name="tuss_code"
        label="Código Tuss"
        value={valueSelected}
        onChange={(e) => handleTussSearch(e.target.value)}
        disabled={!isEditing && !isCreating}
      />
    </SForm.Group>
  );
}

SpecialtyForm.propTypes = {
  isEditing: PropTypes.bool,
  isCreating: PropTypes.bool,
};

SpecialtyForm.defaultProps = {
  isEditing: false,
  isCreating: false,
};
