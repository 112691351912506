export const userListRequest = ({ page = 1, ...rest }) => {
  return {
    type: '@users/LIST_REQUEST',
    payload: {
      page,
      ...rest,
    },
  };
};

export const userListSuccess = (userList, pagination) => {
  return {
    type: '@users/LIST_SUCCESS',
    payload: {
      userList,
      pagination,
    },
  };
};

export const getUserRequest = id => {
  return {
    type: '@users/GET_REQUEST',
    payload: id,
  };
};

export const getUserSuccess = user => {
  return {
    type: '@users/GET_SUCCESS',
    payload: user,
  };
};

export const updateUserRequest = (id, data) => {
  return {
    type: '@users/UPDATE_REQUEST',
    payload: {
      id,
      data,
    },
  };
};

export const updateUserSuccess = user => {
  return {
    type: '@users/UPDATE_SUCCESS',
    payload: user,
  };
};

export const activateUserRequest = user => {
  return {
    type: '@users/ACTIVATE_REQUEST',
    payload: user,
  };
};

export const bulkActivateUserRequest = users => {
  return {
    type: '@users/BULK_ACTIVATE_REQUEST',
    payload: users,
  };
};

export const inactivateUserRequest = user => {
  return {
    type: '@users/INACTIVATE_REQUEST',
    payload: user,
  };
};

export const bulkInactivateUserRequest = users => {
  return {
    type: '@users/BULK_INACTIVATE_REQUEST',
    payload: users,
  };
};

export const destroyUserRequest = user => ({
  type: '@users/DESTROY_REQUEST',
  payload: user,
});

export const bulkDestroyUserRequest = users => ({
  type: '@users/BULK_DESTROY_REQUEST',
  payload: users,
});

export const loadUserParticipations = ({ page = 1, ...rest }) => ({
  type: '@users/LOAD_PARTICIPATIONS',
  payload: {
    page,
    ...rest,
  },
});

export const userParticipationsLoaded = (records, pagination) => ({
  type: '@users/PARTICIPATIONS_LOADED',
  payload: { records, pagination },
});

export const uploadAvatar = (userId, avatar) => ({
  type: '@users/UPLOAD_AVATAR',
  payload: {
    userId,
    avatar,
  },
});

export const createUser = payload => ({
  type: '@users/CREATE_USER',
  payload,
});

export const dataFiled = payload => ({
  type: '@users/DATA_FILED',
  payload,
});

export const userCreated = () => ({
  type: '@users/USER_CREATED',
});

export const usersFailed = () => {
  return {
    type: '@users/FAILED',
  };
};

export const uploadFile = ({ id, files, fileType }) => ({
  type: '@users/UPLOAD',
  payload: {
    id,
    files,
    fileType
  },
});

export const fileFailed = () => ({
  type: '@users/FAILED',
});

