import React from 'react';

import {
    Paid,
    GoBack,
    Sending,
    Created
} from './styles';

export default function Status({status}){
    return(
        <>
            {
                status === 'success'&&
                <Paid>Pago</Paid>
            }
            {
                status === 'failed'&&
                <GoBack>Voltou</GoBack>
            }
            {
                status === 'created'&&
                <Created>Enviado</Created>
            }
            {
                status === 'processing'&&
                <Sending>Processando</Sending>
            }
        </>
    )
}