import produce from 'immer';

const INITIAL_STATE = {
  account: {},
  isEditing: false,
  loading: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  if (type === '@auth/SIGN_OUT') return INITIAL_STATE;
  return produce(state, (draft) => {
    switch (type) {
      case '@checking_accounts/GET_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@checking_accounts/PUT_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@checking_accounts/GET_SUCCESS': {
        draft.account = payload;
        draft.loading = false;
        break;
      }
      case '@checking_accounts/FAILED': {
        draft.loading = false;
        break;
      }
      default:
    }
  });
};
