import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import users from './users/reducer';
import global from './global/reducer';
import scheduling from './scheduling/reducer';
import appointments from './appointments/reducer';
import payments from './payments/reducer';
import checkingAccount from './checkingAccount/reducer';
import specialties from './specialties/reducer';

const reducers = combineReducers({
  auth,
  user,
  global,
  users,
  scheduling,
  appointments,
  payments,
  specialties,
  checkingAccount,
});

export default reducers;
