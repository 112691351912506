import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Comment, Button, Modal } from 'semantic-ui-react';
import { formatDistance, parseISO } from 'date-fns';
import { Form, Input } from '@rocketseat/unform';
import { get } from 'lodash';

import CommentAction from './commentAction'

import locale from 'date-fns/locale/pt-BR';

import { addComment, destroyComment } from '~/store/modules/appointments/actions';
import Textarea from '~/components/textarea';

const schema = Yup.object().shape({
  commentId: Yup.number(),
  comment: Yup.string().required('Campo obrigatório'),
});

export default function Comments({ comments, appointment_id }) {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user)
  const [comment, setComment] = useState('')
  const [buttonText, setButtonText] = useState('Adicionar')
  const [commentId, setCommentId] = useState(0)
  const [open, setOpen] = useState(false)

  const handleSubmit = (data) => {
    dispatch(
      addComment({
        appointment_id,
        ...data,
      })
    );
  };

  const handleAction = (data, type) => {
    if (type) {
      setComment(data.comment)
      setButtonText('Editar')
      setCommentId(data.id)
    } else {
      setCommentId(data.id)
      setOpen(true)
    }
  }

  const handleDestroy = () => {
    dispatch(
      destroyComment({
        appointment_id,
        commentId
      })
    );
  }

  return (
    <>
      <Comment.Group>
        <h3>Comentários</h3>
        {comments ? (
          comments.map((c) => (
            <Comment key={c.id}>
              <Comment.Avatar src={c.user.id == process.env.REACT_APP_RPA_ID ? get(c, 'user.profile.avatar') : get(c, 'user.profile.avatar_url')} />
              <Comment.Content>
                <Comment.Author as="span">{get(c, 'user.name')}</Comment.Author>
                <Comment.Metadata>
                  <span>
                    {formatDistance(parseISO(c.created_at), new Date(), {
                      locale,
                    })}
                  </span>
                </Comment.Metadata>
                {
                  currentUser.profile.id === c.user.id &&
                  <CommentAction item={c} action={handleAction}/>
                }
                <Comment.Text dangerouslySetInnerHTML={{ __html: c.comment }} />
              </Comment.Content>
            </Comment>
          ))
        ) : (
          <Comment>
            <Comment.Text>Nenhum comentário</Comment.Text>
          </Comment>
        )}
        <Form onSubmit={handleSubmit} schema={schema} className="ui reply form">
          <Input type="hidden" name="commentId" value={commentId} />
          <Textarea name="comment" value={comment} onChange={(e) => setComment(e.target.value)} />
          <Button type="submit" color="red">
            {buttonText} Comentário
          </Button>
          {
            commentId > 0 &&
            <Button type="button" default onClick={() => {setCommentId(0); setButtonText('Adicionar'); setComment('')}}>
              Cancelar
            </Button>
          }
        </Form>
      </Comment.Group>
      <Modal
        size="mini"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Modal.Header>Delete Your Comment</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete your account</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpen(false)}>
            No
          </Button>
          <Button positive onClick={handleDestroy}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

Comments.propTypes = {
  comments: PropTypes.array,
  appointment_id: PropTypes.number.isRequired
};

Comments.defaultProps = {
  comments: [],
};
