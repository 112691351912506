export const APPOINTMENT_STATUS = {
  SCDL: 'Agendado',
  PIRQ: 'Necessita Prévia',
  AWPR: 'Aguardando Prévia',
  PIFN: 'Prévia Concluída',
  WAIT: 'Aguardando Aprovação',
  APPR: 'Aprovado pelo Paciente',
  FREE: 'Sem Cobrança',
  PROC: "Procedimento Realizado",
  RQRF: 'Reembolso Solicitado',
  OKPA: 'Reembolso Pago',
  WTPA: 'Aguardando Pagamento',
  DONE: 'Finalizado',
  PNDC: 'Pendência',
  GLOS: 'Glosa',
  OVRD: 'Inadimplente',
  CANC: 'Cancelado',
//abaixo, variáveis antigas que ainda estão sendo lançadas no profile  5: 'Administrador Financeiro'
  AWTR: 'Aguardando Repasse',
  SGPF: 'Procedimento Realizado (Cirurgia)',
  PERF: 'Procedimento Realizado (Consulta)',
  DGSD: 'Diagnosticados',
  PMMG: 'Falta Pagamento',
  RBMG: 'Falta Reembolso',
};

export const STATUS_DOC = {
  SCDL: 'Agendado',
  PIRQ: 'Necessita Prévia',
  AWPR: 'Aguardando Prévia',
  PIFN: 'Prévia Concluída',
  WAIT: 'Aguardando Aprovação',
  APPR: 'Aprovado pelo Paciente',
  FREE: 'Sem Cobrança',
  PROC: "Procedimento Realizado",
  RQRF: 'Reembolso Solicitado',
  OKPA: 'Reembolso Pago',
  WTPA: 'Aguardando Pagamento',
  DONE: 'Finalizado',
  PNDC: 'Pendência',
  GLOS: 'Glosa',
  OVRD: 'Inadimplente',
  CANC: 'Cancelado',
};

export const PROFILE_TYPE = {
  1: 'Administrador',
  2: 'Prestador',
  3: 'Paciente',
  4: 'Médico',
  5: 'Administrador Financeiro',
  6: 'Médico Associado',
  7: 'Atendente'
};

export const PROFILE = {
  ADMIN: 1,
  PROVIDER: 2,
  PATIENT: 3,
  DOCTOR: 4,
  FINADMIN: 5,
  ASSDOCTOR: 6,
  ATTENDANCE: 7,
};

export const STATUS = {
  ACTV: 'Ativo',
  IACT: 'Inativo',
};

export const SPECIALTY_STATUS = {
  true: 'Ativo',
  false: 'Inativo',
};

export const SPECIALTY_TYPES = {
  EXAM: 'Exame',
  MDCL: 'Consulta Médica',
  SURG: 'Cirurgia',
  AMBL: 'Procedimento Ambulatorial',
  IMAG: 'Imagem',
  EXAL: 'Exame Laboratorial',
};

export const PAYMENTS_TYPES = {
  success: 'Pago',
  created: 'Enviado',
  failed: 'Voltou',
  processing: 'Processando',
};

export const FILE_TYPES = {
  documento_fiscal: 'Documento Fiscal',
  descricoes_cirurgicas :'Descrições Cirúrgicas',
  relatorio_medico: 'Relatório Médico',
  outros: 'Outros', // For legacy documents
};

export const FISCAL_FILE_TYPES = {
  nota_fiscal: 'Nota Fiscal',
  recibo: 'Recibo'
}

export const CHARGE_TYPES = {
  by_preview: 'Cobrar pela Prévia',
  by_value: 'Cobrar por Valor',
}

export const USER_FILE_TYPES = {
  personal_doc: 'Documento (RG, CNH)',
  insurance_card: 'Carteirinha do Plano'
}
