export function codeBankMask(value){
    return value
    .replace(/\D/g, '') 
    .replace(/(\d{3})\d+?$/, '$1')
}

export function agencyMask(value){
    return value
    .replace(/\D/g, '') 
    .replace(/(\d{4})\d+?$/, '$1')
}

export function checkingAccountMask(value){
    return value
    .replace(/\D/g, '') 
    .replace(/(\d{8})\d+?$/, '$1')
}

export function digitMask(value){
    return value
    .replace(/\D/g, '') 
    .replace(/(\d{1})\d+?$/, '$1')
}