import styled from 'styled-components';

export const Container = styled.div`
  .content {
    background: #ffffff;
    display: block;
    margin: 20px 0 40px 0;
    padding: 20px 30px 30px 30px;

    h3 {
      font-size: 20px;
      font-weight: normal;
      text-align: center;
      margin-bottom: 30px;
    }

    .column {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
      text-align: center;
    }
  }
  .ui.statistic > .value ~ .label,
  .ui.statistics .statistic > .value ~ .label {
    min-height: 38px;
  }

  .ui.statistic > .value ~ .label,
  .ui.statistics .statistic > .value ~ .label.basic {
    min-height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
