/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from '@rocketseat/unform';
import { useParams } from 'react-router-dom';
import { Button, Placeholder } from 'semantic-ui-react';
import * as Yup from 'yup';

import Input from '~/components/input';
import Select from '~/components/select';
import Checkbox from '~/components/checkbox';
import { useHistory } from 'react-router-dom';
import requestHandler from '~/services/requestHandler';
import operatorService from '~/services/operators';
import { getProfileByHash, updateRpa } from '~/services/rpa';
import Logo from '../../assets/homeup_horizontal_logo.svg';
import { Container } from './styles';
import { Row } from '~/components/form';

function RpaForm() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [insurerNameOpt, setInsurerNameOpt] = useState([]);
  const [initialData, setInitialData] = useState({});
  const { hash } = useParams();

  const formSchema = Yup.object().shape({
    accept_terms: Yup.boolean().oneOf([true], 'Obrigatório'),
    insurer_name: Yup.string().required('Obrigatório'),
    insurance_username: Yup.string().required('Obrigatório'),
    insurance_password: Yup.string().required('Obrigatório'),
    profile_id: Yup.number().nullable(),
  });

  useEffect(() => {
    setLoading(true);
    const loadOperators = async () => {
      const operatorList = await requestHandler(operatorService.onlyActive)
      if (operatorList && operatorList.length) {
        setInsurerNameOpt(operatorList.filter(op => op.type === 1).map(op => ({
          key: op.key,
          value: op.value,
          text: op.text
        })));
      }
    };
    loadOperators();

    requestHandler(getProfileByHash, hash).then(data => {
      if (!data) {
        history.push('/404');
      } else {
        setInitialData({
          profile_id: data.id,
          name: data.user.name,
          cpf: data.cpf,
        });
      }
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  const onSubmitForm = useCallback(data => {
    setSubmitting(true);
    return requestHandler(updateRpa, data).then(() => {
      return history.push('/rpa/success');
    }).finally(() => {
      setSubmitting(false);
    });
  }, []);

  return (
    <Container>
      <h1><img src={Logo} alt="HomeUp Diagnósticos" /></h1>
      <h2>Solicitação de Reembolso Automatizado</h2>

      {!loading ? (
        <>
          <p>Insira os dados de sua conta de acesso da sua Operadora de Saúde para solicitarmos o Reembolso para você:</p>

          <Form className="ui form" onSubmit={onSubmitForm} schema={formSchema} initialData={initialData}>
            <Input type="hidden" name="profile_id" />
            <Input name="name" label="Cliente" placeholder="Somente números" readOnly disabled />
            <Input name="cpf" label="CPF" placeholder="Somente números" readOnly disabled />
            <Select name="insurer_name" options={insurerNameOpt} label="Operadora de Saúde" />
            <Input name="insurance_username" label="Login" placeholder="Seu usuário" />
            <Input name="insurance_password" label="Sua Senha de Acesso" placeholder="******" type="password" />
            <Checkbox name="accept_terms" label="Autorizo a Doc.Bank a acessar minha plataforma de Operadora de Saúde apenas para solicitação de Reembolso" value="1" />
            <Row>
              <Button loading={submitting} type="submit" color="red" size="big">Enviar dados</Button>
            </Row>
          </Form>
        </>
      ) : (
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      )}

    </Container>
  );
}
export default RpaForm;
