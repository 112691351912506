export const createPayment = ({ checkingAccountId, amount }) => {
  return {
    type: '@payments/POST_REQUEST',
    payload: {
      checkingAccountId, 
      amount
    },
  };
};

export const loadPaymentsInfo = ({ userId }) => {
  return {
    type: '@payments/INFO_REQUEST',
    payload: {
      userId,
    },
  };
};

export const paymentInfoLoaded = (paymentInfo) => {
  return {
    type: '@payments/INFO_SUCCESS',
    payload: {
      paymentInfo,
    },
  };
};

export const loadPaymentList = ({ page = 1, ...rest }) => {
  return {
    type: '@payments/LIST_REQUEST',
    payload: {
      page,
      ...rest,
    },
  };
};

export const paymentListLoaded = (paymentList, pagination) => {
  return {
    type: '@payments/LIST_SUCCESS',
    payload: {
      paymentList,
      pagination,
    },
  };
};

export const paymentGetRequest = ({ paymentId })=> {
  return {
    type: '@payments/GET_REQUEST',
    payload: {
      paymentId
    },
  };
};

export const paymentGetLoaded = (currentPayment) => {
  return {
    type: '@payments/GET_SUCCESS',
    payload: {
      ...currentPayment
    },
  };
};

export const paymentFailed = () => {
  return {
    type: '@payments/FAILED',
  };
};
