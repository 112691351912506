import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import authService from '../../../services/auth';
import history from '../../../services/history';
import { signInSuccess, resetPasswordSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const response = yield call(authService.signIn, payload);
    const { token, user, expired, id } = response.data;
    if(expired){
      toast.success('Email encaminhado. Siga os passos para redefinição de senha.');
      history.push(`/forgot/${id}`, { id: id });
    }
    if(user){
      yield put(signInSuccess(token, user));
      api.defaults.headers.Authorization = `Bearer ${token}`;
      if ([1, 2, 4, 5, 6].includes(user.roleId)) {
        history.push('/dashboard');
        window.location.reload(true);
      } else if ([7].includes(user.roleId)) {
        history.push('/users');
        window.location.reload(true);
      } else
        history.push('/welcome');
      }
  } catch (err) {
    toast.error('Falha na autenticação, verifique os seus dados');
    yield put(signFailure());
  }
}
export function* verifyAccess({payload}){
  try {
    const response = yield call(authService.verifyForgot, payload);
    if(response){
      toast.success('Redefinição iniciada');
      history.push(`/forgot/redirect/${response.data.id}`);
    }
  } catch (err) {
    toast.error(err.response.data);
  }
}
export function* resetPassword({ payload }) {
  try {
    const response = yield call(authService.forgot, payload);
    const { token, expired, id } = response.data;
    yield put(resetPasswordSuccess());
    toast.success('Email encaminhado. Siga os passos para ativação da sua conta.');
    history.push(`/forgot/${id}`, { id: id });
  } catch (err) {
    toast.error(err.response.data);
    yield put(signFailure());
  }
}

export function* updatePassword({ payload }) {
  try {
    const response = yield call(authService.updatePassword, payload);
    if (response) {
      yield put(resetPasswordSuccess());
      toast.success('Sua senha foi atualizada com sucesso');
      history.push('/');
    } else {
      yield put(signFailure());
      toast.error(
        'Não foi possível atualizar a senha. Verifique os campos e tente novamente'
      );
    }
  } catch (err) {
    yield put(signFailure());
    toast.error('Falha ao tentar atualizar a senha');
  }
}

export function* updatePasswdStatus({ payload }) {
  try {
    const response = yield call(authService.updatePasswdAndStatus, payload);
    if (response) {
      yield put(resetPasswordSuccess());
      toast.success('Sua senha foi atualizada com sucesso');
      history.push('/');
    } else {
      yield put(signFailure());
      toast.error(
        'Não foi possível atualizar a senha. Verifique os campos e tente novamente'
      );
    }
  } catch (err) {
    yield put(signFailure());
    toast.error('Falha ao tentar atualizar a senha');
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/');
  window.location.reload(true);
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('@auth/RESET_PASSWORD_REQUEST', resetPassword),
  takeLatest('@auth/RESET_PASSWORD_UPDATE_REQUEST', updatePassword),
  takeLatest('@auth/PUT_PASSWD', updatePasswdStatus),
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/VERIFY_FORGOT', verifyAccess)
]);
