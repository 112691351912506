import styled from 'styled-components';

export const Container = styled.div`
  .profile {
    width: 240px;

    .profile-image {
      width: 200px;
      height: 200px;
      object-fit: contain;
      border: solid 1px #dadadb;
      margin-bottom: 8px;
      position: relative;

      img {
        width: 198px;
        height: 198px;
      }

      .image-dimmer {
        position: absolute;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.3);
        color: #ffffff;
        width: 198px;
        height: 198px;
        display: none;
        justify-content: center;
        align-items: center;
      }

      input {
        display: none;
      }

      &:hover {
        .image-dimmer {
          display: flex;
        }
      }
    }

    .profile-data {
      width: 200px;
      border: solid 1px #dadadb;
      background-color: #ffffff;
      font-family: Lato;
      line-height: 160%;
      padding: 10px 15px;

      .name {
        font-size: 18px;
        font-weight: bold;
        line-height: 180%;
        color: rgba(0, 0, 0, 0.87);
      }

      .since {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.4);
      }

      .role {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }

  .additional-info {
    flex-direction: column;
    width: 100%;
    padding: 20px;

    .field-view {
      line-height: 200%;
      font-weight: 400;
      margin-bottom: 5px;
      color: rgba(0, 0, 0, 0.8);

      .label {
        font-weight: 850;
        margin-right: 5px;
      }
    }
  }
`;

export const Title = styled.h3`
  font-size: 22px;
  color: gray;
`;
