import styled from 'styled-components';

export const Container = styled.div`
    background: #f1f1f1;
    border-radius: 5px;
    border: solid 1px #c5c5c3;
    padding: 20px;
    box-shadow: 10px 10px 10px -6px #c5c5c3;
    display: grid !important;
`;

export const RowAccount = styled.div`
  display: flex;
  justify-content: space-between; 
  align-items: baseline;
`

export const TitleAccount = styled.h2`
  text-align: left;
  margin: 0;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 500;
`

export const OptionAccount = styled.h3`
  text-align: left;
  margin: 0;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 500;
`

export const ButtonAccount = styled.button`
  color: #333333 !important;
  border: none;
  text-decoration: underline;
  color: #4554b7 !important;
  font-weight: 800;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 15px;
`
export const ModalContainer = styled.div`
  padding: 20px;
`

export const ModalRow = styled.div`
  display: flex;
  justify-content: center;
`

export const ModalClientInfo = styled.div`
  margin-top: 20px;
  padding: 20px 30px;
  display: grid;
  background: #f4f4f4 !important;
  border-radius: 30px;
`

export const TitleModal = styled.h2`
  text-align: center;
  margin: 0;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 18px;
  font-weight: 800;
`

export const TitleInModal = styled.span`
  text-align: center;
  margin: 0;
  margin-left: 5px;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 18px;
  font-weight: 200;
`

export const InformationModal = styled.h4`
  text-align: center;
  margin: 20px 0;
  margin-left: 5px;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 15px;
  color: #333333;
`

export const FormModal = styled.form`

`
export const RowForm = styled.div`
  display: flex;
`
export const ColumnForm = styled.div`
  flex: 1;
  display: grid;
  padding: 20px;
`

export const ColumnFormButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 20px;
`

export const LabelForm = styled.label`
  text-align: center;
  margin: 0;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 15px;
  font-weight: 800;
  margin-bottom: 10px;
`
export const InputModal = styled.input`
  width: 100%;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  border-radius: 5px;
  border: solid 1px #c5c5c3;
  padding: 10px;
  box-shadow: 10px 10px 10px -6px #c5c5c3;
  text-align: center;
`

export const RowInput = styled.div`
  display: flex;
`

export const InputM1 = styled.input`
  width: 100%;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  border-radius: 5px;
  border: solid 1px #c5c5c3;
  padding: 10px;
  box-shadow: 10px 10px 10px -6px #c5c5c3;
  flex: 2;
  margin-right: 30px;
  text-align: center;
`

export const InputM2 = styled.input`
  width: 100%;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  border-radius: 5px;
  border: solid 1px #c5c5c3;
  padding: 10px;
  box-shadow: 10px 10px 10px -6px #c5c5c3;
  flex: 1;
  text-align: center;
`

export const ButtonFormSave = styled.button`
  background: #c2ff61
  padding: 10px 30px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 15px;
  color: #333333 !important;
  font-weight: 800;
  margin-right: 10px;
  &:hover: {
    background: #addd62;
  }
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  svg {
    font-size: 20px !important;
    margin-right: 5px;
  }
`

export const ButtonFormBack = styled.button`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 15px;
  color: #333333 !important;
  font-weight: 800;
  margin-left: 10px;
  &:hover: {
    background: #addd62;
  }
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  svg {
    font-size: 20px !important;
    margin-right: 5px;
  }
`