import styled from 'styled-components';

export const Container = styled.div`
    border-radius: 5px;
    border: solid 1px #c5c5c3;
    padding: 20px;
    box-shadow: 10px 10px 10px -6px #c5c5c3;
`;

export const TitleInfo = styled.h2`
  text-align: center;
  margin: 0;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 500;
`

export const DescriptionInfo = styled.h1`
  text-align: center;
  margin: 0;
  font-size: 35px !important;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 700;
`

export const Divider = styled.div`
  display: block;
  background: #c5c5c3;
  width: 100%;
  height: 1px;
  margin: 10px 0;
`