import produce from 'immer';

const INITIAL_STATE = {
  profile: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  if (type === `@user/SIGN_OUT`) return INITIAL_STATE;

  return produce(state, draft => {
    switch (type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.profile = payload.user;
        break;
      }
      case '@user/UPDATE_PROFILE': {
        draft.profile = payload;
      }
      // eslint-disable-next-line no-fallthrough
      default:
        break;
    }
  });
};
