import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { unionBy } from 'lodash';
import { Modal, Button, Divider } from 'semantic-ui-react';
import { Form } from '@rocketseat/unform';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import {
  destroyAppointment,
  updateProviders,
  updateSpecialities,
} from '~/store/modules/appointments/actions';
import { ActionsContainer } from '../styles';
import history from '~/services/history';
import usersService from '~/services/users';
import { setLoading } from '~/store/modules/global/actions';
import requestHandler from '~/services/requestHandler';
import Select from '~/components/select';
import { PROFILE, SPECIALTY_TYPES } from '~/utils/constants';
export default function MediationActions({ currentAppointment, specialities }) {
  const [opened, setOpened] = useState(false);
  const [specialtyGroup, setSpecialtyGroup] = useState('');
  const [userList, setUserList] = useState(
    currentAppointment.provider_appointments.map((x) => x.provider_id)
  );
  const [list, setList] = useState(specialities);
  const [editCase, setEditCase] = useState(true);
  const [currentSelected, setCurrentId] = useState(
    currentAppointment.speciality_appointments.map((item) => item.id)
  );
  const [control, setControl] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [specials, setSpecials] = useState(false);
  const dispatch = useDispatch();

  const handleSearchChange = (_e, { searchQuery }) =>
    setSearchQuery(searchQuery);

  const handleUserListChange = (_e, { value }) => {
    setUserList(value);
    if (value.length > 0) {
      const id = value[value.length - 1];
      requestHandler(usersService.getUser, id).then((result) => {
        setList(result.specialties);
      });
    }
  };

  const [doctors, setSearchUserList] = useState(
    currentAppointment.provider_appointments.map((x) => ({
      id: x.provider_id,
      name: x.user.name,
      email: x.user.email,
      roleId: x.user.role_id,
    }))
  );

  if (!control) {
    let id = doctors[doctors.length - 1].id;
    requestHandler(usersService.getUser, id).then((result) => {
      setList(result.specialties);
      setControl(true);
    });
  }

  const specialtiesTypeOpt = () => {
    const tmp = list.map(
      (s) => ({
        key: s.id,
        value: s.type,
        text: SPECIALTY_TYPES[s.type],
      }),
      'key'
    );

    let aux = tmp.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.value === value.value)
    );
    return aux;
  };

  const specialtiesOpt = () => {
    return list
      .filter((e) => e.type === specialtyGroup)
      .map((s) => ({
        key: s.id,
        value: s.id,
        text: s.name,
      }));
  };

  const updateSearchList = () => {
    if (searchQuery.length >= 3) {
      requestHandler(usersService.getUsersList, {
        partialStr: searchQuery,
      })
        .then((result) =>
          setSearchUserList(
            unionBy(doctors, result.records, 'id').filter(
              (x) =>
                x.id !== currentAppointment.provider_id &&
                [PROFILE.DOCTOR, PROFILE.ASSDOCTOR].includes(x.roleId)
            )
          )
        )
        .finally(() => dispatch(setLoading(false)));
    } else {
      setSearchUserList(doctors.filter((u) => userList.includes(u.id)));
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    updateSearchList();
  }, [searchQuery]);

  const onClick = (v) => {
    setEditCase(v);
    setOpened(true);
  };

  const userOptions = () => {
    return doctors.map((s) => ({
      key: s.id,
      value: s.id,
      text: `${s.name} <${s.email}>`,
    }));
  };

  const onSubmit = () => {
    dispatch(setLoading(true));
    try {
      if (editCase && specials) {
        dispatch(
          updateProviders({
            id: currentAppointment.id,
            providers: userList,
          })
        );
        // let specials = dataList.filter(e => e.id === currentSelected)
        //   .map((s) => ({
        //   id: s.id,
        //   name: s.name,
        //   type: s.type,
        // }));

        dispatch(
          updateSpecialities({
            id: currentAppointment.id,
            specialty_id: currentSelected,
          })
        );
      } else if (editCase) {
        dispatch(
          updateProviders({
            id: currentAppointment.id,
            providers: userList,
          })
        );
      } else if (specials) {
        dispatch(
          updateSpecialities({
            id: currentAppointment.id,
            specialty_id: currentSelected,
          })
        );
      } else {
        toast.error(
          'Não foi possível atualizar o procedimento, necessário selecionar um campo.'
        );
      }
    } catch (err) {
      toast.error(err);
    }
    setOpened(false);
    dispatch(setLoading(false));
  };
  return (
    <>
      <ActionsContainer>
        <Button
          type="button"
          size="tiny"
          color="red"
          icon
          onClick={() => {
            dispatch(destroyAppointment(currentAppointment.id));
          }}
        >
          <FaTrashAlt /> Apagar
        </Button>
        <Link to={`/users/${currentAppointment.requester.id}/view`}>
          <Button type="button" size="tiny" color="red" icon>
            <FaEdit /> Editar Paciente
          </Button>
        </Link>

        <Button
          type="button"
          size="tiny"
          color="red"
          icon
          onClick={() => onClick(true)}
        >
          <FaEdit /> Editar Procedimento
        </Button>
      </ActionsContainer>

      <Modal
        size="tiny"
        open={opened}
        closeIcon
        onClose={() => setOpened(false)}
      >
        <Modal.Header>Adicionar Procedimento</Modal.Header>
        <Modal.Content>
          <Form className="ui form" onSubmit={onSubmit}>
            {editCase ? (
              <>
                <p>
                  Busque um especialista para atualizar o procedimento. (CPF ou
                  Nome cadastrado).
                </p>
                <Select
                  name="providers"
                  label="Profissionais"
                  multiple
                  value={userList}
                  onChange={handleUserListChange}
                  options={userOptions()}
                  searchQuery={searchQuery}
                  onSearchChange={handleSearchChange}
                />
              </>
            ) : null}

            {specials ? (
              <>
                <Select
                  name="specialtyGroup"
                  label="Tipo de Atendimento"
                  options={specialtiesTypeOpt()}
                  onChange={(_, { value }) => setSpecialtyGroup(value)}
                />
                <Select
                  disabled={!specialtyGroup}
                  name="specialty"
                  label="Procedimento"
                  options={specialtiesOpt()}
                  onChange={(_, { value }) => setCurrentId(value)}
                />
              </>
            ) : null}
            <Button
              style={{ float: 'right' }}
              type="button"
              onClick={() => setSpecials(!specials)}
            >
              Selecionar Procedimento
            </Button>
            <br />
            <br />
            <Divider />
            <Button type="button" onClick={() => setOpened(false)}>
              Cancelar
            </Button>
            <Button type="submit" color="red">
              Confirmar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
}

MediationActions.propTypes = {
  currentAppointment: PropTypes.object.isRequired,
};
