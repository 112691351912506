import React, { useState } from 'react';
import Input from '~/components/input';
import moment from 'moment';

export function TimeInput({ initialValue, ...rest }) {
  const [time, setTime] = useState(initialValue || '08:00');
  const [valid, setValid] = useState(isValid(time));

  function onBlur() {
    setValid(isValid(time));
  }

  function isValid(time) {
    return moment(time, 'HH:mm').isValid();
  }

  function onChange(e) {
    let newTime = e.target.value;
    // Remove non-numeric characters
    newTime = newTime.replace(/[^0-9]/gi, '').slice(0, 4);
    if (newTime.length > 2) {
      newTime = newTime.slice(0, 2) + ':' + newTime.slice(2, 4);
    }
    setTime(newTime);
    setValid(newTime);
  }

  return (
    <Input
      {...rest}
      value={time}
      onChange={onChange}
      invalid={!valid ? 'true' : ''}
      style={!valid ? { borderColor: 'red' } : {}}
      onBlur={onBlur}
    />
  );
}

export default TimeInput;
