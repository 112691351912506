import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table, Pagination, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { MdAdd } from 'react-icons/md';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { loadAppointmentList } from '~/store/modules/appointments/actions';
import { setBreadcrumb, setLoading } from '~/store/modules/global/actions';
import Filter from './components/filter';
import NewAppointment from './newAppointment';
import { renderAppointmentStatus } from '~/utils/statuses';
import { PROFILE, SPECIALTY_TYPES } from '~/utils/constants';
import appointmentService from '~/services/appointments';
import Scheduler from '~/components/scheduler';
import ConfirmationModal from './components/confirmationModal';
import { ActionsContainer } from './styles';

export default function AppointmentList() {
  const [modalNewAppointmentOpened, setModalNewAppointmentOpened] =
    useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [requesterId] = useState(null);
  const [specialtyId] = useState(null);
  const { loading } = useSelector((state) => state.scheduling);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const { profile: loggedUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pagination, appointmentList } = useSelector(
    (state) => state.appointments
  );
  const [filtered, setFiltered] = useState(null);
  useEffect(() => {
    function getList() {
      dispatch(setBreadcrumb('APPOINTMENTS'));
      dispatch(loadAppointmentList(pagination));
    }
    getList();
  }, [dispatch]);

  function renderAppointments() {
    return appointmentList.map(
      (
        appointment //appointment.status !== 'CANC' &&
      ) => (
        <Table.Row key={appointment.id}>
          <Table.Cell>{appointment.id}</Table.Cell>
          <Table.Cell>{get(appointment, 'requester.name', '-')}</Table.Cell>
          <Table.Cell>
            {appointment.specialty
              ? SPECIALTY_TYPES[appointment.specialty.type]
              : '-'}
          </Table.Cell>
          <Table.Cell>{get(appointment, 'provider.name', '-')}</Table.Cell>
          <Table.Cell>
            {format(parseISO(appointment.created_at), 'dd/MM/yyyy hh:mm:ss')}
          </Table.Cell>
          <Table.Cell>
            {renderAppointmentStatus(appointment.status, t)}
          </Table.Cell>
          <Table.Cell>
            <Button.Group icon>
              <Link
                to={`/appointments/${appointment.id}/view`}
                className="table-link"
              >
                Abrir
              </Link>
              <Link
                to={`/appointments/${appointment.id}/view`}
                className="table-link"
              >
                Editar
              </Link>
            </Button.Group>
          </Table.Cell>
        </Table.Row>
      )
    );
  }

  //this METHOD CHANGE
  const onCreateSubmit = async (data) => {
    dispatch(setLoading(true));
    //        speciality_appointments: ,

    try {
      const appointment = await appointmentService.create({
        providerId: loggedUser.id,
        noSchedule: true,
        requesterId: data.patients[0],
        specialty_id: data.specialty,
      });
      if (appointment) {
        toast.success('Agendamento criado com sucesso');
        dispatch(loadAppointmentList(pagination));
        setModalNewAppointmentOpened(false);
      }
    } catch (err) {
      toast.error('Não foi possível criar o agendamento');
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onPageChange = (e, { activePage: page }) => {
    dispatch(loadAppointmentList({ ...pagination, ...filtered, page }));
  };

  const onSubmit = async ({ description }) => {
    dispatch(setLoading(true));
    try {
      const appointment = await appointmentService.create({
        slot: selectedSlot,
        resource: loggedUser.timekit_resource_id,
        description,
        requesterId,
        specialty_id: specialtyId,
        providerId: loggedUser.id,
      });
      if (appointment) {
        toast.success('Agendamento efetuado com sucesso');
        setModalOpen(false);
        window.location.reload(true);
      }
    } catch (err) {
      toast.error('Não foi possível criar o agendamento');
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="content">
      <h1>Histórico de Procedimentos</h1>
      <div className="actions">
        <ActionsContainer>
          {[PROFILE.DOCTOR, PROFILE.PROVIDER, PROFILE.ASSDOCTOR].includes(
            get(loggedUser, 'roleId')
          ) && (
            <>
              <Button
                color="red"
                icon
                onClick={() => setModalNewAppointmentOpened(true)}
              >
                <MdAdd />
                Novo
              </Button>
              <br />
            </>
          )}{' '}
        </ActionsContainer>
        <Filter
          onSubmit={(data) => {
            dispatch(loadAppointmentList({ ...pagination, ...data, page: 1 }));
            setFiltered(data);
          }}
          initialData={pagination.search}
        />
      </div>
      {appointmentList.length > 0 && (
        <>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell># ID</Table.HeaderCell>
                <Table.HeaderCell>Solicitante</Table.HeaderCell>
                <Table.HeaderCell>Tipo de Serviço</Table.HeaderCell>
                <Table.HeaderCell>Prestador</Table.HeaderCell>
                <Table.HeaderCell>Criado em</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Ações</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{renderAppointments()}</Table.Body>
          </Table>
          <Pagination
            totalPages={pagination.pages}
            defaultActivePage={pagination.page}
            onPageChange={onPageChange}
            firstItem={null}
            lastItem={null}
            pointing
            secondary
          />
        </>
      )}
      <NewAppointment
        opened={modalNewAppointmentOpened}
        onClose={() => setModalNewAppointmentOpened(false)}
        onSubmit={(data) => onCreateSubmit(data)}
        specialties={loggedUser.specialties}
      />
      <Modal open={modalOpen} closeIcon onClose={() => setModalOpen(false)}>
        <Modal.Header>Escolha um horário disponível</Modal.Header>
        <Modal.Content>
          <Scheduler
            providerId={loggedUser.id}
            specialtyId={specialtyId}
            selectedSlot={selectedSlot}
            onSlotSelect={(slot) => setSelectedSlot(slot)}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button default onClick={() => setModalOpen(false)}>
            Cancelar
          </Button>
          {selectedSlot && (
            <ConfirmationModal
              patient={requesterId}
              loading={!selectedSlot || loading}
              selectedSlot={selectedSlot}
              onSubmit={onSubmit}
              specialtyType={
                loggedUser.roleId === PROFILE.PROVIDER ? 'EXAM' : 'MDCL'
              }
            />
          )}
        </Modal.Actions>
      </Modal>
    </div>
  );
}
