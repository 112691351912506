import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table, Pagination } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { get } from 'lodash';
import { loadFinancialList } from '~/store/modules/appointments/actions';
import { setBreadcrumb } from '~/store/modules/global/actions';
import Filter from './components/filter';
import { renderAppointmentStatus } from '~/utils/statuses';
import { SPECIALTY_TYPES } from '~/utils/constants';
import ExportTable from './components/exportToXLSX';

export default function FinancialList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pagination, financialList } = useSelector(
    (state) => state.appointments
  );

  const [filtered, setFiltered] = useState(null);
  const onPageChange = (e, { activePage: page }) => {
    dispatch(loadFinancialList({ ...pagination, ...filtered, page }));
  };

  useEffect(() => {
    function getList() {
      dispatch(setBreadcrumb('FINANCIAL'));
      dispatch(loadFinancialList(pagination));
    }
    getList();
  }, [dispatch]);

  function renderAppointments() {
    return financialList.map((appointment) => (
      <Table.Row key={appointment.id}>
        <Table.Cell>{appointment.id}</Table.Cell>
        <Table.Cell>{get(appointment, 'requester.name', '-')}</Table.Cell>
        <Table.Cell>
          {appointment.specialty
            ? SPECIALTY_TYPES[appointment.specialty.type]
            : '-'}
        </Table.Cell>
        <Table.Cell>{get(appointment, 'profile.cpf')}</Table.Cell>
        <Table.Cell>{get(appointment, 'provider.name', '-')}</Table.Cell>
        <Table.Cell>
          {format(parseISO(appointment.created_at), 'dd/MM/yyyy hh:mm:ss')}
        </Table.Cell>
        <Table.Cell>
          {renderAppointmentStatus(appointment.status, t)}
        </Table.Cell>
        <Table.Cell>
          <Button.Group icon>
            <Link
              to={`/appointments/${appointment.id}/view`}
              className="table-link"
            >
              Abrir
            </Link>
            <Link
              to={`/appointments/${appointment.id}/view`}
              className="table-link"
            >
              Editar
            </Link>
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    ));
  }

  return (
    <div className="content">
      <h1>Controle Financeiro</h1>

      <div className="actions">
        <Filter
          onSubmit={(data) => {
            dispatch(loadFinancialList({ ...pagination, ...data, page: 1 }));
            setFiltered(data);
          }}
          initialData={pagination.search}
        />
      </div>
      {financialList.length > 0 && (
        <>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell># ID</Table.HeaderCell>
                <Table.HeaderCell>Solicitante</Table.HeaderCell>
                <Table.HeaderCell>Tipo de Serviço</Table.HeaderCell>
                <Table.HeaderCell>CPF</Table.HeaderCell>
                <Table.HeaderCell>Prestador</Table.HeaderCell>
                <Table.HeaderCell>Criado em</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Ações</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{renderAppointments()}</Table.Body>
          </Table>

          <Pagination
            totalPages={pagination.pages}
            defaultActivePage={pagination.page}
            onPageChange={onPageChange}
            firstItem={null}
            lastItem={null}
            pointing
            secondary
          />
        </>
      )}
    </div>
  );
}
