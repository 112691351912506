import produce from 'immer';

const INITIAL_STATE = {
  loading: 0,
  lexval: [],
  breadcrumb: {
    type: null,
    params: [],
  },
  dashboard: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  if (type === '@auth/SIGN_OUT') return INITIAL_STATE;

  return produce(state, draft => {
    switch (type) {
      case '@global/SET_LOADING':
        draft.loading = payload ? state.loading + 1 : state.loading - 1;
        break;
      case '@global/SET_LEXVAL':
        draft.lexval = payload || [];
        break;
      case '@global/SET_BREADCRUMB':
        draft.breadcrumb.type = payload.type || null;
        draft.breadcrumb.params = payload.params || [];
        break;
      case '@global/DASHBOARD_LOADED':
        draft.dashboard = payload;
        break;
      default:
    }
  });
};
