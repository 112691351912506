import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';

import AuthLayout from '../pages/_layouts/auth';
import Route from './Route';
import Dashboard from '../pages/Dashboard';
import SignIn from '../pages/SignIn';
import Welcome from '../pages/Welcome';
import ResetPassword from '../pages/ResetPassword';
import TokenRedirect from '../pages/ForgotPasswdTk';
import Users from '../pages/Users';
import Appointments from '../pages/Appointments';
import Calendar from '../pages/Calendar';
import Auditor from '../pages/Auditor';
import Finances from '../pages/Financial';
import Report from '../pages/Report';
import Specialties from '../pages/Specialties';
import Payments from '../pages/Payments';
import RpaForm from '~/pages/RpaForm';
import BlankLayout from '~/pages/_layouts/blank';
import NotFound from '~/pages/NotFound';
import RpaSuccess from '~/pages/RpaForm/success';
// import RegisterLayout from '../pages/_layouts/register';
// import SignUp from '../pages/SignUp';

export default function () {
  return (
    <Suspense fallback={<div>loading...</div>}>
        <Switch>
          <Route path="/" exact component={SignIn} layout={AuthLayout} />
          <Route path="/rpa/success" exact component={RpaSuccess} isPublic layout={BlankLayout} />
          <Route path="/rpa/:hash" component={RpaForm} layout={BlankLayout} isPublic />
          <Route
            path="/resetPassword"
            component={ResetPassword}
            layout={AuthLayout}
          />
          <Route
            path="/forgot"
            component={TokenRedirect}
            layout={AuthLayout}
          />
          {/* <Route path="/register" component={SignUp} layout={RegisterLayout} /> */}
          <Route
            path="/welcome"
            component={Welcome}
            layout={AuthLayout}
            isPrivate
          />
          <Route path="/dashboard" component={Dashboard} isPrivate />
          <Route path="/users" component={Users} isPrivate />
          <Route path="/specialties" component={Specialties} isPrivate />
          <Route path="/appointments" component={Appointments} isPrivate />
          <Route path="/financial" component={Finances} isPrivate />
          <Route path="/report" component={Report} isPrivate />
          <Route path="/calendar" component={Calendar} isPrivate />
          <Route path="/auditor" component={Auditor} isPrivate />
          <Route path="/payments" component={Payments} isPrivate />

          <Route component={NotFound} layout={BlankLayout} />
        </Switch>
    </Suspense>
  );
}
