import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import ListView from './listView';
import ReportView from './reportView';

export default function Appointments() {
  return (
    <Suspense fallback="loading">
      <Switch>
        <Route path="/report/:status/view/:selected" component={ListView} />
        <Route path="/report/:status/view" component={ListView} />
        <Route path="/report" exact component={ReportView} />
      </Switch>
    </Suspense>
  );
}
