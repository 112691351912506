import api from './api';

const load = (payload) => {
  const { user_id } = payload;
  return api.get(`/checking-account/user/${user_id}`);
};

const update = (payload) => {
  const { user_id, accountData } = payload;
  return api.put(`/checking-account/user/${user_id}`, accountData);
}

export default {
  load,
  update,
};
