/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Header, Icon, Segment, List } from 'semantic-ui-react';
import { FaRegFileAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { ShadowBox } from '../../styles';
import { setLoading } from '~/store/modules/global/actions';
import { getAppointmentRequest } from '~/store/modules/appointments/actions';
import filesService from '~/services/files';
import requestHandler from '~/services/requestHandler';
import DeleteModal from './components/deleteModal';
import InsertModal from './components/insertModal';
import { Container } from './styles';
import { FILE_TYPES, FISCAL_FILE_TYPES } from '~/utils/constants';

function buildFileName(file){
  const fiscalDocType = file.fiscal_doc_type !== 'null'
    ? `(${FISCAL_FILE_TYPES[file.fiscal_doc_type]})`
    : '';
  const filename = `${FILE_TYPES[file.archive_type]} ${fiscalDocType} - ${file.name}`

  return  filename
}

export default function Files({ appointment }) {
  const { id, files } = appointment;
  const dispatch = useDispatch();
  const deleteFile = async (file) => {
    dispatch(setLoading(true));
    try {
      const response = await requestHandler(filesService.destroy, file);
      if (response && !response.error) {
        toast.success('Arquivo removido com sucesso');
        dispatch(getAppointmentRequest(file.appointment_id));
        return true;
      }
    } catch (err) {
      toast.error('Não foi possível remover o arquivo');
    } finally {
      dispatch(setLoading(false));
    }
    return false;
  };

  return (
    <Container>
      <ShadowBox style={{backgroundColor: '#fafafa'}}>
        <h3 style={{ display: 'flex' }}>
          Arquivos anexados{' '}
          {files && files.length > 0 && (
            <InsertModal appointmentId={ id }/>
          )}
        </h3>

        {files && files.length > 0 ? (
            <ShadowBox className="boxfiles" id="boxfiles" style={{
              backgroundColor: 'white',
              display: 'flex',
              borderRadius: '0px',
              overflow: 'scroll',
              margin: '10px',
              padding: '60px',
              scrollDirection: 'vertical'
            }}>
              {files.map((file) => (
                <List.Item className="file-item">
                  <List.Content floated="left">
                    <FaRegFileAlt size="40" />
                  </List.Content>
                  <a
                    href={file.url}
                    alt={buildFileName(file)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="body-link"
                  >
                    {buildFileName(file)}
                  </a>
                  <List.Content floated="right">
                    <DeleteModal file={file} onConfirm={() => deleteFile(file)} />
                  </List.Content>
                </List.Item>
              ))}
            </ShadowBox>
        ) : (
          <Segment placeholder>
            <Header icon>
              <Icon name="pdf file outline" />
              Nenhum documento anexado
            </Header>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <InsertModal appointmentId={ id }/>
          </Segment>
        )}
      </ShadowBox>
    </Container>
  );
}

Files.propTypes = {
  appointment: PropTypes.object.isRequired,
  files: PropTypes.array.isRequired,
};
