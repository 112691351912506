import { takeLatest, all, call, put } from 'redux-saga/effects';

import requestHandler from '../../middlewares/requestHandler';
import { dashboardLoaded } from './actions';
import api from '~/services/api';

function* loadDashboard() {
  const response = yield call(requestHandler, api.get, '/dashboard');

  if (response) {
    yield put(dashboardLoaded(response));
  }
}

export default all([takeLatest('@global/LOAD_DASHBOARD', loadDashboard)]);
