export const loadAppointmentList = ({ page = 1, ...rest }) => {
  return {
    type: '@appointments/LIST_REQUEST',
    payload: {
      page,
      ...rest,
    },
  };
};

export const loadFinancialList = ({ page = 1, ...rest }) => {
  return {
    type: '@appointments/FINANCIAL_REQUEST',
    payload: {
      page,
      ...rest,
    },
  };
}

export const appointmentListLoaded = (appointmentList, pagination) => {
  return {
    type: '@appointments/LIST_SUCCESS',
    payload: {
      appointmentList,
      pagination,
    },
  };
};

export const financialListLoaded = (financialList, pagination) => {
  return {
    type: '@appointments/FINANCIAL_SUCCESS',
    payload: {
      financialList,
      pagination,
    },
  };
};

export const loadPerformanceReport = ({ status, startDate, endDate }) => {
  return {
    type: '@appointments/REPORT_REQUEST',
    payload: { status, startDate, endDate },
  };
};

export const performanceReportLoaded = (appointmentList) => {
  return {
    type: '@appointments/STATISTICS_SUCCESS',
    payload: { appointmentList },
  };
};

export const getAppointmentRequest = (id) => {
  return {
    type: '@appointments/GET_REQUEST',
    payload: id,
  };
};

export const destroyAppointment = (id) => {
  return {
    type: '@appointments/DESTROY_APPOINTMENT',
    payload: id,
  };
};

export const getAppointmentSuccess = (appointment) => {
  return {
    type: '@appointments/GET_SUCCESS',
    payload: appointment,
  };
};

export const setEditing = (value) => {
  return {
    type: '@appointments/SET_EDITING',
    payload: value,
  };
};

export const addComment = (payload) => ({
  type: '@appointments/ADD_COMMENT',
  payload,
});

export const destroyComment = (payload) => ({
  type: '@appointments/DESTROY_COMMENT',
  payload,
})


export const commentAdded = () => ({
  type: '@appointments/COMMENT_ADDED',
});

export const appointmentFailed = () => {
  return {
    type: '@appointments/FAILED',
  };
};

export const updateStatus = (payload) => {
  return {
    type: '@appointments/UPDATE_STATUS',
    payload,
  };
};

export const updateProviders = (payload) => {
  return {
    type: '@appointments/UPDATE_PROVIDERS',
    payload,
  };
};

export const updateSpecialities = (payload) => {
  return {
    type: '@appointments/UPDATE_CURRENT_APPOINTMENT',
    payload,
  };
};

export const updateStatusWithBoleto = (payload) => {
  return {
    type: '@appointments/UPDATE_STATUS_WITH_BOLETO',
    payload,
  };
};

export const updateFinancialAmount = (payload) => {
  return {
    type: '@appointments/UPDATE_FINANCIALAMOUNT',
    payload,
  };
};


