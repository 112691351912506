import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@rocketseat/unform';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import Select from '~/components/select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { registerLocale } from  "react-datepicker";
import { uploadFile } from '~/store/modules/users/actions';
import ptBR from 'date-fns/locale/pt-BR';
import { USER_FILE_TYPES } from '~/utils/constants';
registerLocale('pt-BR', ptBR);

export default function InsertModal({ userId }) {
  const [opened, setOpened] = useState(false);
  const [formData, setFormData] = useState({});
  const [ fileType, setFileType ] = useState(null);
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    dispatch(
      uploadFile({
        id: userId,
        fileType,
        files: e.target.files,
        ...formData
      })
    );
  };

  const fileTypes = Object.keys(USER_FILE_TYPES).map((e, index) => (
    {
      key: index,
      value: e,
      text: USER_FILE_TYPES[e],
    }
  ))

  const submitForm = async (data) => {
    try {
      const schema = Yup.object().shape(
        Object.assign(
          {

            fileType: Yup.string().required(),
          },
        )
      );

      await schema.validate(data);
      setFormData(data);

      inputRef.current.click();
    } catch (error) {
      toast.error('Preencha todos os campos antes de selecionar o arquivo.');
    }
  }

  return (
    <>
      <Button
        color="red"
        icon
        onClick={() => setOpened(true)}
        style={{ cursor: 'pointer', marginLeft: '5px' }}
      >
        Adicionar
      </Button>
      <Modal size="tiny" open={opened} onClose={() => setOpened(false)}>
        <Modal.Header>Selecione o Tipo de Arquivo</Modal.Header>
        <Modal.Content>
          <Form
            className="ui form"
            onSubmit={submitForm}
          >
              <Select
                name='fileType'
                label="Tipo do Arquivo"
                options={fileTypes}
                value={fileType}
                onChange={(_, {value}) => setFileType(value)}
              />
              <Button
                color="red"
                type="submit"
                disabled={!fileType}
              >
                Escolher Arquivo
              </Button>
          </Form>
        </Modal.Content>
        <input
          ref={inputRef}
          hidden={true}
          id="file"
          type="file"
          name="file[]"
          onChange={handleSubmit}
          multiple
        />
      </Modal>
    </>
  );
}

InsertModal.propTypes = {
  userId: PropTypes.number.isRequired,
};
