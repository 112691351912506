import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import SpecialtyList from './list';
import SpecialtyEdit from './edit';
import SpecialtyNew from './new';

export default function Specialties() {
  return (
    <Suspense fallback="loading">
      <Switch>
        <Route path="/specialties/:id/edit" component={SpecialtyEdit} />
        <Route path="/specialties/new" exact component={SpecialtyNew} />
        <Route path="/specialties" exact component={SpecialtyList} />
      </Switch>
    </Suspense>
  );
}
