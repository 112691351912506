import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Statistic, Grid, Label } from 'semantic-ui-react';
import { get } from 'lodash';

import { setBreadcrumb, loadDashboard } from '~/store/modules/global/actions';
import { Container } from './styles';

export default function Dashboard() {
  const dispatch = useDispatch();
  const { dashboard } = useSelector((state) => state.global);

  useEffect(() => {
    dispatch(setBreadcrumb('HOME'));
    dispatch(loadDashboard());
  }, [dispatch]);

  const renderDiff = (diff, defaultValue, isDaily) => {
    if (diff) {
      return (
        <Label basic color={diff > 0 ? 'green' : 'red'}>
          {diff > 0 ? `+${diff}` : diff} {isDaily ? 'da última' : 'do último'}
        </Label>
      );
    }
    return null;
  };

  return (
    <Container>
      <div className="content">
        <h3>Resultado da Semana</h3>
        {get(dashboard, 'weekly') ? (
          <Grid columns="equal">
            {/* <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'weekly.SCRQ.count')}
                </Statistic.Value>
                <Statistic.Label>Solicitado Agendamento</Statistic.Label>
                {renderDiff(get(dashboard, 'weekly.SCRQ.diff'), 0, true)}
              </Statistic>
            </Grid.Column> */}
            {/* <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'weekly.SCDL.count')}
                </Statistic.Value>
                <Statistic.Label>Agendadas</Statistic.Label>
                {renderDiff(get(dashboard, 'weekly.SCDL.diff'), 0, true)}
              </Statistic>
            </Grid.Column> */}
            <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'weekly.PERF.count')}
                </Statistic.Value>
                <Statistic.Label>Consulta realizada</Statistic.Label>
                {renderDiff(get(dashboard, 'weekly.PERF.diff'), 0, true)}
              </Statistic>
            </Grid.Column>
            {/* <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'weekly.PIRQ.count')}
                </Statistic.Value>
                <Statistic.Label>Necessita Prévia</Statistic.Label>
                {renderDiff(get(dashboard, 'weekly.PIRQ.diff'), 0, true)}
              </Statistic>
            </Grid.Column> */}
            {/* <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'weekly.PIFN.count')}
                </Statistic.Value>
                <Statistic.Label>Prévia Concluída</Statistic.Label>
                {renderDiff(get(dashboard, 'weekly.PIFN.diff'), 0, true)}
              </Statistic>
            </Grid.Column> */}
            <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'weekly.SGPF.count')}
                </Statistic.Value>
                <Statistic.Label>Cirurgia Realizada</Statistic.Label>
                {renderDiff(get(dashboard, 'weekly.SGPF.diff'), 0, true)}
              </Statistic>
            </Grid.Column>
            {/* <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'weekly.DGSD.count')}
                </Statistic.Value>
                <Statistic.Label>Diagnosticados</Statistic.Label>
                {renderDiff(get(dashboard, 'weekly.DGSD.diff'), 0, true)}
              </Statistic>
            </Grid.Column> */}
            <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'weekly.RBMG.count')}
                </Statistic.Value>
                <Statistic.Label>Falta Reembolso</Statistic.Label>
                {renderDiff(get(dashboard, 'weekly.RBMG.diff'), 0, true)}
              </Statistic>
            </Grid.Column>
            <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'weekly.PMMG.count')}
                </Statistic.Value>
                <Statistic.Label>Falta Pagamento</Statistic.Label>
                {renderDiff(get(dashboard, 'weekly.PMMG.diff'), 0, true)}
              </Statistic>
            </Grid.Column>
            <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'weekly.DONE.count')}
                </Statistic.Value>
                <Statistic.Label>Finalizadas</Statistic.Label>
                {renderDiff(get(dashboard, 'weekly.DONE.diff'), 0, true)}
              </Statistic>
            </Grid.Column>
          </Grid>
        ) : (
            <p>No data</p>
          )}
      </div>

      <div className="content">
        <h3>Resultado do Mês</h3>
        {get(dashboard, 'monthly') ? (
          <Grid columns="equal">
            {/* <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'monthly.SCRQ.count')}
                </Statistic.Value>
                <Statistic.Label>Solicitado Agendamento</Statistic.Label>
                {renderDiff(get(dashboard, 'monthly.SCRQ.diff'), 0)}
              </Statistic>
            </Grid.Column> */}
            {/* <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'monthly.SCDL.count')}
                </Statistic.Value>
                <Statistic.Label>Agendadas</Statistic.Label>
                {renderDiff(get(dashboard, 'monthly.SCDL.diff'), 0)}
              </Statistic>
            </Grid.Column> */}
            <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'monthly.PERF.count')}
                </Statistic.Value>
                <Statistic.Label>Consulta realizada</Statistic.Label>
                {renderDiff(get(dashboard, 'monthly.PERF.diff'), 0)}
              </Statistic>
            </Grid.Column>
            {/* <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'monthly.PIRQ.count')}
                </Statistic.Value>
                <Statistic.Label>Necessita Prévia</Statistic.Label>
                {renderDiff(get(dashboard, 'monthly.PIRQ.diff'), 0)}
              </Statistic>
            </Grid.Column> */}
            {/* <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'monthly.PIFN.count')}
                </Statistic.Value>
                <Statistic.Label>Prévia Concluída</Statistic.Label>
                {renderDiff(get(dashboard, 'monthly.PIFN.diff'), 0)}
              </Statistic>
            </Grid.Column> */}
            <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'monthly.SGPF.count')}
                </Statistic.Value>
                <Statistic.Label>Cirurgia Realizada</Statistic.Label>
                {renderDiff(get(dashboard, 'monthly.SGPF.diff'), 0)}
              </Statistic>
            </Grid.Column>
            {/* <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'monthly.DGSD.count')}
                </Statistic.Value>
                <Statistic.Label>Diagnosticados</Statistic.Label>
                {renderDiff(get(dashboard, 'monthly.DGSD.diff'), 0)}
              </Statistic>
            </Grid.Column> */}
            <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'monthly.RBMG.count')}
                </Statistic.Value>
                <Statistic.Label>Falta Reembolso</Statistic.Label>
                {renderDiff(get(dashboard, 'monthly.RBMG.diff'), 0)}
              </Statistic>
            </Grid.Column>
            <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'monthly.PMMG.count')}
                </Statistic.Value>
                <Statistic.Label>Falta Pagamento</Statistic.Label>
                {renderDiff(get(dashboard, 'monthly.PMMG.diff'), 0)}
              </Statistic>
            </Grid.Column>
            <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'monthly.DONE.count')}
                </Statistic.Value>
                <Statistic.Label>Finalizadas</Statistic.Label>
                {renderDiff(get(dashboard, 'monthly.DONE.diff'), 0)}
              </Statistic>
            </Grid.Column>
          </Grid>
        ) : (
            <p>No data</p>
          )}
      </div>

      <div className="content">
        <h3>Resultado do Ano</h3>
        {get(dashboard, 'yearly') ? (
          <Grid columns="equal">
            {/* <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'yearly.SCRQ.count')}
                </Statistic.Value>
                <Statistic.Label>Solicitado Agendamento</Statistic.Label>
                {renderDiff(get(dashboard, 'yearly.SCRQ.diff'), 0)}
              </Statistic>
            </Grid.Column> */}
            {/* <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'yearly.SCDL.count')}
                </Statistic.Value>
                <Statistic.Label>Agendadas</Statistic.Label>
                {renderDiff(get(dashboard, 'yearly.SCDL.diff'), 0)}
              </Statistic>
            </Grid.Column> */}
            <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'yearly.PERF.count')}
                </Statistic.Value>
                <Statistic.Label>Consulta Realizada</Statistic.Label>
                {renderDiff(get(dashboard, 'yearly.PERF.diff'), 0)}
              </Statistic>
            </Grid.Column>
            {/* <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'yearly.PIRQ.count')}
                </Statistic.Value>
                <Statistic.Label>Necessita Prévia</Statistic.Label>
                {renderDiff(get(dashboard, 'yearly.PIRQ.diff'), 0)}
              </Statistic>
            </Grid.Column> */}
            {/* <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'yearly.PIFN.count')}
                </Statistic.Value>
                <Statistic.Label>Prévia Concluída</Statistic.Label>
                {renderDiff(get(dashboard, 'yearly.PIFN.diff'), 0)}
              </Statistic>
            </Grid.Column> */}
            <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'yearly.SGPF.count')}
                </Statistic.Value>
                <Statistic.Label>Cirurgia Realizada</Statistic.Label>
                {renderDiff(get(dashboard, 'yearly.SGPF.diff'), 0)}
              </Statistic>
            </Grid.Column>
            {/* <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'yearly.DGSD.count')}
                </Statistic.Value>
                <Statistic.Label>Diagnosticados</Statistic.Label>
                {renderDiff(get(dashboard, 'yearly.DGSD.diff'), 0)}
              </Statistic>
            </Grid.Column> */}
            <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'yearly.RBMG.count')}
                </Statistic.Value>
                <Statistic.Label>Falta Reembolso</Statistic.Label>
                {renderDiff(get(dashboard, 'yearly.RBMG.diff'), 0)}
              </Statistic>
            </Grid.Column>
            <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'yearly.PMMG.count')}
                </Statistic.Value>
                <Statistic.Label>Falta Pagamento</Statistic.Label>
                {renderDiff(get(dashboard, 'yearly.PMMG.diff'), 0)}
              </Statistic>
            </Grid.Column>
            <Grid.Column>
              <Statistic size="small">
                <Statistic.Value>
                  {get(dashboard, 'yearly.DONE.count')}
                </Statistic.Value>
                <Statistic.Label>Finalizadas</Statistic.Label>
                {renderDiff(get(dashboard, 'yearly.DONE.diff'), 0)}
              </Statistic>
            </Grid.Column>
          </Grid>
        ) : (
            <p>No data</p>
          )}
      </div>
    </Container>
  );
}
