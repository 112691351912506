import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@rocketseat/unform';
import { Button } from 'semantic-ui-react';
import { MdSave } from 'react-icons/md';
import { Container } from './styles';
import SpecialtyForm from './components/form';
import schema from './components/validation';
import specialsService from '~/services/specialties';
import requestHandler from '~/services/requestHandler';

import { createSpecialtyRequest } from '../../store/modules/specialties/actions';
import { setBreadcrumb } from '../../store/modules/global/actions';

export default function SpecialtyNew() {
  const dispatch = useDispatch();

  useEffect(() => {
    function dispatchSetBreadcrumb() {
      dispatch(setBreadcrumb('SPECIALTY_NEW'));
    }

    dispatchSetBreadcrumb();
  }, [dispatch]);

  const handleSubmit = (data) => {
    console.log(data);
    let code = data.name;
    let type = data.type;
    requestHandler(specialsService.getTusByCode, code).then((result) => {
      const response = {
        type: type,
        name: result[0].proccess,
        tuss_code: code,
      };
      dispatch(createSpecialtyRequest(response));
    });
  };
  return (
    <Container className="content">
      <h1>Cadastrar Especialidade</h1>
      <Form className="ui form" schema={schema} onSubmit={handleSubmit}>
        <SpecialtyForm isCreating />
        <Button color="red" icon type="submit">
          <MdSave />
          Gravar
        </Button>
      </Form>
    </Container>
  );
}
