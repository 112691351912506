import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@rocketseat/unform';
import schema from './components/validation';
import {
  updateSpecialty,
  getSpecialtyRequest,
} from '../../store/modules/specialties/actions';
import { setBreadcrumb } from '../../store/modules/global/actions';
import SpecialtyActions from './components/actions';
import SpecialtyForm from './components/form';
import { Container } from './styles';
import requestHandler from '~/services/requestHandler';
import specialsService from '~/services/specialties';

export default function SpecialtyEdit(props) {
  const [control, setControl] = useState(false);
  const { currentSpecialty, isEditing } = useSelector(
    state => state.specialties
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const specialtyId = props.match.params.id;
    if(!control){
      if (!currentSpecialty || currentSpecialty.id !== specialtyId) {
        dispatch(getSpecialtyRequest(specialtyId));
      }
      setControl(true);
    }
  }, [props, currentSpecialty, dispatch, control]);

  useEffect(() => {
    dispatch(setBreadcrumb('SPECIALTY_DETAIL', currentSpecialty));
  }, [currentSpecialty, dispatch]);

  const handleSubmit = data => {
    const { id } = props.match.params;
    let code = data.name
    let type = data.type
    requestHandler(specialsService.getTusByCode, code)
    .then((result) => {
      const response = {type: type, name: result[0].proccess, tuss_code: code}
      dispatch(updateSpecialty(id, response));
    })
  };

  return (
    <Container className="content">
      <h1>
        {currentSpecialty ? `${currentSpecialty?.name}` : 'Editar Especialidade'}
      </h1>
      {currentSpecialty && (
        <Form
          className="ui form"
          onSubmit={data => handleSubmit(data)}
          schema={schema}
          initialData={currentSpecialty}
        >
          <SpecialtyActions
            isEditing={isEditing}
            currentSpecialty={currentSpecialty}
          />
          <SpecialtyForm isEditing={isEditing} onSubmit={handleSubmit}  />
        </Form>
      )}
    </Container>
  );
}

SpecialtyEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
