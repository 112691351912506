import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal, Button, Divider } from 'semantic-ui-react';
import { unionBy } from 'lodash';
import { Form } from '@rocketseat/unform';
import usersService from '~/services/users';
import { setLoading } from '~/store/modules/global/actions';
import requestHandler from '~/services/requestHandler';
import Select from '~/components/select';
import { PROFILE } from '~/utils/constants';

export default function NewScheduling({
  opened,
  onSubmit,
  onClose,
  specialties,
}) {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const [searchUserList, setSearchUserList] = useState(
    userList.map((x) => ({
      id: x.id,
      name: x.name,
      email: x.email,
    }))
  );
  const [searchQuery, setSearchQuery] = useState('');

  /* eslint-disable no-shadow */
  const handleSearchChange = (_e, { searchQuery }) =>
    setSearchQuery(searchQuery);
  const handleUserListChange = (_e, { value }) => {
    setUserList(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSearchUserList = () => {
    if (searchQuery.length >= 3) {
      requestHandler(usersService.listUsers, {
        partialStr: searchQuery,
      })
        .then((result) =>
          setSearchUserList(
            unionBy(searchUserList, result, 'id').filter(
              (x) => x.roleId === PROFILE.PATIENT
            )
          )
        )
        .finally(() => dispatch(setLoading(false)));
    } else {
      setSearchUserList(searchUserList.filter((u) => userList.includes(u.id)));
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    updateSearchUserList();
  }, [updateSearchUserList]);

  const userOptions = () => {
    return searchUserList.map((s) => ({
      key: s.id,
      value: s.id,
      text: `${s.name} <${s.email}>`,
    }));
  };

  const specialtiesOpt = () => {
    return specialties.map((s) => ({
      key: s.id,
      value: s.id,
      text: s.name,
    }));
  };

  return (
    <>
      <Modal open={opened} closeIcon onClose={onClose}>
        <Modal.Header>Nova consulta</Modal.Header>
        <Modal.Content>
          <p>Busque o paciente para adicionar à consulta. Busque por e-mail.</p>
          <Form className="ui form" onSubmit={onSubmit}>
            <Select
              name="patients"
              label="Paciente"
              multiple
              value={userList}
              onChange={handleUserListChange}
              options={userOptions()}
              searchQuery={searchQuery}
              onSearchChange={handleSearchChange}
            />
            <Select
              name="specialty"
              label="Especialidade"
              options={specialtiesOpt()}
            />
            <Divider />
            <Button type="button" onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button type="submit" color="red">
              Confirmar
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
}

NewScheduling.propTypes = {
  opened: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  specialties: PropTypes.array.isRequired,
};
