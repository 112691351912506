import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'aside header'
    'aside content';
  grid-template-rows: 50px 1fr;
  grid-template-columns: 180px 1fr;
`;

export const MainContent = styled.div`
  grid-area: content;
  overflow-x: hidden;
  box-shadow: inset 0 0 20px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 20px 0 20px;
  background-color: #f0f2f5;
  overflow-y: auto;

  & > .content {
    background: #ffffff;
    margin-top: 10px;
    padding: 20px;
    border-radius: 2px;
    height: 100%;
  }

  h1 {
    color: #333333;
    font-size: 28px;
  }
`;
