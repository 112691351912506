import styled from 'styled-components';

export const List = styled.div`
  .list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;

    &:hover {
      background: #efefef;
    }
  }
`;
