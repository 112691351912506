import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form as SForm, Grid } from 'semantic-ui-react';
import Input from '~/components/input';
import Select from '~/components/select';
import DatePicker from '~/components/date-picker';
import HealthInfo from '~/pages/SignUp/HealthInfo';
import { PROFILE_TYPE, PROFILE } from '~/utils/constants';

const providerOpt = { 3: 'Paciente', 7: 'Atendente' };

const roleOptions = (profile) => {
  if ([PROFILE.ADMIN].includes(profile.roleId)) {
    return Object.keys(PROFILE_TYPE).map((key) => ({
      key,
      value: key,
      text: PROFILE_TYPE[key],
    }));
  } else {
    return Object.keys(providerOpt).map((key) => ({
      key,
      value: key,
      text: providerOpt[key],
    }));
  }
};

export default function UserForm({ roleId, setRoleId }) {
  const { currentUser } = useSelector((state) => state.users);
  const { profile } = useSelector((state) => state.user);
  const parsedRoleId = parseInt(roleId, 10);
  const isPhysician = [PROFILE.DOCTOR].includes(parsedRoleId);
  const isAssDoctor = [PROFILE.ASSDOCTOR].includes(parsedRoleId);
  const isPhysicianOrProvider = [
    PROFILE.ASSDOCTOR,
    PROFILE.PROVIDER,
    PROFILE.DOCTOR,
  ].includes(parsedRoleId);
  const isPatient = !parsedRoleId || PROFILE.PATIENT === parsedRoleId;

  return (
    <>
      <Input type="hidden" name="id" />
      {currentUser ? (
        <Input type="hidden" name="roleId" />
      ) : (
        <SForm.Group widths="equal">
          <Select
            name="roleId"
            label="Função"
            options={roleOptions(profile)}
            onChange={(e, { value }) => {
              [PROFILE.ADMIN].includes(profile.roleId)
                ? setRoleId(value)
                : setRoleId(PROFILE.PATIENT);
            }}
          />
        </SForm.Group>
      )}
      <SForm.Group widths="equal">
        <Input name="name" label="Nome" />

        <div
          style={{
            width: '100%',
            flex: '1 1 auto',
            marginRight: '10px',
          }}
        >
          <SForm.Group widths="equal">
            <Input name="profile.phone" label="Telefone celular" />
            <DatePicker name="profile.birthday" label="Data de Nascimento" />
          </SForm.Group>
        </div>
        <div
          style={{
            width: '100%',
            flex: '1 1 auto',
            paddingRight: '7px',
            boxSizing: 'border-box',
          }}
        >
          <SForm.Group widths="equal">
            <Input name="profile.cpf" label="CPF" />
            <Input name="profile.cnpj" label="CNPJ" />
          </SForm.Group>
        </div>
      </SForm.Group>
      <SForm.Group widths="equal">
        <Input name="email" label="E-mail" />
        <Input type="password" name="password" label="Senha" />
        <Input
          type="password"
          name="confirmPassword"
          label="Confirme a senha"
        />
      </SForm.Group>
      <Grid stackable columns="2" padded="vertically">
        <Grid.Row>
          <Grid.Column>
            <h4>Endereço Pessoal</h4>
            <SForm.Group>
              <Input name="profile.zipcode" label="CEP" />
            </SForm.Group>
            <SForm.Group widths="equal">
              <Input name="profile.street" label="Logradouro" width={10} />
              <Input name="profile.number" label="Número" width={6} />
            </SForm.Group>
            <SForm.Group widths="equal">
              <Input name="profile.complement" label="Complemento" />
              <Input name="profile.district" label="Bairro" />
            </SForm.Group>
            <SForm.Group widths="equal">
              <Input name="profile.city" label="Cidade" />
              <Input name="profile.state" label="UF" />
            </SForm.Group>
          </Grid.Column>
          {(isPhysician || isAssDoctor) && (
            <Grid.Column>
              <h4>Endereço Profissional</h4>
              <SForm.Group>
                <Input name="professionalData.zipcode" label="CEP" />
              </SForm.Group>
              <SForm.Group widths="equal">
                <Input name="professionalData.street" label="Logradouro" />
                <Input name="professionalData.number" label="Número" />
              </SForm.Group>
              <SForm.Group widths="equal">
                <Input name="professionalData.complement" label="Complemento" />
                <Input name="professionalData.district" label="Bairro" />
              </SForm.Group>
              <SForm.Group widths="equal">
                <Input name="professionalData.city" label="Cidade" />
                <Input name="professionalData.state" label="UF" />
              </SForm.Group>
            </Grid.Column>
          )}
          {isPatient && (
            <Grid.Column>
              <h4>Dados Saúde</h4>
              <HealthInfo
                inputFieldPrefix="profile"
                profile={currentUser && currentUser.profile}
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      {isPhysicianOrProvider && (
        <>
          <SForm.Group widths="equal">
            <Input
              name="professionalData.professional_id"
              label="Nº de Identificação Profissional"
            />
          </SForm.Group>
        </>
      )}
      {isAssDoctor && (
        <>
          <SForm.Group widths="equal">
            <Input
              name="professionalData.percentage_transfer"
              label="Percentual de Repasse"
              type="number"
            />
          </SForm.Group>
        </>
      )}
      {isPhysician && (
        <>
          <SForm.Group widths="equal">
            <Input name="professionalData.company" label="Nome da empresa" />
          </SForm.Group>
          <SForm.Group widths="equal">
            <Input
              name="professionalData.private_header_key"
              label="Private header key"
            />
          </SForm.Group>
          <SForm.Group widths="equal">
            <Input name="professionalData.private_key" label="Private key" />
          </SForm.Group>
          <SForm.Group widths="equal">
            <Input name="professionalData.project_id" label="Project id" />
          </SForm.Group>
        </>
      )}
    </>
  );
}

UserForm.propTypes = {
  roleId: PropTypes.any.isRequired,
  setRoleId: PropTypes.func.isRequired,
};
