import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import FinancialList from './list';
import AppointmentView from '../Appointments/view';

export default function Finances() {
  return (
    <Suspense fallback="loading">
      <Switch>
        <Route path="/appointments/:id/view" component={AppointmentView} />
        <Route path="/financial" exact component={FinancialList} />
      </Switch>
    </Suspense>
  );
}
