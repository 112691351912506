import axios from 'axios';

const getBaseURL = () => process.env.REACT_APP_API_URL;

export const APP_URL = getBaseURL();

const api = axios.create({
  baseURL: APP_URL,
});

export default api;
