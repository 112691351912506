import styled from 'styled-components';

export const Container = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

  font-family: Roboto, sans-serif;
  display: flex;
  padding: 30px 60px;
  flex-direction: column;
  align-items: center;

  h1 {
    width: 20%;

    img {
      width: 100%;
    }
  }

  h2 {
    font-size: 24px;
    line-height: 135%;
    font-weight: 700;
  }

  p {
    font-size: 18px;
    line-height: 170%;
  }
`;
