import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@rocketseat/unform';
import { Button } from 'semantic-ui-react';
import * as Yup from 'yup';

import { signInRequest } from '../../store/modules/auth/actions';
import Input from '../../components/input';
import Checkbox from '../../components/checkbox';
import { Background, Container } from './styles';
import logo from '../../assets/homeup_horizontal_logo.svg';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail obrigatório'),
  password: Yup.string().required('Senha obrigatória'),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  const handleSubmit = ({ email, password }) => {
    dispatch(signInRequest(email, password));
  };

  return (
    <>
      <Background />
      <Container>
        <div className="container-header">
          <h1>
            <img src={logo} alt="HomeUp" />
          </h1>
          <h2>Preencha com suas credenciais de acesso ou Cadastre-se</h2>
        </div>
        <Form className="ui form" schema={schema} onSubmit={handleSubmit}>
          <Input
            label="E-mail"
            name="email"
            type="text"
            placeholder="nome@dominio.com"
          />
          <Input
            label="Senha"
            name="password"
            type="password"
            placeholder="Mínimo de 8 dígitos"
          />
          <div className="space-between-h">
            <Checkbox name="rememberMe" label="Lembrar de mim" />
            <Link to="/resetPassword">Esqueci minha senha</Link>
          </div>
          <Button type="submit" color="red">
            {loading ? 'Carregando...' : 'Entrar'}
          </Button>
          {/* <Link to="/register" className="ui button default">
            Quero me cadastrar
          </Link> */}
        </Form>
      </Container>
    </>
  );
}
