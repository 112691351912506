import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Modal, Button } from 'semantic-ui-react';
import { Form } from '@rocketseat/unform';

import Select from '~/components/select';

const schema = Yup.object().shape({
  specialty_id: Yup.number().required('obrigatório'),
});

export default function SpecialtyModal({
  onSubmit,
  onClose,
  opened,
  specialtyType,
  specialties,
}) {
  const getSpecialtyOptions = () => {
    return specialties
      .filter(s => s.type === specialtyType)
      .map(s => ({
        key: s.id,
        value: s.id,
        text: s.name,
      }));
  };
  return (
    <Modal open={opened}>
      <Modal.Header>Selecione a especialidade desejada</Modal.Header>
      <Modal.Content>
        <Form className="ui form" onSubmit={onSubmit} schema={schema}>
          <Select
            name="specialty_id"
            label="Especialidade"
            placeholder="Selecione a especialidade"
            options={getSpecialtyOptions()}
          />
          <Button color="red" type="submit">
            Continuar o agendamento
          </Button>
          <Button type="button" onClick={() => onClose()}>
            Cancelar
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
}

SpecialtyModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  specialtyType: PropTypes.string,
  specialties: PropTypes.array,
};

SpecialtyModal.defaultProps = {
  specialtyType: 'EXAM',
  specialties: [],
};
