export const scheduleAvailabilityRequest = ({ specialtyId, providerId }) => ({
  type: '@scheduling/SCHEDULE_AVAILABILITY_REQUEST',
  payload: { specialtyId, providerId },
});

export const scheduleAvailabilityAndResources = ({ specialtyId, providerId }) => ({
  type: '@scheduling/SCHEDULE_AVAILABILITY_RESOURCES',
  payload: { specialtyId, providerId },
});

export const scheduleAvailabilitySuccess = payload => ({
  type: '@scheduling/SCHEDULE_AVAILABILITY_SUCCESS',
  payload,
});


export const scheduleAvailabilityAndResourcesSuccess = payload => ({
  type: '@scheduling/SCHEDULE_AVAILABILITY_RESOURCES_SUCCESS',
  payload,
});

export const scheduleFailure = () => ({
  type: '@scheduling/FAILURE',
});

export const createAppointmentRequest = payload => ({
  type: '@scheduling/CREATE_REQUEST',
  payload,
});

export const createAppointmentSuccess = () => ({
  type: '@scheduling/CREATE_SUCCESS',
});

export const loadAppointments = () => ({
  type: '@scheduling/LOAD_APPOINTMENTS',
});

export const appoitmentsLoaded = appoitments => ({
  type: '@scheduling/APPOINTMENTS_LOADED',
  payload: appoitments,
});

export const cancelAppointment = appointmentId => ({
  type: '@scheduling/CANCEL_APPOINTMENT',
  payload: appointmentId,
});
