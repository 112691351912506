import styled from 'styled-components';

export const Paid = styled.div`
    color: #709179;
    text-transform: capitalize;
    font-weight: 800;
`;

export const GoBack = styled.div`
    color: #202621;
    text-transform: capitalize;
    font-weight: 800;
`;

export const Sending = styled.div`
    color: #a26163;
    text-transform: capitalize;
    font-weight: 800;
`;

export const Created = styled.div`
    text-transform: capitalize;
    font-weight: 800;
`;