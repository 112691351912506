import produce from 'immer';

const INITIAL_STATE = {
  scheduleAvailability: [],
  availabilitiesAndResources: [],
  appointments: [],
  loading: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  if (type === '@auth/SIGN_OUT') return INITIAL_STATE;

  return produce(state, draft => {
    switch (type) {
      case '@scheduling/SCHEDULE_AVAILABILITY_REQUEST':
        draft.loading = true;
        break;
      case '@scheduling/SCHEDULE_AVAILABILITY_SUCCESS':
        draft.scheduleAvailability = payload;
        draft.loading = false;
        break;
      case '@scheduling/SCHEDULE_AVAILABILITY_RESOURCES':
        draft.scheduleAvailability = payload;
        draft.loading = false;
        break;
      case '@scheduling/SCHEDULE_AVAILABILITY_RESOURCES_SUCCESS':
        draft.availabilitiesAndResources = payload;
        draft.loading = false;
        break;
      case '@schedulling/CREATE_REQUEST':
        draft.loading = true;
        break;
      case '@scheduling/CREATE_SUCCESS':
        draft.loading = false;
        break;
      case '@scheduling/APPOINTMENTS_LOADED':
        draft.appointments = payload;
        break;
      case '@scheduling/FAILURE':
        draft.loading = false;
        break;
      default:
    }
  });
};
