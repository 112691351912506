import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import i18n from '~/i18n';
import { signOut } from '../modules/auth/actions';

function formatErrors(error) {
  if (typeof error === 'string') {
    return i18n.t(error);
  }

  if (typeof error === 'object') {
    const keys = Object.keys(error);
    return keys.map((k) => formatErrors(error[k])).join('<br />');
  }

  return '';
}

function* errorHandler(response, withoutReload) {
  if (response.status === 401) {
    toast.warn('Sessão expirada');
    yield put(signOut());
  } else if (response.status === 403) {
    toast.error('Forbidden');
    yield put(signOut());
  } else if (response.status < 500) {
    toast.error(formatErrors(response.data));
    if (!withoutReload) {
      setTimeout(() => {
        window.location.reload(false);
      }, 2500);
    }
    throw new Error(response.data || 'Unknown error');
  }
}

export default function* (request, payload, withoutReload) {
  try {
    const response = yield call(request, payload);

    if (response.status > 204 && response.status < 200) {
      yield call(errorHandler, response);
      return false;
    }

    return response.data;
  } catch (err) {
    yield call(errorHandler, err.response, withoutReload);
  }
  return null;
}
