import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import requestHandler from '../../middlewares/requestHandler';
import userService from '../../../services/user';
import history from '../../../services/history';

import { signInRequest } from '../auth/actions';
import { registerSuccess } from './actions';

function* register({ payload }) {
  try {
    const response = yield call(requestHandler, userService.register, payload);
    yield put(signInRequest(payload.email, payload.password));
    yield put(registerSuccess(response));
    history.push('/dashboard');
  } catch (err) {
    toast.error(err);
  }
}

export default all([takeLatest('@user/REGISTER_REQUEST', register)]);
