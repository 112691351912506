import React from 'react';
import { useSelector } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';

export default function() {
  const loading = useSelector(state => state.global.loading);

  if (loading > 0) {
    return (
      <Dimmer page active inverted>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }

  return <></>;
}
