import produce from 'immer';

const INITIAL_STATE = {
  paymentList: [],
  isEditing: false,
  loading: false,
  currentPayment: null,
  pagination: {
    page: 1,
    name: null,
    status: null,
    search: null,
    created_at: null,
  },
};

export default (state = INITIAL_STATE, { type, payload }) => {
  if (type === '@auth/SIGN_OUT') return INITIAL_STATE;

  return produce(state, draft => {
    switch (type) {
      case 'payments/POST_SUCCESS': {
        draft.currentPayment = payload;
        draft.loading = false;
        break;
      }
      case '@payments/LIST_SUCCESS': {
        draft.paymentList = payload.paymentList;
        draft.currentPayment = null;
        draft.pagination = payload.pagination;
        break;
      }
      case '@payments/INFO_SUCCESS': {
        draft.paymentInfo = payload.paymentInfo;
        draft.loading = false;
        break;
      }
      case '@payments/GET_SUCCESS': {
        draft.currentPayment = payload;
        break;
      }
      case '@payments/SET_EDITING': {
        draft.isEditing = payload;
        break;
      }
      case '@payments/FAILED': {
        draft.loading = false;
        break;
      }
      default: 
    }
  });
};
