import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { Button, Table, Label, Checkbox, Pagination } from 'semantic-ui-react';
import { FaRegTrashAlt, FaCheck, FaTimes } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';

import {
  specialtyListRequest,
  bulkActivateSpecialtyRequest,
  bulkInactivateSpecialtyRequest,
  bulkDestroySpecialtyRequest,
} from '../../store/modules/specialties/actions';
import { setBreadcrumb } from '../../store/modules/global/actions';
import Filter from './components/filter';

import { ActionsContainer } from './styles';

export default function Specialties() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRecords, setSelectedRecords] = useState({});
  const [can, setCan] = useState({
    delete: false,
    activate: false,
    inactivate: false,
  });
  const { pagination, specialtyList } = useSelector(state => state.specialties);

  const canBulkActivate = () => {
    const keys = Object.keys(selectedRecords);
    const hasActiveRecord = keys.find(
      key => selectedRecords[key].status === 'ACTV'
    );
    return !hasActiveRecord && keys.length;
  };

  const canBulkInactivate = () => {
    const keys = Object.keys(selectedRecords);
    const hasInactiveRecord = keys.find(
      key => selectedRecords[key].status === 'IACT'
    );
    return !hasInactiveRecord && keys.length;
  };

  useEffect(() => {
    dispatch(setBreadcrumb('SPECIALTIES'));
    dispatch(specialtyListRequest({ page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    setCan({
      delete: !!Object.keys(selectedRecords).length,
      activate: canBulkActivate(),
      inactivate: canBulkInactivate(),
    });
  }, [selectedRecords]);

  useEffect(() => {
    setSelectedRecords({});
  }, [specialtyList]);

  const toggleSelectRecord = specialty => {
    if (selectedRecords[specialty.id]) {
      const newObject = { ...selectedRecords };
      delete newObject[specialty.id];
      setSelectedRecords(newObject);
    } else {
      setSelectedRecords({ ...selectedRecords, [specialty.id]: specialty });
    }
  };

  const toggleAllRecords = () => {
    const keys = Object.keys(selectedRecords);
    if (keys.length === specialtyList.length) {
      setSelectedRecords({});
    } else {
      setSelectedRecords(
        specialtyList.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {})
      );
    }
  };
  const renderSpeciality = (value) => {
    let option
    if(value === 'SURG') option = 'Cirurgia'
    else if(value == 'EXAM') option = 'Exame'
    else option = 'Consulta Médica'
    return option
  }

  const onPageChange = (e, { activePage: page }) => {
    dispatch(specialtyListRequest({ ...pagination, page }));
  };

  const onFilterSubmit = data => {
    dispatch(specialtyListRequest({ ...pagination, ...data, page: 1 }));
  };

  function renderSpecialties() {
    return specialtyList.map(specialty => (
      <Table.Row key={specialty.id} selected={selectedRecords[specialty.id]}>
        <Table.Cell>
          <Checkbox
            checked={!!selectedRecords[specialty.id]}
            onClick={() => toggleSelectRecord(specialty)}
          />
        </Table.Cell>
        <Table.Cell>{specialty.id}</Table.Cell>
        <Table.Cell>{specialty.name}</Table.Cell>
        <Table.Cell>{renderSpeciality(specialty.type)}</Table.Cell>
        <Table.Cell>
          {format(parseISO(specialty.created_at), 'dd/MM/yyyy hh:mm:ss')}
        </Table.Cell>
        <Table.Cell>
          {specialty.enabled ? (
            <Label basic color="green">
              Ativo
            </Label>
          ) : (
            <Label basic color="red">
              Inativo
            </Label>
          )}
        </Table.Cell>
        <Table.Cell>
          <Link to={`/specialties/${specialty.id}/edit`} className="table-link">
            visualizar
          </Link>
        </Table.Cell>
      </Table.Row>
    ));
  }

  return (
    <div className="content">
      <h1>Especialidades</h1>
      <ActionsContainer>
        <Link to="/specialties/new" className="ui icon red button">
          <MdAdd />
          Novo
        </Link>
        <Button
          color="red"
          className="icon"
          disabled={!can.delete}
          onClick={() =>
            dispatch(
              bulkDestroySpecialtyRequest(Object.values(selectedRecords))
            )
          }
        >
          <FaRegTrashAlt />
          Apagar
        </Button>
        <Button
          default
          className="icon"
          disabled={!can.activate}
          onClick={() =>
            dispatch(
              bulkActivateSpecialtyRequest(Object.values(selectedRecords))
            )
          }
        >
          <FaCheck />
          Ativar
        </Button>
        <Button
          default
          className="icon"
          disabled={!can.inactivate}
          onClick={() =>
            dispatch(
              bulkInactivateSpecialtyRequest(Object.values(selectedRecords))
            )
          }
        >
          <FaTimes />
          Desativar
        </Button>
      </ActionsContainer>
      <div className="buttons" />
      <div className="search" />
      <Filter initialData={pagination} onSubmit={onFilterSubmit} />
      {!!specialtyList.length && (
        <>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    checked={
                      specialtyList.length ===
                      Object.keys(selectedRecords).length
                    }
                    indeterminate={
                      Object.keys(selectedRecords).length > 0 &&
                      Object.keys(selectedRecords).length < specialtyList.length
                    }
                    onClick={() => toggleAllRecords()}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell># ID</Table.HeaderCell>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>Especialidade</Table.HeaderCell>
                <Table.HeaderCell>Criado em</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Ações</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{renderSpecialties()}</Table.Body>
          </Table>
          <Pagination
            totalPages={pagination.pages}
            defaultActivePage={pagination.page}
            onPageChange={onPageChange}
            firstItem={null}
            lastItem={null}
            pointing
            secondary
          />
        </>
      )}
    </div>
  );
}
