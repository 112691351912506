import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@rocketseat/unform';
import { Button } from 'semantic-ui-react';
import { verifyAccess } from '../../../store/modules/auth/actions';
import logo from '../../../assets/homeup_horizontal_logo.svg';
import Input from '../../../components/input';

export default function ResetPasswordRequest(props) {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);
  const [code, setValue] = useState(null);
  const id = props.match.params.id;

  const handleSubmit = (e) => {
    e.preventDefault();
    const response = {id, code};
    dispatch(verifyAccess(response));
  };

  return (
    <>
      <div className="container-header">
        <h1>
          <img src={logo} alt="Instituto Vertus de Mediação" />
        </h1>
        <h2>Para ativar sua conta, informe o código encaminhado no e-mail cadastrado.</h2>
      </div>
      <Form className="ui form">
        <Input
          label="Código de acesso"
          name="code"
          value={code}
          onChange={(e) => setValue(e.target.value)}
          type="number"
          placeholder="303012"
        />
        <Button onClick={handleSubmit} color="red">
          {loading ? 'Carregando...' : 'Confirmar'}
        </Button>
        <Link to="/">voltar ao login</Link>
      </Form>
    </>
  );
}
