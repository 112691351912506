import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Select from '~/components/select';
import { Form } from '@rocketseat/unform';
import { Form as SForm, Button, Table } from 'semantic-ui-react';
import requestHandler from '~/services/requestHandler';
import usersService from '~/services/users';
import { Modal } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { unionBy } from 'lodash';
import { userListRequest } from '../../store/modules/users/actions';
import { setLoading } from '~/store/modules/global/actions';
import { loadPerformanceReport } from '~/store/modules/appointments/actions';
import { setBreadcrumb } from '~/store/modules/global/actions';
import { renderAppointmentStatus } from '~/utils/statuses';
import { PROFILE } from '~/utils/constants';
import { MdSearch } from 'react-icons/md';
import { ReactComponent as IconTotalBilling } from './components/IconTotalBilling.svg';
import { ReactComponent as IconFinalized } from './components/IconFinalized.svg';
import { ReactComponent as IconMissing } from './components/IconMissing.svg';
import './style.css';

export const financeShow = (x) => {
  return Number.parseFloat(x).toFixed(2)
}

export const calculateFinance = (arr) => {
  let estimationAmount = 0;
  let refundAmount = 0;
  for (let i = 0; i < arr.length; i++) {
    estimationAmount += parseFloat(arr[i].estimation_amount !== null ? arr[i].estimation_amount : 0);
    refundAmount += parseFloat(arr[i].refund_amount !== null ? arr[i].refund_amount : 0);
  }
  return { estimationAmount, refundAmount };
}

export default function ReportView() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { profile } = useSelector((state) => state.user);
  let { appointmentList } = useSelector(
    (state) => state.appointments
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [selected, setUserList] = useState('');
  const [list, setSearchUserList] = useState([]);
  let { userList } = useSelector(state => state.users);
  userList.map((s) =>
  ({
    id: s.id,
    name: s.name,
    email: s.email,
  }));

  if(selected) {
    appointmentList = appointmentList.filter((appoint) => appoint.provider_id === selected);
  }

  const Filtered = _.groupBy(appointmentList, 'status');

  Filtered.PROC = appointmentList.filter((appointment) => appointment.status === 'PROC' || appointment.status === 'PERF' || appointment.status === 'SGPF');
  Filtered.RQRF = appointmentList.filter((appointment) => appointment.status === 'RQRF' || appointment.status === 'RBMG' );

  ['PERF', 'SGPF', 'RBMG'].forEach((status) => {
    delete Filtered[status];
  });

  useEffect(() => {
   dispatch(userListRequest({especific_request: 4}));
  }, [dispatch])

  const handleSearchChange = (_e, { searchQuery }) =>  setSearchQuery(searchQuery);

  const updateSearchList = () => {
    if (searchQuery.length >= 3) {
      requestHandler(usersService.listUsers, {
        partialStr: searchQuery,
      })
        .then((result) =>
          setSearchUserList(
            unionBy(list, result, 'id').filter(
              (x) =>
                [PROFILE.DOCTOR, PROFILE.ASSDOCTOR].includes(x.roleId)
            )
          )
        )
        .finally(() => dispatch(setLoading(false)));
    } else {
      setSearchUserList(list.filter((u) => selected.includes(u.id)));
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    updateSearchList();
  }, [searchQuery])

  const handleSearch = () => {
    setOpened(false);
  }

  const setHandleTrue = (e) => {
    e.preventDefault();
    setOpened(true);
  }
  const handleUserListChange = (_e, { value }) => {
    setUserList(value);
  };

  const userOptions = () => {
    const tmp = userList.map((s) => ({
      key: s.id,
      value: s.id,
      text: `${s.name} <${s.email}>`,
    }));
    return tmp
  };

  useEffect(() => {
    dispatch(setBreadcrumb('REPORT'));
    dispatch(loadPerformanceReport({ startDate, endDate }));
  }, [dispatch, endDate, startDate]);

  const onHandleSearch = () => {
    dispatch(loadPerformanceReport({ status: null, startDate, endDate }));
  }

  return (
    <div className="content">
      <h1>Relatórios de Performance</h1>
      <div className="actions">
        <Form className="ui form">
          <SForm.Group style={{ marginTop: 35, marginBottom: 35 }}>
            <SForm.Field className="d-flex" >
              <label className="mt-auto mb-auto mr-10">Inicio: </label>
              <DatePicker name="started_at"
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={date => setStartDate(date)}
              />
            </SForm.Field>
            <SForm.Field className="d-flex" >
              <label className="mt-auto mb-auto mr-10">Até: </label>
              <DatePicker name="finished_at"
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={date => setEndDate(date)}
              />
            </SForm.Field>
            <Button
              color="red"
              icon onClick={onHandleSearch}
            >
              <MdSearch />Buscar
            </Button>
          </SForm.Group>
          {([PROFILE.FINADMIN, PROFILE.ADMIN].includes(profile.roleId))
            ?
            <div style={{float: 'right'}}>
              <Button
                style={{margin: '10px'}}
                color="red"
                icon
                onClick={setHandleTrue}
              >
                Filtrar
              </Button>
            </div>
            : null
          }

          <Divider />
        </Form>
        <Modal size="tiny" open={opened} closeIcon onClose={() => setOpened(false)}>
          <Modal.Header>Buscar Colaborador</Modal.Header>
          <Modal.Content>
            <p>
              Busque um colaborador para listar os relátórios.
            </p>
            <Form className="ui form" onSubmit={handleSearch}>
              <Select
                name="providers"
                label="Profissionais"
                options={userOptions()}
                value={selected}
                onChange={handleUserListChange}
                searchQuery={searchQuery}
                onSearchChange={handleSearchChange}
              />
              <Divider />
              <Button type="button" onClick={() => {
                setOpened(false);
                setUserList('');
              }}>
                Cancelar
              </Button>
              <Button type="submit" color="red">
                Confirmar
              </Button>
            </Form>
          </Modal.Content>
        </Modal>
      </div>
      <div className="status-icon-board">
        <div className="d-flex">
          <div className="status-icon">
            <IconTotalBilling className="m-auto" />
          </div>
          <div className="pl-10">
            <p className="status-brand">Faturamento Total</p>
            <h3 className="m-0">R$ {calculateFinance(appointmentList).estimationAmount.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h3>
          </div>
        </div>

        <div className="d-flex">
          <div className="status-icon">
            <IconMissing className="m-auto" />
          </div>
          <div className="pl-10">
            <p className="status-brand">Finalizados</p>
            <h3 className="m-0">R$ {calculateFinance(appointmentList).refundAmount.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h3>
          </div>
        </div>

        <div className="d-flex">
          <div className="status-icon">
            <IconFinalized className="m-auto" />
          </div>
          <div className="pl-10">
            <p className="status-brand">Falta Receber</p>
            <h3 className="m-0">R$ {(calculateFinance(appointmentList).estimationAmount - calculateFinance(appointmentList).refundAmount).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h3>
          </div>
        </div>
        <Divider />
      </div>
      {appointmentList.length > 0
        ?
        (<>
        <Table striped>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Qtd de Atendimentos</Table.HeaderCell>
              <Table.HeaderCell>% do Total</Table.HeaderCell>
              <Table.HeaderCell>Ações</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
          {
            [
              'PIRQ', // Necessita prévia
              'AWPR', // Aguardando Prévia
              'PIFN', // Prévia Concluída
              'RQRF', // Reembolso solicitado + Falta reembolso
              'PNDC', // Pendência
              'AWTR', // Aguardando Repasse
              'PROC', // Procedimentos realizados
              'CANC', // Cancelada
              'DONE', // Finalizada
            ].map((status, index) => {
              const label = renderAppointmentStatus(status, t);
              const amount = (Filtered[status] || []).length;
              const perc = ((Number( amount /appointmentList.length) * 100).toFixed(2))

              return (
                <Table.Row key={index} textAlign="center">
                  <Table.Cell>{label}</Table.Cell>
                  <Table.Cell>{(amount)}</Table.Cell>
                  <Table.Cell> {perc}
                  </Table.Cell>
                  <Table.Cell>
                    <Button.Group icon>
                      <Link
                        to={{
                          pathname: `/report/${status}/view/${selected}`,
                          state: {
                            startDate: startDate,
                            endDate: endDate
                          }
                        }}
                        className="table-link"
                      >
                        Ver Todos
                      </Link>
                    </Button.Group>
                  </Table.Cell>
                </Table.Row>
              );
            })
          }
          </Table.Body>
        </Table>
        <div>
          <h2 style={{ textAlign: "center" }}>{appointmentList.length} TOTAL</h2>
        </div>
      </>) : null }
    </div >
  );
}
