import api from './api';

const listUsers = (params) => {
  return api.get('users', { params });
};
const listAllUsers = (params) => {
  return api.get('users/all', { params });
};
const getUsersList = (params) => {
  return api.get('users/list', { params });
};
const getUser = (id) => {
  return api.get(`users/${id}`);
};
const getCurrentUser = (id) => {
  return api.get(`users/app/${id}`);
};
const createUser = (data) => {
  return api.post('users', data);
};

const updateUser = ({ id, data }) => {
  return api.put(`users/${id}`, data);
};

const toggleStatus = (user) => {
  let data;
  if (user.flag) {
    data = {
      specialties: user.specialties,
    };
  } else {
    data = {
      status: user.status === 'ACTV' ? 'IACT' : 'ACTV',
      specialties: user.specialties,
    };
  }
  return api.put(`users/${user.id}`, data);
};

const getMediators = () => {
  return api.get(`users?roleId=2&all=1`);
};

const destroy = (user) => {
  return api.delete(`users/${user.id}`);
};

const loadParticipants = (payload) => {
  const { userId, ...params } = payload;
  return api.get(`users/${userId}/view`, { params }); // /participations
};

const uploadAvatar = (payload) => {
  const { userId, avatar } = payload;
  const data = new FormData();
  data.append('avatar', avatar);
  return api.post(`users/${userId}/avatar`, data);
};

const uploadFile = ({ id, files, fileType }) => {
  const data = new FormData();
  for (let i = 0; i < files.length; i++) {
    data.append('files', files[i]);
  }

  return api.post(`users/${id}/files?fileType=${fileType}`, data);
};

const destroyFile = ({ user_id, id }) => {
  return api.delete(`users/${user_id}/files/${id}`);
};

export default {
  listUsers,
  listAllUsers,
  getUsersList,
  getUser,
  createUser,
  updateUser,
  toggleStatus,
  getMediators,
  destroy,
  getCurrentUser,
  loadParticipants,
  uploadAvatar,
  destroyFile,
  uploadFile,
};
