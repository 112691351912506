import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { parseISO, format, isBefore, subHours } from 'date-fns';
import { get } from 'lodash';
import { cancelAppointment } from '~/store/modules/scheduling/actions';
import { Appointment } from '../styles';
import { useTranslation } from 'react-i18next';
import { renderAppointmentStatus } from '~/utils/statuses';
import { PROFILE } from '~/utils/constants';

export default function Appointments() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { appointments } = useSelector(state => state.scheduling);
  const onCancelAppointment = appointmentId => {
    dispatch(cancelAppointment(appointmentId));
  };

  const canCancelAppointment = appointment => {
    const start = parseISO(appointment.start_at);
    if (appointment.status === 'CANC') return false;
    return isBefore(subHours(new Date(), 1), start);
  };

  return (
    <>
      <h3>Agendamentos</h3>
      {appointments.map(appointment => {
        const where = get(appointment, 'where', 'N/D');
        return (
          <Appointment>
            <div className="appointment-line">
              <strong>Agendado para:</strong>
              <span>
                {format(parseISO(appointment.start_at), 'dd/MM/yyyy HH:mm:ss')}
              </span>
            </div>
            <div className="appointment-line">
              <strong>Local:</strong>
              <span>
                {where.startsWith('http') ? (
                  <a href={where} target="_blank" rel="noopener noreferrer">
                    {where}
                  </a>
                ) : (
                    where
                  )}
              </span>
            </div>
            <div className="appointment-line">
              <strong>
                {appointment.provider.roleId === PROFILE.PROVIDER ? 'Prestador' : 'Médico'}:
              </strong>
              <span>{appointment.provider.roleId === PROFILE.PROVIDER ? get(appointment, 'provider.name', 'N/D') : 'Dr.(a)' + get(appointment, 'provider.name', 'N/D')}</span>
            </div>
            <div className="appointment-line">
              <strong>
                Status do agendamento
              </strong>
              <span>{renderAppointmentStatus(get(appointment, 'status', 'N/D'), t)}</span>
            </div>
            {canCancelAppointment(appointment) && (
              <button
                className="cancel-link"
                type="button"
                onClick={() => onCancelAppointment(appointment.id)}
              >
                Cancelar agendamento
              </button>
            )}
          </Appointment>
        );
      })}
    </>
  );
}
