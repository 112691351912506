import { toast } from 'react-toastify';

import i18n from '~/i18n';
import { store } from '~/store';
import { signOut } from '~/store/modules/auth/actions';

function formatErrors(error) {
  if (typeof error === 'string') {
    return i18n.t(error);
  }

  if (typeof error === 'object') {
    const keys = Object.keys(error);
    return keys.map(k => formatErrors(error[k])).join('<br />');
  }

  return '';
}

function errorHandler(response) {
  if (response.status === 401) {
    toast.warn('Sessão expirada');
    store.dispatch(signOut());
  } else if (response.status < 500) {
    toast.error(formatErrors(response.data));
  }
}

export default async function(request, ...payload) {
  try {
    const response = await request(...payload);

    if (response.status > 204 && response.status < 200) {
      errorHandler(response);
      return false;
    }

    return response.data;
  } catch (err) {
    await errorHandler(err.response);
  }
  return null;
}
