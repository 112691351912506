import { takeLatest, put, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import requestHandler from '../../middlewares/requestHandler';
import { setLoading } from '../global/actions';
import { checkingAccountGetLoaded, checkingAccountFailed } from './actions';
import checkingAccountService from '../../../services/checkingAccount';

function* loadCheckingAccount({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      checkingAccountService.load,
      payload,
      true
    );
    yield put(checkingAccountGetLoaded(response));
  } catch (err) {
    toast.error(err);
    yield put(checkingAccountFailed());
  }
  yield put(setLoading(false));
}

function* updateCheckingAccount({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(
      requestHandler,
      checkingAccountService.update,
      payload,
      true
    );
    yield put(checkingAccountGetLoaded(response));
  } catch (err) {
    toast.error(err);
    yield put(checkingAccountFailed());
  }
  yield put(setLoading(false));
}

export default all([
  takeLatest('@checking_accounts/GET_REQUEST', loadCheckingAccount),
  takeLatest('@checking_accounts/PUT_REQUEST', updateCheckingAccount),
]);
