import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import users from './users/sagas';
import scheduling from './scheduling/sagas';
import appointments from './appointments/sagas';
import checkingAccount from './checkingAccount/sagas';
import payments from './payments/sagas';
import files from './files/sagas';
import globals from './global/sagas';
import specialties from './specialties/sagas';

export default function* rootSaga() {
  yield all([
    auth,
    user,
    users,
    scheduling,
    appointments,
    payments,
    files,
    globals,
    specialties,
    checkingAccount,
  ]);
}
