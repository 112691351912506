import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { parseISO, formatDistanceStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Divider, Modal, Button, Input, Form, Form as SForm, Icon } from 'semantic-ui-react';
import { get } from 'lodash';
import { MdBlock, MdEdit, MdSave } from 'react-icons/md';
import requestHandler from '~/services/requestHandler';
import specialtiesService from '~/services/specialties';
import userService from '~/services/users';
import Scheduler from '~/components/scheduler';
import { getAppointmentRequest } from '../../store/modules/appointments/actions';
import { setBreadcrumb, setLoading } from '../../store/modules/global/actions';
import AppointmentActions from './components/actions';
import appointmentService from '~/services/appointments';
import StatusForm from './components/statusForm';
import Comments from './components/comments';
import Files from './components/Files';
import { Container, AppointmentData, ShadowBox, BoxStatus } from './styles';
import ConfirmationModal from './components/confirmationModal';
import { PROFILE, CHARGE_TYPES } from '~/utils/constants';
import {
  updateFinancialAmount
} from '~/store/modules/appointments/actions';
import Select from '~/components/select';
import formatCpf from '../Payments/utils/formatCpf';

const chargeTypeOpt = () => {
  return Object.keys(CHARGE_TYPES).map((key) => ({
    key,
    value: key,
    text: CHARGE_TYPES[key],
  }))
}
export default function AppointmentView(props) {
  const { currentAppointment } = useSelector((state) => state.appointments);
  const [specialList, setSpecialList] = useState([]);
  const [control, setControl] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isRpaCopied, setRpaCopied] = useState(false);
  const rpaInputRef = useRef(null);
  const [estimationAmount, setEstimationAmount] = useState(null);
  const [refundAmount, setRefundAmount] = useState(null);
  const [chargeType, setChargeType] = useState(Object.keys(CHARGE_TYPES)[0]);
  const [chargeValue, setChargeValue] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const { loading } = useSelector((state) => state.scheduling);
  const [specialities, setSpecialities] = useState(null);
  const [showValue, setShowValue] = useState(false);
  const [pressed, setPressed] = useState(true);
  const [refValue, setRefValue] = useState(false);
  const [singleDate, setStatusDate] = useState(Date.now());
  const dispatch = useDispatch();

  const identify = currentAppointment?.specialty_id ? currentAppointment.specialty_id : 'N/A';
  let special
  let typeAppointment

  if(identify) {
    special = specialList.filter((data) => data.id === identify);
    typeAppointment = special.map(item => {return item.type});
    if(String(typeAppointment) === 'MDCL') typeAppointment = 'Consulta Médica'
    else if(String(typeAppointment) === 'SURG') typeAppointment = 'Cirurgia'
    else typeAppointment = 'Exame'
  }
  let doctors = currentAppointment?.provider_appointments.map(item => item);

  const togglePass = () => {
    var x = document.getElementById("chargeValue");
    if (x.type === "password") {
      x.type = "number";
    } else {
      x.type = "password";
    }
  }

  const appointmentId = props.match.params.id;
  
  useEffect(() => {
    if(control) {
      if (!currentAppointment || currentAppointment.id !== appointmentId) {
        dispatch(getAppointmentRequest(appointmentId));
      }
      dispatch(setBreadcrumb('APPOINTMENT_DETAIL', currentAppointment));
      if (currentAppointment) {
        setControl(false);
        const estimation_amount = get(currentAppointment, 'estimation_amount');
        const refund_amount = get(currentAppointment, 'refund_amount');
        const charge_type = get(currentAppointment, 'charge_type');
        const charge_value = get(currentAppointment, 'charge_value');
        setEstimationAmount(estimation_amount);
        setRefundAmount(refund_amount);
        setChargeType(charge_type);
        setChargeValue(charge_value);
        const hash = Buffer.from(`${get(currentAppointment, 'requester.profile.id')}`).toString('base64');

        const baseUrl = window.location.href.replace(window.location.pathname, '');
        rpaInputRef.current.value = `${baseUrl}/rpa/${hash}`;
      }
      requestHandler(specialtiesService.listAll).then(result => setSpecialList(result))
      if(doctors){
        // eslint-disable-next-line react-hooks/exhaustive-deps
        doctors = doctors?.map(item => {return item.provider_id});
        let id = doctors[doctors.length-1]
        requestHandler(userService.getUser, id)
        .then((result) => {
          setSpecialities(result.specialties);
        })
      }
    }

  }, [ appointmentId, doctors, currentAppointment, setSpecialList, dispatch, control]);

  useEffect(() => {
    const getStatusDate = () => {
      requestHandler(appointmentService.getUpDate, appointmentId)
      .then((result) => {
        if(result){
          setStatusDate(result.createdAt);
        }
      });
    }
    if(appointmentId){
      getStatusDate();
    }
  }, [appointmentId])

  const birthday = get(currentAppointment, 'requester.profile.birthday');
  // const estimation_amount = get(currentAppointment, 'estimation_amount');
  // const refund_amount = get(currentAppointment, 'refund_amount');
  let age;
  if (birthday) {
    const birthdayDate = parseISO(birthday);
    if (String(birthdayDate) !== 'Invalid Date')
    age = formatDistanceStrict(new Date(), birthdayDate, {
      locale: ptBR,
      unit: 'year',
      roundingMethod: 'floor'
    });
  }

  const copyRpaLink = useCallback(() => {
    rpaInputRef.current.select();
    document.execCommand('copy');
    setRpaCopied(true);

    setTimeout(() => {
      setRpaCopied(false);
    }, 5000);
  }, []);

  const handleSubmitFinance = async () => {
    dispatch(updateFinancialAmount({
      id: currentAppointment.id,
      estimationAmount: estimationAmount === "" ? null : estimationAmount,
      refundAmount: refundAmount === "" ? null : refundAmount,
      chargeType: chargeType === "" ? null : chargeType,
      chargeValue: chargeValue === "" ? null : chargeValue,
    }));
    setEditing(false);
  }

  const eventEst = () => {
    if(currentAppointment.estimation_amount)
      return <Button style={{backgroundColor: "#c2ff61"}} onClick={() => setShowValue(!showValue)} content={"Liberado"} />
    else if(currentAppointment.status === "AWPR")
      return <Button compact onClick={() => setShowValue(!showValue)} content={"Em análise"} />
    else if(!currentAppointment.estimation_amount)
      return <Button compact onClick={() => setShowValue(!showValue)} content={"Inexistente"} />
    else
      return <Button compact onClick={() => setShowValue(!showValue)} content={"Aguardando"} />
  }

  const eventRef = () => {
    if(currentAppointment.refund_amount)
      return <Button style={{backgroundColor: "#c2ff61"}} onClick={() => setRefValue(!refValue)} content={"Atualizado"} />
    else if(currentAppointment.status === "AWPR")
      return <Button compact onClick={() => setRefValue(!refValue)} content={"Aguardando"} />
    else if(!currentAppointment.refund_amount)
      return <Button compact onClick={() => setRefValue(!refValue)} content={"Inexistente"} />
    else
      return <Button compact onClick={() => setRefValue(!refValue)} content={"Aguardando"} />
  }

  const fastRefresh = (event) => {
    window.location.reload(true);
  }

  const onSubmit = async ({ description }) => {
    dispatch(setLoading(true));
    try {
      const appointment = await appointmentService.update({
        id: currentAppointment.id,
        slot: selectedSlot,
        resource: currentAppointment.provider.timekit_resource_id,
        description,
        requesterId: currentAppointment.requester.id,
        specialtyId: currentAppointment.specialty_id,
        providerId: currentAppointment.provider_id,
        client: currentAppointment.requester,
        roleId: currentAppointment.provider.roleId,
        professionalData: currentAppointment.provider.professionalData,
      });
      if (appointment) {
        toast.success('Agendamento efetuado com sucesso');
        setModalOpen(false);
      }
    } catch (err) {
      toast.error('Não foi possível criar o agendamento');
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Container className="content">
      {currentAppointment && (
        <>
        <div style={{marginBottom: '10px'}}>
          <h1 >
            { typeAppointment ? typeAppointment : 'N/A' } # ID {currentAppointment && currentAppointment.id}
          </h1>
        </div>
          <Grid columns="2" style={{height:"10%", marginBottom: "10px"}}>
            <div style={{width:'50%'}}>
              <Grid.Column >
                <AppointmentActions currentAppointment={currentAppointment} specialities={specialities} specialList={specialList}/>
              </Grid.Column>
              <BoxStatus>
                <StatusForm currentAppointment={currentAppointment} special={special} singleDate={singleDate}/>
              </BoxStatus>
            </div>

            <div  className='container' style={{width:'50%'}}>
              <Grid.Column >
                <div style={{ display: "flex", marginTop: '10px' }}>
                  <h3 style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "0.8rem"
                  }}>Financeiro
                  </h3>
                </div>

                <Grid.Column>
                  <Form>
                  <Form.Group style={{ display: 'flex', justifyContent: 'inline'}}>
                      <Form.Field style={{display:'flex', justifyContent:'space-between'}}>
                        <label style={{
                          marginTop: 'auto',
                          marginBottom: '10px'
                        }}>Tipo de<p/>Cobrança&nbsp;&nbsp;&nbsp;</label>
                        <SForm.Group
                          style={{
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Select
                            compact
                            id="chargeType"
                            name="chargeType"
                            disabled={!isEditing}
                            options={chargeTypeOpt()}
                            value={chargeType}
                            onChange={(_, { value }) => {
                              if (value === 'by_preview'){
                                setChargeValue(null);
                              }
                              setChargeType(value)
                            }}
                          />
                          {!isEditing ? (
                              <Button icon onClick={() => setEditing(true)}>
                                <MdEdit />
                              </Button>
                          ) : (
                            <>
                              <Button icon onClick={(e) => {setEditing(false); setShowValue(!showValue); setRefValue(!refValue); fastRefresh(e);}}>
                                <MdBlock />
                              </Button>
                              <Button icon onClick={(e) => {handleSubmitFinance(); setShowValue(!showValue); setRefValue(!refValue);}}>
                                <MdSave />
                              </Button>
                            </>
                          )}
                        </SForm.Group>
                      </Form.Field>

                      { chargeType === 'by_value' &&
                      <Form.Field style={{display:'flex', justifyContent:'space-between'}} >
                        <label style={{
                          marginRight: '14px',
                          marginTop: '18px',
                          marginBottom: 'auto'
                          }}>Valor <p/>Cobrado
                        </label>
                        <SForm.Group
                          style={{
                            display: 'flex',
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <Input
                            style={{width:'120px'}}
                            type="password"
                            placeholder=''
                            id="chargeValue"
                            name="chargeValue"
                            disabled={!isEditing}
                            value={chargeValue}
                            onChange={(ev) => setChargeValue(ev.target.value)}
                          />
                          <Button
                            style={{padding: '13px', marginLeft: '5px'}}
                            icon
                            onClick={() => {togglePass(); setPressed(!pressed)}}
                          >
                            {pressed
                            ?
                              <Icon name='eye' />
                            :
                              <Icon name='low vision' />
                            }
                          </Button>
                        </SForm.Group>
                      </Form.Field>}
                    </Form.Group>

                    <Form.Group>
                      <Form.Field
                        style={{display:'flex', justifyContent:'space-between'}}
                      >
                        <label style={{
                          paddingTop: "15px",
                          marginTop: "auto",
                          marginRight: '10px',
                          marginBottom: "auto",
                        }}>Valor Prévia</label>
                        {showValue ?
                          <Input type="number"
                            id="estimationAmount"
                            name="estimationAmount"
                            disabled={!isEditing}
                            value={estimationAmount}
                            onChange={(ev) => setEstimationAmount(ev.target.value)}
                          />
                        : eventEst()
                        }

                      </Form.Field>
                      <label style={{width:"80px"}} />
                      <Form.Field
                        style={{display:'flex', justifyContent:'space-between'}}
                      >
                        <label style={{
                          paddingTop: "15px",
                          marginTop: "auto",
                          marginRight: '10px',
                          marginBottom: "auto"
                        }}>Valor Reembolso</label>

                        {refValue ?
                          <Input type="number"
                            placeholder=''
                            id="refundAmount"
                            name="refundAmount"
                            disabled={!isEditing}
                            value={refundAmount}
                            onChange={(ev) => setRefundAmount(ev.target.value)}
                          />
                        : eventRef()
                        }
                      </Form.Field>

                    </Form.Group>
                  </Form>
                </Grid.Column>
                <Divider />
              </Grid.Column>
            </div>
          </Grid>
          <Grid columns="2" style={{height:"90%"}}>
            <Grid.Column>
            <div className='container' style={{backgroundColor:'#fafafa', opacity:0.9, borderRadius:'10px'}}>
              <AppointmentData>
                <h4 style={{margin:'20px'}}>Dados do paciente:</h4>
                <ShadowBox style={{backgroundColor:'white', margin:'10px'}}>
                  <p><strong>Nome completo: </strong>  {get(currentAppointment, 'requester.name')}</p>
                  <p><strong>CPF: </strong>  {formatCpf(get(currentAppointment, 'requester.profile.cpf'))}</p>
                  <p><strong>Idade: </strong>{age ? age : 'N/A'}</p>
                </ShadowBox>
              </AppointmentData>

              <AppointmentData>
                <h4 style={{margin:'10px'}}>Dados do plano:</h4>
                <ShadowBox style={{backgroundColor:'white', margin:'10px'}}>
                  <p><strong>Número: </strong> {get(currentAppointment,'requester.profile.insurance_account')} </p>
                  <p><strong>Tipo (quando houver): </strong> {get(currentAppointment, 'requester.profile.insurance_type')} </p>
                  <p><strong>Nome: </strong> {get(currentAppointment, 'requester.profile.insurer_name')} </p>
                  <p><strong>Link formulário RPA:</strong> <input ref={rpaInputRef} style={{ border: 0, width: '250px' }} />
                    <Button
                      size="tiny"
                      disabled={isRpaCopied}
                      onClick={copyRpaLink}
                    >
                      {isRpaCopied ? 'Copiado!' : 'Copiar link RPA'}
                    </Button>
                </p>
                </ShadowBox>
              </AppointmentData>
              <AppointmentData>
                <h4 style={{margin:'10px'}}>Médico(s)/Técnico(s) Responsáveis:</h4>
                <ShadowBox style={{backgroundColor:'white', margin:'10px'}}>
                  <dd>
                    <ul>
                      <li key={get(currentAppointment, 'provider.id')}> <strong> Dr.(a) </strong>
                        {get(currentAppointment, 'provider.roleId') ===
                          PROFILE.DOCTOR
                          ? ` ${currentAppointment.provider.name};`
                          : `${currentAppointment.provider.name};`}
                      </li>
                      {get(currentAppointment, 'provider_appointments')
                        .filter(
                          (provider) =>
                            get(provider, 'user.id') !==
                            get(currentAppointment, 'provider.id')
                        )
                        .map((provider) => (
                         <li key={provider.user.id}>
                            {provider.user.role_id === PROFILE.DOCTOR ||
                            provider.user.role_id === PROFILE.ASSDOCTOR
                              ?  <strong>Dr.(a) </strong>
                              : ''}
                            {provider.user.name};
                          </li>
                        ))}

                    </ul>
                  </dd>
                  <p><br></br><strong>Procedimentos: </strong>{special.length ? special[0].name : 'N/A'}</p>
                </ShadowBox>
                <br></br>
              </AppointmentData>
            </div>
            </Grid.Column>
            <Grid.Column  style={{marginTop: '65px'}}>
              <Comments
                comments={currentAppointment.comments}
                appointment_id={currentAppointment.id}
              />
            </Grid.Column>
          </Grid>
          <Files
            appointment={currentAppointment}
            files={currentAppointment.files}
          />
          <Modal open={modalOpen} closeIcon onClose={() => setModalOpen(false)}>
            <Modal.Header>Escolha um horário disponível</Modal.Header>
            <Modal.Content>
              <Scheduler
                providerId={currentAppointment.provider_id}
                specialtyId={currentAppointment.specialty_id}
                selectedSlot={selectedSlot}
                onSlotSelect={(slot) => setSelectedSlot(slot)}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button default onClick={() => setModalOpen(false)}>
                Cancelar
              </Button>
              {selectedSlot && (
                <ConfirmationModal
                  patient={currentAppointment.requester.id}
                  loading={!selectedSlot || loading}
                  selectedSlot={selectedSlot}
                  onSubmit={onSubmit}
                  specialtyType={currentAppointment.specialty.type}
                />
              )}
            </Modal.Actions>
          </Modal>
        </>
      )
      }
    </Container >
  );
}

AppointmentView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
