import styled from 'styled-components';

export const Container = styled.div`
  input {
    display: none;
  }

  .file-item {
    display: flex;
    align-items: center;
    line-height: 210%;

    svg {
      margin-right: 5px;
    }
  }
`;
