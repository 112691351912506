import React, { useState, useEffect } from 'react';

import Input from '~/components/input';
import Select from '~/components/select';
import { Checkbox } from 'semantic-ui-react';
import requestHandler from '~/services/requestHandler';
import operatorService from '~/services/operators'

export default function HealthInfo({ inputFieldPrefix, profile = {} }) {
  const inputNamePrefix = inputFieldPrefix || 'personalInfo';
  const [disableOptions, setDisableOptions] = useState(false);
  const [disableInsuranceOpt, setDisableInsuranceOpt] = useState(true);
  const [insurerNameOpt, setInsurerNameOpt] = useState([])
  const [amilOpt, setAmilOpt] = useState([])
  const [notredameOpt, setNotredameOpt] = useState([])
  const [insuranceOpt, setInsuranceOpt] = useState([]);
  const [insurerName, setInsurerName] = useState(
    (profile && profile.insurer_name) || ''
  );
  const [insurerType, setInsurerType] = useState(
    (profile && profile.insurer_type) || ''
  );
  const [placeHolder, setPlaceHolder] = useState('Selecione o tipo do plano');

  const onOptionChange = (event, value) => {
    if (event) {
      event.preventDefault();
    }
    switch (value) {
      case 'AMIL':
        setDisableInsuranceOpt(false);
        setInsuranceOpt(amilOpt);
        break;
      case 'NOTREDAME INTERMÉDICA':
        setDisableInsuranceOpt(true);
        setInsuranceOpt(notredameOpt);
        break;
      default:
        setDefaultCases();
        break;
    }
    setInsurerName(value);
  };

  const handleChange = (event) => {
    resetAllState();
  };

  const resetAllState = () => {
    setDisableInsuranceOpt(!disableInsuranceOpt);
    setDisableOptions(!disableOptions);
    setInsuranceOpt([{ key: 0, value: 'TODOS', text: 'TODOS' }]);
    setPlaceHolder('Selecione o tipo do plano');
    setInsurerName('');
  };

  const setDefaultCases = () => {
    setDisableInsuranceOpt(true);
    setInsuranceOpt([{ key: 0, value: 'TODOS', text: 'TODOS' }]);
    setPlaceHolder('TODOS - Não precisa selecionar');
  };

  useEffect(() => {
    const loadOperators = async () => {
      let insurerNameList = []
      let amilList = []
      let notredameList  = []
      const operatorList = await requestHandler(operatorService.onlyActive)
      if (operatorList && operatorList.length) {
        operatorList.forEach(o => {
          if (o.type === 1) insurerNameList.push(o)
          if (o.type === 2) amilList.push(o)
          if (o.type === 3) notredameList.push(o)
        });
      }
      setInsurerNameOpt(insurerNameList)
      setAmilOpt(amilList)
      setNotredameOpt(notredameList)
    }
    loadOperators()
  }, [])

  useEffect(() => {
    onOptionChange(null, insurerName);
  }, [insurerName]);

  return (
    <>
      <Select
        name={`${inputNamePrefix}.insurer_name`}
        label="Nome da Operadora/Seguradora"
        placeholder="Selecione o plano"
        onChange={(event, { value }) => onOptionChange(event, value)}
        value={insurerName}
        options={insurerNameOpt}
        disabled={disableOptions}
      />
      <Select
        name={`${inputNamePrefix}.insurance_type`}
        label="Tipo do Plano"
        options={insuranceOpt}
        placeholder={placeHolder}
        disabled={disableInsuranceOpt}
        onChange={(event, { value }) => setInsurerType(value)}
        value={disableInsuranceOpt ? '' : insurerType}
      />
      <Input
        name={`${inputNamePrefix}.insurance_account`}
        label="Número da Carteirinha"
        placeholder="Digite o número da sua matrícula"
        disabled={disableOptions}
      />
      <Checkbox
        name={`${inputNamePrefix}.has_insurer`}
        label="Não possuo Convênio/Plano de Saúde"
        options={[
          { checked: true, value: true },
          { checked: false, value: false },
        ]}
        onChange={(event) => handleChange(event)}
      />
    </>
  );
}
