import produce from 'immer';

const INITIAL_STATE = {
  appointmentList: [],
  financialList: [],
  isEditing: false,
  loading: false,
  currentAppointment: null,
  pagination: {
    page: 1,
    name: null,
    status: null,
    search: null,
    created_at: null,
  },
};

export default (state = INITIAL_STATE, { type, payload }) => {
  if (type === '@auth/SIGN_OUT') return INITIAL_STATE;

  return produce(state, draft => {
    switch (type) {
      case '@appointments/LIST_SUCCESS': {
        draft.appointmentList = payload.appointmentList;
        draft.pagination = payload.pagination;
        break;
      }
      case '@appointments/FINANCIAL_SUCCESS': {
        draft.financialList = payload.financialList;
        draft.pagination = payload.pagination;
        break;
      }
      case '@appointments/STATISTICS_SUCCESS': {
        draft.appointmentList = payload.appointmentList;
        break;
      }

      case '@appointments/DESTROY_APPOINTMENT': {
        // let lexval = 0
        // let aux = state.appçointmentList
        // for(let i =0; i < aux.length; i++)
        //   if(payload === state.appointmentList[i].id) lexval = i
        // draft.appointmentList = draft.appointmentList.splice(lexval, 1)
        // draft.appointmentList = draft.appointmentList.filter((item) => item.id !== payload);
        break;
      }

      case '@appointments/GET_SUCCESS': {
        draft.currentAppointment = payload;
        break;
      }

      case '@appointments/SET_EDITING': {
        draft.isEditing = payload;
        break;
      }
      case '@appointments/FAILED': {
        draft.loading = false;
        break;
      }
      default:
    }
  });
};
