import api from './api';

const load = (payload) => {
  const { userId, ...params } = payload;
  return api.get(`/payment-redemption/user/${userId}/info`, { params });
};
const list = (payload) => {
  const { ...params } = payload;
  return api.get(`/payment-redemption/`, { params });
};
const store = (payload) => {
  const { checkingAccountId, amount } = payload;
  return api.post('/payment-redemption', { checkingAccountId, amount })
}
const show = (payload) => {
  const { paymentId } = payload;
  return api.get(`/payment-redemption/${paymentId}`)
}
export default {
  load,
  list,
  store,
  show,
};
