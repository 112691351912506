import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { Button, Table, Label, Checkbox, Pagination } from 'semantic-ui-react';
import { FaUserPlus, FaRegTrashAlt, FaCheck, FaTimes } from 'react-icons/fa';
import {
  userListRequest,
  bulkActivateUserRequest,
  bulkInactivateUserRequest,
  bulkDestroyUserRequest,
} from '../../store/modules/users/actions';
import { setBreadcrumb } from '../../store/modules/global/actions';
import Filter from './components/filter';
import { PROFILE } from '~/utils/constants';
import { ActionsContainer } from './styles';

export default function Users() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRecords, setSelectedRecords] = useState({});
  const [filtered, setFiltered] = useState(null);

  const [can, setCan] = useState({
    delete: false,
    activate: false,
    inactivate: false,
  });
  const { pagination, userList } = useSelector(state => state.users);
  const { profile } = useSelector(state => state.user);

  const canBulkActivate = () => {
    const keys = Object.keys(selectedRecords);
    const hasActiveRecord = keys.find(
      key =>
        selectedRecords[key].status === 'ACTV' ||
        ([2, 4].includes(selectedRecords[key].roleId) &&
          !selectedRecords[key].specialty_id)
    );
    return !hasActiveRecord && keys.length;
  };

  const canBulkInactivate = () => {
    const keys = Object.keys(selectedRecords);
    const hasInactiveRecord = keys.find(
      key => selectedRecords[key].status === 'IACT'
    );
    return !hasInactiveRecord && keys.length;
  };

  useEffect(() => {
    dispatch(setBreadcrumb('USERS'));
    dispatch(userListRequest(pagination));
  }, [dispatch]);

  useEffect(() => {
    setCan({
      delete: !!Object.keys(selectedRecords).length,
      activate: canBulkActivate(),
      inactivate: canBulkInactivate(),
    });
  }, [selectedRecords]);

  useEffect(() => {
    setSelectedRecords({});
  }, [userList]);

  const toggleSelectRecord = user => {
    if (selectedRecords[user.id]) {
      const newObject = { ...selectedRecords };
      delete newObject[user.id];
      setSelectedRecords(newObject);
    } else {
      setSelectedRecords({ ...selectedRecords, [user.id]: user });
    }
  };

  const toggleAllRecords = () => {
    const keys = Object.keys(selectedRecords);
    if (keys.length === userList.length) {
      setSelectedRecords({});
    } else {
      setSelectedRecords(
        userList.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {})
      );
    }
  };

  const onPageChange = (e, { activePage: page }) => {
    dispatch(userListRequest({...pagination, ...filtered, page: page }));
  };

  function renderUsers() {
    return userList.map(user => (
      <Table.Row key={user.id} selected={selectedRecords[user.id]}>
        <Table.Cell>
          <Checkbox
            checked={!!selectedRecords[user.id]}
            onClick={() => toggleSelectRecord(user)}
          />
        </Table.Cell>
        <Table.Cell>{user.name}</Table.Cell>
        <Table.Cell>{user.email}</Table.Cell>
        <Table.Cell>{t(user.role.name)}</Table.Cell>
        <Table.Cell>
          {format(parseISO(user.created_at), 'dd/MM/yyyy hh:mm:ss')}
        </Table.Cell>
        <Table.Cell>
          {user.status === 'ACTV' ? (
            <Label basic color="green">
              Ativo
            </Label>
          ) : (
            <Label basic color="red">
              Desativado
            </Label>
          )}
        </Table.Cell>
        <Table.Cell>
          <Link to={`/users/${user.id}/view`} className="table-link">
            Visualizar
          </Link>
        </Table.Cell>
      </Table.Row>
    ));
  }

  return (
    <div className="content">
      <h1>Gerenciamento de Usuários</h1>

      <ActionsContainer>
        {[PROFILE.ADMIN, PROFILE.PROVIDER, PROFILE.DOCTOR, PROFILE.ASSDOCTOR].includes(
          profile.roleId
        ) && (
          <Link to="/users/new" className="ui icon red button">
            <FaUserPlus />
            Novo
          </Link>
        )}
        {[PROFILE.ADMIN].includes(profile.roleId) && (
          <>
            <Button
              color="red"
              className="icon"
              disabled={!can.delete}
              onClick={() =>
                dispatch(bulkDestroyUserRequest(Object.values(selectedRecords)))
              }
            >
              <FaRegTrashAlt />
              Apagar
            </Button>
            <Button
              default
              className="icon"
              disabled={!can.activate}
              onClick={() =>
                dispatch(
                  bulkActivateUserRequest(Object.values(selectedRecords))
                )
              }
            >
              <FaCheck />
              Ativar
            </Button>
            <Button
              default
              className="icon"
              disabled={!can.inactivate}
              onClick={() =>
                dispatch(
                  bulkInactivateUserRequest(Object.values(selectedRecords))
                )
              }
            >
              <FaTimes />
              Desativar
            </Button>
          </>
        )}
      </ActionsContainer>


      <Filter
          onSubmit={(data) => {
            dispatch(userListRequest({ ...pagination, ...data, page: 1 }));
            setFiltered(data);
          }}
          initialData={pagination.search}
        />

        {userList.length > 0 && (
        <>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    checked={
                      userList.length === Object.keys(selectedRecords).length
                    }
                    indeterminate={
                      Object.keys(selectedRecords).length > 0 &&
                      Object.keys(selectedRecords).length < userList.length
                    }
                    onClick={() => toggleAllRecords()}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>E-mail</Table.HeaderCell>
                <Table.HeaderCell>Tipo</Table.HeaderCell>
                <Table.HeaderCell>Criado em</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Ações</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{renderUsers()}</Table.Body>
          </Table>
          <Pagination
            totalPages={pagination.pages}
            defaultActivePage={pagination.page}
            onPageChange={onPageChange}
            firstItem={null}
            lastItem={null}
            pointing
            secondary
          />
        </>
      )}



    </div>
  );
}
