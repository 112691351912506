import api from './api';

const upload = ({ id, files, archiveType, emissionDate, fiscalDocType }) => {
  const data = new FormData();
  for (let i = 0; i < files.length; i++) {
    data.append('files', files[i]);
  }
  
  return api.post(`/appointments/${id}/files?archiveType=${archiveType}&fiscalDocType=${fiscalDocType}&emissionDate=${emissionDate}`, data);
};

const destroy = ({ appointment_id, id }) => {
  return api.delete(`/appointments/${appointment_id}/files/${id}`);
};

export default {
  upload,
  destroy,
};
