import * as Yup from 'yup';
import { PROFILE } from '~/utils/constants';
const REQUIRED = 'Campo obrigatório';

function testaCPF(strCPF) {
  let Soma = 0;
  let Resto = 0;

  if (!strCPF) {
    return false;
  }

  if (strCPF === '00000000000') return false;
  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;
  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;
  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;
  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

function testaCNPJ(strCNPJ) {
  if (!strCNPJ || !strCNPJ.length) return true;

  const cnpj = strCNPJ.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;

  if (cnpj.length !== 14) return false;

  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return false;

  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(1)) return false;

  return true;
}

export default Yup.object().shape({
  id: Yup.string().nullable(),
  roleId: Yup.number().when('id', (id, field) =>
    !id ? field.required(REQUIRED).typeError(REQUIRED) : field
  ),
  name: Yup.string().required(REQUIRED),
  email: Yup.string().email('E-mail inválido').required(REQUIRED),
  password: Yup.string().when('id', (id, field) =>
    !id
      ? field
          .min(8, 'Deve ter pelo menos 8 caracteres')
          .max(10, 'Deve ter 10 ou menos caracteres')
          .required(REQUIRED)
      : field
  ),
  confirmPassword: Yup.string()
    .when('password', (password, field) =>
      password ? field.required(REQUIRED) : field
    )
    .oneOf([Yup.ref('password')], 'Senhas não conferem'),
  profile: Yup.object().shape({
    birthday: Yup.date().typeError('Data inválida'),
    zipcode: Yup.string(),
    street: Yup.string(),
    number: Yup.string(),
    complement: Yup.string(),
    district: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    phone: Yup.string().matches(
      /\(?\d{2}\)?\d{5}[-]?\d{4}/,
      'Telefone celular inválido'
    ),
    cpf: Yup.string()
      .required(REQUIRED)
      .test('CPF TEST', 'CPF Inválido', testaCPF),
    cnpj: Yup.string().test('CNPJ TEST', 'CNPJ Inválido', testaCNPJ),
    insurer_name: Yup.string().when('has_insurer', {
      is: 'false',
      then: Yup.string().required(REQUIRED),
    }),
    insurance_type: Yup.string().when('has_insurer', {
      is: 'false',
      then: Yup.string().required(REQUIRED),
    }),
    insurance_account: Yup.string().when('has_insurer', {
      is: 'false',
      then: Yup.string().required(REQUIRED),
    }),
    has_insurer: Yup.boolean(),
  }),
  professionalData: Yup.object().when('roleId', (roleId, field) => {
    const parsedRoleId = parseInt(roleId, 10);
    // Physician
    if (parsedRoleId === PROFILE.DOCTOR) {
      return field.shape({
        zipcode: Yup.string()
          .matches(/\d{5}[-]?\d{3}/, 'CEP inválido')
          .when((_, field2) => {
            field2.required(REQUIRED);
          }),
        street: Yup.string().when((_, field2) => {
          return field2.required(REQUIRED);
        }),
        number: Yup.string().when((_, field2) => {
          return field2.required(REQUIRED);
        }),
        complement: Yup.string(),
        district: Yup.string().when((_, field2) => {
          return field2.required(REQUIRED);
        }),
        city: Yup.string().when((_, field2) => {
          return field2.required(REQUIRED);
        }),
        state: Yup.string().when((_, field2) => {
          return field2.required(REQUIRED);
        }),
        professional_id: Yup.string().required(REQUIRED),
        private_header_key: Yup.string(),
        private_key: Yup.string(),
        project_id: Yup.string(),
      });
      // Contractor
    } else if (
      parsedRoleId === PROFILE.PROVIDER ||
      parsedRoleId === PROFILE.ASSDOCTOR
    ) {
      return field.shape({
        professional_id: Yup.string().required(REQUIRED),
        percentage_transfer: Yup.number()
          .positive()
          .integer()
          .transform(value => (isNaN(value) ? undefined : value))
          .min(0)
          .max(100)
          .test(
            'Percentage test',
            'Percentual de repasse deve ser entre 0 e 100.',
            (percentage) => {
              return 0 <= percentage && 100 >= percentage;
            }
          )
          .required(REQUIRED),
      });
    }
    // Patient: we don't ask for professional info
    return field.shape(undefined);
  }),
});
